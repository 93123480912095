import { useEffect, useRef, useState } from "react";
// @ts-ignore
import style from "./WarehouseSelect.module.css";
import {
  changeWarehouse,
  deleteWarehouseSelected,
  editOpenModal,
  handleSearchInputChange,
  openCloseSelect,
  closeOptionsSelect,
} from "./functions/functionsWarehouseSelect";
import { handleKeyPress } from "@Pages/MainView/functions/MainViewFunctions";
import EditNameModal from "../EditNameModal/EditNameModal";
import ButtonWarehouseSelect from "./ComponentsWarehouseSelect/ButtonWarehouseSelect/ButtonWarehouseSelect";
import InputSearchWarehouse from "./ComponentsWarehouseSelect/InputSearchWarehouse/InputSearchWarehouse";
import WarehouseSearchResults from "./ComponentsWarehouseSelect/WarehouseSearchResults/WarehouseSearchResults";
import ButtonCreateWarehouse from "./ComponentsWarehouseSelect/ButtonCreateWarehouse/ButtonCreateWarehouse";
import { saleWarehouse } from "@Pages/MainView/functions/saleWarehouse";
import { setSessionWarehouse } from "@Utilities/warehouseUtils";

const WarehouseSelect = ({
  warehousesList,
  setWarehousesList,
  defaultWarehouse,
  setSelectWarehouse,
  setResetWarehouses,
  resetWarehouses,
  setStateResponse,
  showWarehouse,
  setShowWarehouse,
  setSelectProduct,
  resetProducts,
  setResetProducts,
}) => {
  const [warehouseName, setWarehouseName] = useState<string | null>(
    defaultWarehouse?.warehouseName
  );
  const [selectedWarehouseId, setSelectedWarehouseId] = useState<number | null>(
    null
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [warehouseToEdit, setWarehouseToEdit] = useState(null);
  const [buttonDelete, setButtonDelete] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setSelectedWarehouseId(defaultWarehouse?.warehouseId);
    setWarehouseName(defaultWarehouse?.warehouseName);
    setSessionWarehouse({
      warehouseId: defaultWarehouse?.warehouseId,
      warehouseName: defaultWarehouse?.warehouseName,
      sales: defaultWarehouse.sales,
      warehouseValue: defaultWarehouse?.warehouseValue || 0,
    });
  }, [warehousesList, defaultWarehouse]);

  const closeOptions = () => {
    setButtonDelete(false);
    setShowEdit(false);
    setSelectedWarehouseId(null);
    setSelectProduct(null);
  };
  const menuRef = useRef(null); // Nueva referencia para el contenedor del menú

  return (
    <div className={style.warehouse} ref={menuRef}>
      {/* Button open-close options*/}
      <ButtonWarehouseSelect
        showWarehouse={showWarehouse}
        setShowWarehouse={setShowWarehouse}
        openCloseSelect={openCloseSelect}
        closeOptions={closeOptions}
        warehouseName={warehouseName}
        defaultWarehouse={defaultWarehouse}
        menuRef={menuRef}
        closeOptionsSelect={closeOptionsSelect}
      />

      {/* options and search*/}
      {showWarehouse && (
        <div className={style.containerSearchWarehouse}>
          {/* Search input*/}
          <InputSearchWarehouse
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setShowWarehouse={setShowWarehouse}
            warehousesList={warehousesList}
            setSelectedWarehouseId={setSelectedWarehouseId}
            setWarehouseName={setWarehouseName}
            resetWarehouses={resetWarehouses}
            setResetWarehouses={setResetWarehouses}
            handleSearchInputChange={handleSearchInputChange}
            handleKeyPress={handleKeyPress}
            setStateResponse={setStateResponse}
            setWarehousesList={setWarehousesList}
            setSelectWarehouse={setSelectWarehouse}
          />

          {/*warehouse and result*/}
          <WarehouseSearchResults
            warehousesList={warehousesList}
            searchQuery={searchQuery}
            changeWarehouse={changeWarehouse}
            setSelectWarehouse={setSelectWarehouse}
            setWarehouseName={setWarehouseName}
            setShowWarehouse={setShowWarehouse}
            editOpenModal={editOpenModal}
            setIsHovered={setIsHovered}
            setButtonDelete={setButtonDelete}
            showEdit={showEdit}
            selectedWarehouseId={selectedWarehouseId}
            setSelectedWarehouseId={setSelectedWarehouseId}
            setShowEdit={setShowEdit}
            closeOptions={closeOptions}
            setWarehouseToEdit={setWarehouseToEdit}
            setOpenEditModal={setOpenEditModal}
            resetWarehouses={resetWarehouses}
            setResetWarehouses={setResetWarehouses}
            saleWarehouse={saleWarehouse}
            deleteWarehouseSelected={deleteWarehouseSelected}
            openCloseSelect={openCloseSelect}
            showWarehouse={showWarehouse}
            buttonDelete={buttonDelete}
            isHovered={isHovered}
            setStateResponse={setStateResponse}
            setWarehousesList={setWarehousesList}
            setResetProducts={setResetProducts}
            resetProducts={resetProducts}
          />

          {/* Button to create a new store by pressing enter*/}
          <ButtonCreateWarehouse
            searchQuery={searchQuery}
            setShowWarehouse={setShowWarehouse}
            setSearchQuery={setSearchQuery}
            warehousesList={warehousesList}
            setSelectedWarehouseId={setSelectedWarehouseId}
            setWarehouseName={setWarehouseName}
            resetWarehouses={resetWarehouses}
            setResetWarehouses={setResetWarehouses}
            handleKeyPress={handleKeyPress}
            setStateResponse={setStateResponse}
            setWarehousesList={setWarehousesList}
          />
        </div>
      )}
      {openEditModal && (
        <EditNameModal
          close={() => setOpenEditModal(false)}
          setShowWarehouse={setShowWarehouse}
          setSelectedWarehouse={setSelectedWarehouseId}
          setWarehouse={setWarehouseName}
          warehouses={warehousesList}
          setWarehousesList={setWarehousesList}
          setResetWarehouses={setResetWarehouses}
          warehouseToEdit={warehouseToEdit}
          resetWarehouses={resetWarehouses}
          setSelectWarehouse={setSelectWarehouse}
          setStateResponse={setStateResponse}
        />
      )}
    </div>
  );
};

export default WarehouseSelect;
