import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import style from "./InformationView.module.css";
import {
  TextInput,
  Icon,
  InfoNote,
  CriticalErrorPage,
  validateModuleAccess,
} from "../../../../resources";
import SearchBar from "../../../SearchBar/SearchBar";
import CheckboxInput from "../../../FormInputs/CheckboxInput/CheckboxInput";
import Loader from "../../../Loader/Loader";
import { getBrands, getSubsBrands } from "../../utils/productApiRequest";
import useProductInfo from "@Hooks/useProduct";
import {
  BellIcon,
  CircleAlertIcon,
  TimesIcon,
} from "../../../../models/icons/icons";
import { getCategoriesAdapter } from "@Adapters/productsAdapters/categories/getCategories.adapter";
import { changeSection } from "./functions/InformationViewFunctions";
import selectedWarehouseGlobal from "../../../../hooks/UseSelectedWarehouse";
import { postCategoryAdapter } from "@Adapters/productsAdapters/categories/postCategories.adapter";
import { createProductAdapter } from "@Adapters/productsAdapters/products/createProduct.adapter";
import { deleteCategoryAdapter } from "@Adapters/productsAdapters/categories/deleteCategory.adapter";
import { editProductDataAdapter } from "@Adapters/productsAdapters/products/editProductData.adapter";
import { updateEmergencyStockAdapter } from "@Adapters/productsAdapters/products/editProductStock.adapter";
import { addCategoryProductAdapter } from "@Adapters/productsAdapters/categories/addCategoryProduct.adapter";
import { editProductCodeAdapter } from "@Adapters/productsAdapters/products/editProductCode.adapter";
import { postBrand } from "../../../../services/products/brands/postBrand.services";
import { deleteBrand } from "../../../../services/products/brands/deleteBrand.services";
import { deleteSubBrandAdapter } from "@Adapters/productsAdapters/subBrands/deleteSubBrand.adapter";
import { postSubBrandAdapter } from "@Adapters/productsAdapters/subBrands/postSubBrand.adapter";
import { deleteCategoryServicesAdapter } from "@Adapters/productsAdapters/categories/deleteCategoryServices.adapter";
import { Waypoint } from "react-waypoint";
import { MASSIVE_LOAD_PRODUCTS_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

const InformationView = ({
  disabled = false,
  editable,
  setAvailableHeaderAction,
  submitButtonRef,
  setHasUnsavedChanges,
  setStateResponse,
  setErrorView,
  errorView,
  productInformation = null,
  warehouseSelected = null,
  setCreatedProducts = [],
  setInfoCreatedProducts = [],
}) => {
  const navigate = useNavigate();
  const IGV = 0.18;
  let { id } = useParams();
  let selectedWarehouse = JSON.parse(localStorage.getItem("selectedWarehouse"));
  const warehouse = selectedWarehouse || warehouseSelected;
  const basicsForm = useRef(null);
  const pricesForm = useRef(null);
  const stockForm = useRef(null);
  const { product, products } = useProductInfo(
    id,
    setErrorView,
    setStateResponse,
    productInformation
  );

  // get an array of product ids to compare them
  const productsId = products?.map((product) => product?.code);

  const [loadingStatus, setLoadingStatus] = useState({
    loading: true,
    firstFetch: true,
  });
  const [formValid, setFormValid] = useState(false);

  const [valuesForm, setValuesForm] = useState({
    warehouseId: warehouse?.warehouseId || warehouseSelected?.warehouseId,
    name: "",
    code: "",
    brand: "",
    subBrand: "",
    categories: [],
    priceTotal: "0.00",
    stock: "",
    emergencyStock: "",
    freeProduct: false,
  });
  const [valuesFormEdited, setValuesFormEdited] = useState(valuesForm);

  const salesData = {
    priceName: "Minorista",
    priceValue: "0",
    taxPrice: "0",
  };
  const [editedSalesData, setEditedSalesData] = useState(salesData);
  useEffect(() => {
    if (id || productInformation !== null) {
      const ve_informacion_producto = 32;
      trackUserAction(ve_informacion_producto);
    }
  }, []);
  // cuando es edicion se ejecuta este useEffect
  useEffect(() => {
    if (loadingStatus.firstFetch) {
      if (id || productInformation !== null) {
        if (product?.warehouse !== undefined) {
          setLoadingStatus({ loading: true, firstFetch: false });

          getBrands(setStateResponse, setErrorView, id)
            .then((res) => {
              const brandsList = res.map((brand) => {
                return {
                  value: brand.brandId,
                  label: brand.brandName,
                };
              });
              setBrandsList(brandsList);
              const selectedBrand = brandsList.find(
                (brand) =>
                  brand.label.toUpperCase() ===
                  product.brand.brandName.toUpperCase()
              );
              if (selectedBrand === undefined) {
              }
              getSubsBrands(selectedBrand?.value, setStateResponse).then(
                (res) => {
                  const subBrandsList = res?.map((brand) => {
                    return {
                      value: brand.subBrandId,
                      label: brand.subBrandName,
                    };
                  });
                  setSubBrandsList(subBrandsList || []);

                  const data = {
                    warehouseId: product.warehouse.warehouseId,
                    name: product.productName,
                    code: product.code,
                    brand: product.brand.brandName,
                    subBrand: product.line.lineName,
                    categories: product.category.map((category) => {
                      return category?.categoryName?.toUpperCase();
                    }),
                    priceTotal: parseFloat(product.price).toFixed(2),
                    stock: product.stock,
                    emergencyStock: product.emergencyStock,
                    freeProduct: product.freeProduct,
                  };

                  setValuesForm(data);
                  setValuesFormEdited(data);

                  const priceValue = parseFloat(
                    product?.price / (1 + IGV)
                  ).toFixed(2);
                  const taxPrice = parseFloat(
                    product?.price - priceValue
                  ).toFixed(2);

                  setEditedSalesData({
                    priceName: "Minorista",
                    taxPrice: taxPrice,
                    priceValue: priceValue,
                  });

                  setTouched({
                    name: true,
                    code: true,
                    brand: true,
                    subBrand: true,
                    categories: true,
                    priceValue: true,
                    stock: true,
                    emergencyStock: true,
                  });

                  setLoadingStatus({
                    loading: false,
                    firstFetch: false,
                  });
                }
              );
            })
            .catch((error) => {
              setStateResponse({
                message:
                  "Posiblemente no se pudo cargar alguna información del producto.",
                status: 400,
              });
              setLoadingStatus({
                loading: false,
                firstFetch: false,
              });
            });
        }
      } else {
        setLoadingStatus({ loading: false, firstFetch: false });
      }
    }
    return () => {};
  }, [setLoadingStatus, loadingStatus, id, product]);

  const [errors, setErrors] = useState({
    name: "",
    code: "",
    brand: "",
    subBrand: "",
    categories: "",
    priceName: "",
    priceValue: "",
    taxPrice: "",
    stock: "",
    emergencyStock: "",
  });

  const [touched, setTouched] = useState({
    name: false,
    code: false,
    brand: false,
    subBrand: true,
    categories: true,
    priceValue: false,
    stock: false,
    emergencyStock: false,
  });

  const [currentSection, setCurrentSection] = useState(0);
  const [bringMeTheSubbrandsAgain, setBringMeTheSubbrandsAgain] =
    useState(false);

  useEffect(() => {
    //QUIERO TRAERME LAS SUBMARCAS DE LA MARCA SELECCIONADA
    const getSubBrandsList = async () => {
      if (valuesFormEdited.brand) {
        const selectedBrand = brandsList.find(
          (brand) =>
            brand.label.toUpperCase() === valuesFormEdited.brand.toUpperCase()
        );
        if (selectedBrand?.value) {
          getSubsBrands(selectedBrand?.value, setStateResponse).then((res) => {
            const subBrandsList = res.map((brand) => {
              return {
                value: brand.subBrandId,
                label: brand.subBrandName,
              };
            });
            setSubBrandsList(subBrandsList);
          });
        } else {
          setSubBrandsList([]);
        }
      }
    };
    getSubBrandsList();
  }, [valuesFormEdited.brand, bringMeTheSubbrandsAgain]);

  const handleChangeValues = (event, values, setValues) => {
    if (event.target.name === "priceValue") {
      const priceValue = parseFloat(event.target.value);
      const taxPrice = parseFloat((priceValue * IGV).toFixed(2));
      const priceTotal = parseFloat((priceValue + taxPrice).toFixed(2));

      setEditedSalesData({
        ...editedSalesData,
        priceValue: priceValue.toString(),
        taxPrice: taxPrice.toString(),
      });

      setValuesFormEdited({
        ...valuesFormEdited,
        priceTotal: priceTotal.toString(),
      });
      handleBlurValues("", { name: "priceTotal", value: priceTotal });
    } else if (event.target.name === "priceTotal") {
      const priceTotal = parseFloat(event.target.value);
      const priceValue = parseFloat(priceTotal / (1 + IGV)).toFixed(2);
      const taxPrice = parseFloat(priceTotal - priceValue).toFixed(2);

      setEditedSalesData({
        ...editedSalesData,
        priceValue: priceValue.toString(),
        taxPrice: taxPrice.toString(),
      });

      setValuesFormEdited({
        ...valuesFormEdited,
        priceTotal: priceTotal.toString(),
      });
      handleBlurValues("", { name: "priceValue", value: priceValue });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
    handleBlurValues(event);
  };

  useEffect(() => {
    const priceFormattedValue = parseFloat(editedSalesData.priceValue);
    const formattedTaxPrice = parseFloat(editedSalesData.taxPrice);

    // validate that the values are numbers

    if (isNaN(priceFormattedValue) || isNaN(formattedTaxPrice)) {
      setValuesFormEdited((prevState) => ({
        ...prevState,
        priceTotal: 0,
      }));
    } else {
      const priceTotal = priceFormattedValue + formattedTaxPrice;
      setValuesFormEdited((prevState) => ({
        ...prevState,
        priceTotal: priceTotal.toFixed(2),
      }));
    }
  }, [editedSalesData.priceValue, editedSalesData.taxPrice]);

  const validateRequiredField = (value) => (!value ? "Campo requerido" : "");

  const validateCodeField = (value, productsId, product, warehouse) => {
    if (!value) {
      return "Campo requerido";
    }

    const codeExists = productsId?.includes(value) && value !== product?.code;
    return codeExists
      ? `Este código ya existe en ${warehouse.warehouseName}`
      : "";
  };

  const validateNumericField = (value) => {
    if (!value) {
      return "Campo requerido";
    } else {
      return "";
    }
  };

  const validateStockField = (value) => {
    if (!value) {
      return "Campo requerido";
    } else if (!/^\d*$/.test(value)) {
      return "No debe contener letras o caracteres especiales";
    } else {
      return "";
    }
  };

  const validateField = (name, value, errors) => {
    switch (name) {
      case "name":
      case "brand":
        errors[name] = validateRequiredField(value);
        break;
      case "code":
        errors[name] = validateCodeField(value, productsId, product, warehouse);
        break;
      case "categories":
        errors[name] = value?.length === 0 ? "Campo requerido" : "";
        break;
      case "priceValue":
      case "priceTotal":
        errors[name] = validateNumericField(value);
        break;
      case "stock":
      case "emergencyStock":
        errors[name] = validateStockField(value);
        break;
      default:
        break;
    }
  };

  const handleBlurValues = (event, other) => {
    const { name, value } = event?.target ? event.target : other;
    setTouched({ ...touched, [name]: true });
    validateField(name, value, errors);
  };

  const [subBrandsList, setSubBrandsList] = useState([]);

  const subBrandsListNames = subBrandsList.map((subBrand) => subBrand.label);

  const [brandsList, setBrandsList] = useState([]);

  const brandsListNames = brandsList.map((brand) => brand.label);

  const [categoriesList, setCategoriesList] = useState([]);

  const [categoriesListNames, setCategoriesListNames] = useState([]);

  useEffect(() => {
    const categoriesListNames = categoriesList.map((category) =>
      category.label.toUpperCase()
    );
    setCategoriesListNames(categoriesListNames);
  }, [categoriesList]);

  const [tempBrandsListNames, setTempBrandsListNames] = useState([]);

  const handleDeleteCategory = (category) => {
    const newCategories = valuesFormEdited.categories.filter(
      (cat) => cat !== category
    );
    setValuesFormEdited({
      ...valuesFormEdited,
      categories: newCategories,
    });
    handleBlurValues("", {
      name: "categories",
      value: newCategories,
    });
  };

  const updateCategoriesList = () => {
    getCategoriesAdapter(setStateResponse, setErrorView, id).then((res) => {
      const categoriesList = res.categories.map((category) => {
        return {
          value: category.categoryId,
          label: category.categoryName,
        };
      });
      setCategoriesList(categoriesList);
    });
  };

  const updateBrandsList = () => {
    setTempBrandsListNames([]);
    getBrands(setStateResponse, setErrorView, id).then((res) => {
      const brandsList = res.map((brand) => {
        return { value: brand.brandId, label: brand.brandName };
      });
      setBrandsList(brandsList);
    });
  };

  useEffect(() => {
    updateBrandsList();
    updateCategoriesList();
  }, []);

  useEffect(() => {
    // si los campos obligatorios estan vacios y el producto no es nulo entonces el error es true

    if (productInformation !== null) {
      if (!valuesFormEdited.brand) {
        setErrors((prev) => ({
          ...prev,
          brand: "Campo requerido",
        }));
      }

      if (!valuesFormEdited.categories.length) {
        setErrors((prev) => ({
          ...prev,
          categories: "Campo requerido",
        }));
      }

      if (!valuesFormEdited.emergencyStock) {
        setErrors((prev) => ({
          ...prev,
          emergencyStock: "Campo requerido",
        }));
      }
    }
  }, [productInformation]);

  // identify if there are changes in the form
  useEffect(() => {
    if (productInformation !== null) {
      if (
        valuesFormEdited.name === "" ||
        valuesFormEdited.code === "" ||
        valuesFormEdited.brand === "" ||
        valuesFormEdited.categories.length === 0 ||
        valuesFormEdited.priceTotal === "" ||
        valuesFormEdited.emergencyStock === "" ||
        valuesFormEdited.emergencyStock === undefined
      ) {
        setFormValid(false);
        setAvailableHeaderAction(false);
      } else {
        setFormValid(true);
        setAvailableHeaderAction(true);
      }
      return;
    }
    if (
      errors.name === "" &&
      errors.code === "" &&
      errors.brand === "" &&
      errors.subBrand === "" &&
      errors.categories === "" &&
      errors.priceValue === "" &&
      errors.stock === "" &&
      errors.emergencyStock === ""
    ) {
      if (
        touched.categories === true &&
        touched.code === true &&
        touched.brand === true &&
        touched.name === true &&
        (touched.priceValue === true || touched.priceTotal === true) &&
        touched.stock === true &&
        touched.emergencyStock === true
      ) {
        if (JSON.stringify(valuesForm) !== JSON.stringify(valuesFormEdited)) {
          setHasUnsavedChanges(true);
          setFormValid(true);
          setAvailableHeaderAction(true);
        } else {
          setHasUnsavedChanges(false);
          setFormValid(false);
          setAvailableHeaderAction(false);
        }
      } else {
        setFormValid(false);
        setAvailableHeaderAction(false);
      }
    } else {
      setFormValid(false);
      setAvailableHeaderAction(false);
    }
  }, [
    errors,
    touched,
    setAvailableHeaderAction,
    valuesForm,
    valuesFormEdited,
    setHasUnsavedChanges,
  ]);

  useEffect(() => {
    if (!id) {
      if (JSON.stringify(valuesForm) !== JSON.stringify(valuesFormEdited)) {
        setHasUnsavedChanges(true);
      } else {
        setHasUnsavedChanges(false);
      }
    }
  }, [valuesForm, valuesFormEdited]);

  const handleSubmit = async () => {
    // Verifica si el formulario es válido
    // setLoadingStatus({ loading: true, firstFetch: false });
    if (formValid) {
      const brandSelectedCode = brandsList?.find(
        (brand) =>
          brand.label.toUpperCase() === valuesFormEdited.brand.toUpperCase()
      )?.value;
      try {
        let categoriesSelectedCodes = [];
        // Obtiene un array de categorías que están en categoriesListNames
        const categoriesInList = valuesFormEdited?.categories.filter(
          (category) => categoriesListNames.includes(category)
        );

        // Obtiene los códigos de las categorías que están en categoriesInList y los agrega a categoriesSelectedCodes
        const preCategories = product
          ? product?.category?.map((category) =>
              category?.categoryName?.toUpperCase()
            )
          : [];

        categoriesInList?.map((cat) => {
          const categoryCode = categoriesList?.find(
            (category) => category.label.toUpperCase() === cat
          ).value;
          if (!preCategories.includes(cat)) {
            categoriesSelectedCodes?.push(categoryCode);
          }
          return true;
        });
        let subBrandSelectedCode = "";

        // Verifica si la submarca está vacía y asigna un valor nulo si es el caso
        if (valuesFormEdited.subBrand === "") {
          valuesFormEdited.subBrand = null;
        } else {
          // Obtiene el código de la submarca seleccionada en base al nombre
          subBrandSelectedCode = subBrandsList?.find(
            (subBrand) =>
              subBrand.label.toUpperCase() ===
              valuesFormEdited?.subBrand?.toUpperCase()
          )?.value;
        }
        // Formatea los valores del formulario editado
        const formattedValuesFormEdited = {
          warehouseId: warehouse.warehouseId,
          name: valuesFormEdited.name,
          code: valuesFormEdited.code,
          brand: brandSelectedCode,
          subBrand: subBrandSelectedCode,
          categoriesIds: categoriesSelectedCodes,
          price: Math.round(valuesFormEdited.priceTotal * 100) / 100,
          freeProduct: valuesFormEdited.freeProduct ? 1 : 0,
          stock: parseInt(valuesFormEdited.stock),
          emergencyStock: parseInt(valuesFormEdited?.emergencyStock),
        };

        // Si no hay un ID, se trata de la creación de un nuevo producto
        if (!id || productInformation !== null) {
          // Actualiza las listas de categorías y marcas
          const response = await createProductAdapter(
            formattedValuesFormEdited
          );
          // Maneja la respuesta de la creación del producto
          if (response.isSuccess) {
            setStateResponse({
              message: "Producto creado con éxito",
              status: response.status,
            });
            setTimeout(() => {
              if (productInformation !== null) {
                setCreatedProducts((prev) => [
                  ...prev,
                  formattedValuesFormEdited,
                ]);
                setInfoCreatedProducts((prev) => [...prev, response.data]);
                return;
              } else {
                navigate("/");
              }
            }, 1500);
          } else {
            setStateResponse({
              message: response.error,
              status: response.status,
            });
          }
        } else {
          // Si hay un ID, se trata de la edición de un producto existente

          // Obtiene los datos básicos para la edición del producto
          const basicData = {
            idProduct: parseInt(product.productId),
            name: formattedValuesFormEdited.name,
            idBrand: brandSelectedCode,
            idSubBrand:
              formattedValuesFormEdited.subBrand === ""
                ? 0
                : formattedValuesFormEdited.subBrand,
            price: formattedValuesFormEdited.price,
            freeProduct: formattedValuesFormEdited.freeProduct,
          };
          // Itera sobre las categorías que el producto ya tiene y elimina aquellas que no están en el nuevo array de categorías
          const preCategories = product
            ? product.category.map((category) =>
                category.categoryName?.toUpperCase()
              )
            : [];

          for (const category of preCategories) {
            if (!valuesFormEdited.categories?.includes(category)) {
              const idCategory = categoriesList?.find(
                (cat) => cat.label.toUpperCase() === category
              )?.value;

              if (idCategory) {
                const res = await deleteCategoryAdapter(
                  product.productLocalId,
                  idCategory,
                  setStateResponse
                );
                if (!res.isSuccess) {
                  throw new Error(res.error);
                }
              }
            }
          }

          // Realiza las llamadas a los adaptadores correspondientes para editar el producto
          const res = await editProductDataAdapter(basicData);
          const res2 = await editProductCodeAdapter(
            product.productId,
            formattedValuesFormEdited.code
          );

          const res3 = await updateEmergencyStockAdapter(
            product.productId,
            formattedValuesFormEdited.emergencyStock,
            warehouse.warehouseId,
            product.productLocalId,
            formattedValuesFormEdited.stock
          );

          // Maneja la respuesta de la edición del producto
          if (!res.isSuccess || !res2.isSuccess || !res3.isSuccess) {
            setStateResponse({
              message: res.error || res2.error || res3.error,
              status: 400,
            });
            return true;
          }

          if (res.isSuccess && res2.isSuccess && res3.isSuccess) {
            // Asocia las nuevas categorías al producto editado
            for (const category of categoriesSelectedCodes || []) {
              const res = await addCategoryProductAdapter(
                product.productLocalId,
                category,
                id,
                setStateResponse
              );
              if (!res.isSuccess) {
                // Lanzar un error si alguna llamada falla
                throw new Error(res.error);
              }
            }
            setStateResponse({
              message: "Producto editado con éxito",
              status: 200,
            });
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }
        }
      } catch (error) {
        setStateResponse({
          message: error?.message,
          status: 400,
        });
        setLoadingStatus({ loading: false, firstFetch: false });
        return true;
      }
    }
  };

  const selectOrCreateBrand = async (result) => {
    // seleccionar una marca
    handleBlurValues("", {
      name: "brand",
      value: result?.toUpperCase(),
    });
    try {
      if (!valuesFormEdited?.brand?.includes(result?.toUpperCase())) {
        setValuesFormEdited({
          ...valuesFormEdited,
          brand: result?.toUpperCase(),
          subBrand: "",
        });
        setMarkedBrand(result?.toUpperCase());
      }
      // si no existe la marca, la creamos
      // quiero crear una validacion para que no se repitan las marcas
      if (
        !tempBrandsListNames?.includes(result?.toUpperCase()) &&
        !brandsListNames.includes(result?.toUpperCase())
      ) {
        setStateResponse({
          message: `Creando marca ${result}...`,
          status: "pending",
        });
        const res = await postBrand(result);
        if (res.isSuccess) {
          setStateResponse({
            message: `Marca ${result} creada con éxito`,
            status: 200,
          });
          setBrandsList([
            ...brandsList,
            {
              value: res.data,
              label: result,
            },
          ]);
          setValuesFormEdited({
            ...valuesFormEdited,
            brand: result?.toUpperCase(),
            subBrand: "",
          });
        }
      }
    } catch (error) {
      setStateResponse({
        message: "Intente nuevamente, ocurrió un error",
        status: 400,
      });
    }
  };

  const selectOrCreateSubBrand = async (result) => {
    handleBlurValues("", {
      name: "subBrand",
      value: result?.toUpperCase(),
    });
    if (!valuesFormEdited?.subBrand?.includes(result?.toUpperCase())) {
      setValuesFormEdited({
        ...valuesFormEdited,
        subBrand: result?.toUpperCase(),
      });
      setMarketSubBrand(result?.toUpperCase());
    }
    if (!subBrandsListNames.includes(result?.toUpperCase())) {
      const brandId = brandsList.find(
        (brand) =>
          brand?.label.toUpperCase() === valuesFormEdited?.brand.toUpperCase()
      )?.value;
      const res = await postSubBrandAdapter(
        {
          brandId: brandId,
          subBrandName: result,
        },
        setStateResponse
      );

      if (res.isSuccess) {
        setSubBrandsList([
          ...subBrandsList,
          {
            value: res.data,
            label: result,
          },
        ]);
        setValuesFormEdited({
          ...valuesFormEdited,
          subBrand: result?.toUpperCase(),
        });
      }
    }
  };

  const deleteBrandClick = async (event, brandd) => {
    event.preventDefault();
    event.stopPropagation();
    const brandSelected = brandsList.find(
      (brand) => brand.label === brandd
    ).value;
    try {
      setStateResponse({
        message: `Eliminando marca ${brandd}...`,
        status: "pending",
      });
      await deleteBrand(brandSelected);
      updateBrandsList();
      setStateResponse({
        message: `Marca ${brandd} eliminada con éxito`,
        status: 200,
      });
      setTouched((prev) => ({
        ...prev,
        brand: false,
      }));
    } catch (error) {
      setStateResponse({
        message: "Ocurrió un error al eliminar la marca",
        status: 400,
      });
    }
  };

  const deleteSubBrandClick = async (event, subBrand) => {
    event.preventDefault();
    event.stopPropagation();
    const subBrandSelected = subBrandsList.find(
      (brand) => brand.label === subBrand
    ).value;
    try {
      await deleteSubBrandAdapter(subBrandSelected, setStateResponse);
      setTouched((prev) => ({
        ...prev,
        brand: false,
      }));
      setBringMeTheSubbrandsAgain(!bringMeTheSubbrandsAgain);
    } catch (error) {}
  };

  const selectOrCreateCategory = async (result) => {
    handleBlurValues("", {
      name: "categories",
      value: result?.toUpperCase(),
    });
    try {
      if (!valuesFormEdited?.categories?.includes(result?.toUpperCase())) {
        setValuesFormEdited({
          ...valuesFormEdited,
          categories: [...valuesFormEdited?.categories, result?.toUpperCase()],
        });
        handleBlurValues("", {
          name: "categories",
          value: [...valuesFormEdited?.categories, result?.toUpperCase()],
        });
      }
      if (!categoriesListNames.includes(result?.toUpperCase())) {
        const res = await postCategoryAdapter(result, setStateResponse);
        if (res.isSuccess) {
          updateCategoriesList();
          setCategoriesList([
            ...categoriesList,
            {
              value: res.data,
              label: result,
            },
          ]);
          setValuesFormEdited({
            ...valuesFormEdited,
            categories: [
              ...valuesFormEdited?.categories,
              result?.toUpperCase(),
            ],
          });
          setCategoriesList([
            ...categoriesList,
            {
              value: res.data,
              label: result,
            },
          ]);
        } else {
          const newCategories = valuesFormEdited.categories.filter(
            (cat) => cat !== result?.toUpperCase()
          );
          setValuesFormEdited({
            ...valuesFormEdited,
            categories: newCategories,
          });
        }
      }
    } catch (error) {
      setStateResponse({
        message: "Intente nuevamente, ocurrió un error",
        status: 400,
      });
    }
  };

  const deleteCategoryClick = async (event, category) => {
    event.preventDefault();
    event.stopPropagation();
    const idCategory = categoriesList.find(
      (cat) => cat.label.toUpperCase() === category.toUpperCase()
    ).value;
    const res = await deleteCategoryServicesAdapter(
      idCategory,
      setStateResponse
    );
    if (res.isSuccess) {
      updateCategoriesList();
      setTouched((prev) => ({ ...prev, categories: false }));
    }
  };

  useEffect(() => {
    setMarkedBrand(valuesFormEdited?.brand);
  }, [valuesFormEdited?.brand]);

  const [markedBrand, setMarkedBrand] = useState("");

  useEffect(() => {
    setMarketSubBrand(valuesFormEdited?.subBrand);
  }, [valuesFormEdited?.subBrand]);

  const [marketSubBrand, setMarketSubBrand] = useState("");
  if (
    errorView.errorProduct ||
    errorView.errorBrand ||
    errorView.errorCategory
  ) {
    return <CriticalErrorPage />;
  }
  return (
    <main
      className={`${style.productInformationContainer} ${
        editable ? style.productInformationContainerSmaller : ""
      }`}
      style={{
        overflow:
          productInformation !== null && loadingStatus.loading
            ? "hidden"
            : "auto",
      }}
    >
      <div
        className={style.leftNavigationMenu}
        style={{
          paddingTop: id ? "20px" : "",
          display: productInformation !== null ? "none" : "",
        }}
      >
        <button
          className={style.navigationButton}
          style={{
            fontWeight: currentSection === 1 ? "bold" : "normal",
          }}
          active={currentSection === 1}
          onClick={() =>
            changeSection(
              0,
              basicsForm,
              pricesForm,
              stockForm,
              setCurrentSection
            )
          }
        >
          <p>Datos básicos</p>
        </button>
        <button
          className={style.navigationButton}
          style={{
            fontWeight: currentSection === 2 ? "bold" : "normal",
          }}
          active={currentSection === 2}
          onClick={() =>
            changeSection(
              2,
              basicsForm,
              pricesForm,
              stockForm,
              setCurrentSection
            )
          }
        >
          <p>Stock</p>
        </button>
      </div>
      <button
        ref={submitButtonRef}
        onClick={() => handleSubmit()}
        style={{ display: "none" }}
      />
      {loadingStatus.loading ? (
        <Loader />
      ) : (
        <form
          className={style.formContainer}
          style={{
            paddingTop: id ? "20px" : "",
          }}
        >
          <div className={style.formSection} ref={basicsForm}>
            {!editable && productInformation === null && (
              <InfoNote>
                <p>
                  Para subir muchos productos a la vez, puedes hacerlo mediante
                  el{" "}
                  <button
                    onClick={() => {
                      validateModuleAccess(MASSIVE_LOAD_PRODUCTS_UUID) &&
                        navigate("/productos/crear-masivo");
                    }}
                    className={style.info_note__link}
                    type="button"
                  >
                    creador masivo
                  </button>
                  {"."}
                </p>
              </InfoNote>
            )}
            <Waypoint
              onEnter={() => {
                setCurrentSection(1);
              }}
              onLeave={() => {
                setCurrentSection(2);
              }}
            />
            <TextInput
              label="Nombre"
              value={valuesFormEdited?.name}
              touched={touched?.name}
              error={errors?.name}
              handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
              handleChange={(e) =>
                handleChangeValues(e, valuesFormEdited, setValuesFormEdited)
              }
              placeholder={"Nombre del producto"}
              keyboardType={"text"}
              name={"name"}
              disabled={disabled}
              required
            />
            <TextInput
              label="Código"
              value={valuesFormEdited?.code}
              touched={touched?.code}
              error={errors?.code}
              handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
              handleChange={(e) =>
                handleChangeValues(e, valuesFormEdited, setValuesFormEdited)
              }
              placeholder={"Código del producto"}
              keyboardType={"text"}
              name={"code"}
              disabled={disabled || productInformation !== null}
              required
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className={style.inputLabel}>
                <p className={style.brandLabel}>Marca</p>
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </p>
              </label>
              <SearchBar
                placeholder="Buscar/agregar una marca"
                isFiltered={false}
                isSimpleData
                markedBrand={markedBrand}
                value={valuesFormEdited?.brand}
                setMarkedBrand={setMarkedBrand}
                dataSearchable={[...brandsListNames, ...tempBrandsListNames]}
                onClickResult={(result) => selectOrCreateBrand(result)}
                handleClick={() =>
                  handleBlurValues("", {
                    name: "brand",
                    value: valuesFormEdited?.brand,
                  })
                }
                returnNewResults={true}
                handleButtonClickDelete={deleteBrandClick}
                messageCreateItem={"Pulsa intro para crear una nueva marca."}
                error={errors?.brand}
              />
              {touched?.brand && errors?.brand && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "5px",
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  <Icon path={CircleAlertIcon} size={10} color={"#ff0000"} />
                  {errors.brand}
                </p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className={style.inputLabel}>
                <p className={style.brandLabel}>Submarca</p>
              </label>
              <SearchBar
                messageCreateItem={"Pulsa intro para crear una nueva submarca."}
                placeholder={`${
                  valuesFormEdited?.brand
                    ? "Buscar/agregar una submarca de " +
                      valuesFormEdited?.brand
                    : "Primero debes seleccionar una marca"
                }`}
                isFiltered={false}
                isSimpleData
                markedBrand={marketSubBrand}
                value={valuesFormEdited?.subBrand}
                setMarkedBrand={setMarketSubBrand}
                dataSearchable={subBrandsListNames}
                onClickResult={(result) => selectOrCreateSubBrand(result)}
                handleClick={() =>
                  handleBlurValues("", {
                    name: "subBrand",
                    value: valuesFormEdited?.subBrand,
                  })
                }
                returnNewResults={true}
                handleButtonClickDelete={deleteSubBrandClick}
                isSearchAvailable={valuesFormEdited?.brand}
                error={errors?.subBrand}
              />
              {touched?.subBrand && errors?.subBrand && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "5px",
                  }}
                >
                  {errors.subBrand}
                </p>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label className={style.inputLabel}>
                <p className={style.brandLabel}>Categorías del producto</p>
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </p>
              </label>
              <SearchBar
                messageCreateItem={
                  "Pulsa intro para crear una nueva categoría."
                }
                placeholder="Buscar/agregar una categoría"
                isFiltered={false}
                isSimpleData
                markedBrand={""}
                isCategory={true}
                markedCategory={valuesFormEdited.categories}
                dataSearchable={[...categoriesListNames]}
                onClickResult={(result) => selectOrCreateCategory(result)}
                handleClick={() =>
                  handleBlurValues("", {
                    name: "categories",
                    value: valuesFormEdited?.categories,
                  })
                }
                returnNewResults={true}
                handleButtonClickDelete={deleteCategoryClick}
                error={errors?.categories}
              />
              {touched?.categories && errors?.categories && (
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginTop: "5px",
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  <Icon path={CircleAlertIcon} size={10} color={"#ff0000"} />
                  {errors.categories}
                </p>
              )}
            </div>
            {valuesFormEdited?.categories?.length > 0 && (
              <div className={style.categoriesContainer}>
                {valuesFormEdited.categories?.map((category) => {
                  return (
                    <div
                      className={style.categoryButton}
                      key={category}
                      active={valuesFormEdited?.categories?.includes(category)}
                    >
                      <p>{category}</p>
                      <button
                        onClick={() => handleDeleteCategory(category)}
                        className={style.categoryButtonClose}
                      >
                        <Icon
                          path={TimesIcon}
                          size={10}
                          color={"#fff"}
                          alt={"Cerrar"}
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={style.formSection} ref={pricesForm}>
            <div className={style.sectionTitle}>Precio de venta</div>
            <div className={style.sellerContainer}>
              <div className={style.sellerContent}>
                <div className={style.sellerFormContent}>
                  <TextInput
                    label="Nombre de precio"
                    value={editedSalesData?.priceName}
                    touched={touched?.priceName}
                    error={errors?.priceName}
                    handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
                    handleChange={(e) =>
                      handleChangeValues(e, editedSalesData, setEditedSalesData)
                    }
                    placeholder={""}
                    keyboardType={"text"}
                    name={"priceName"}
                    disabled
                    required
                    flex={1}
                    minWidth={"150px"}
                  />
                  <TextInput
                    label="Precio"
                    value={editedSalesData?.priceValue}
                    touched={touched?.priceValue}
                    error={errors?.priceValue}
                    handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
                    handleChange={(e) => {
                      handleChangeValues(
                        e,
                        editedSalesData,
                        setEditedSalesData
                      );
                    }}
                    placeholder={""}
                    keyboardType={"number"}
                    name={"priceValue"}
                    disabled={disabled}
                    required
                    flex={1}
                    prefix={"S/."}
                    minWidth={"150px"}
                  />
                  <TextInput
                    label="IGV/IVA"
                    value={editedSalesData?.taxPrice}
                    touched={touched?.taxPrice}
                    error={errors?.taxPrice}
                    handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
                    handleChange={(e) => {
                      handleChangeValues(
                        e,
                        editedSalesData,
                        setEditedSalesData
                      );
                    }}
                    placeholder={""}
                    keyboardType={"number"}
                    name={"taxPrice"}
                    disabled
                    required
                    flex={1}
                    prefix={"S/."}
                    minWidth={"150px"}
                  />
                  <TextInput
                    label="Precio total"
                    value={Math.round(valuesFormEdited?.priceTotal * 100) / 100}
                    touched={true}
                    error={""}
                    handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
                    handleChange={(e) =>
                      handleChangeValues(
                        e,
                        valuesFormEdited,
                        setValuesFormEdited
                      )
                    }
                    placeholder={""}
                    keyboardType={"number"}
                    name={"priceTotal"}
                    required
                    flex={1}
                    prefix={"S/."}
                    minWidth={"150px"}
                  />
                </div>
                <CheckboxInput
                  label={"Producto gratuito"}
                  value={valuesFormEdited?.freeProduct}
                  checked={valuesFormEdited?.freeProduct}
                  handleChange={() => {
                    setValuesFormEdited({
                      ...valuesFormEdited,
                      freeProduct: !valuesFormEdited?.freeProduct,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.formSection} ref={stockForm}>
            <div className={style.sectionTitle}>Stock del producto</div>
            <p className={style.stockReference}>
              Ingresa la cantidad de unidades físicas con las que cuentas de
              este producto en el{" "}
              <span className={style.currentStorage}>
                {warehouse.warehouseName}
              </span>
              .
            </p>
            <TextInput
              label="Cantidad"
              value={valuesFormEdited?.stock}
              touched={touched?.stock}
              error={errors?.stock}
              handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
              handleChange={(e) =>
                handleChangeValues(e, valuesFormEdited, setValuesFormEdited)
              }
              placeholder={"10 uds."}
              keyboardType={"text"}
              name={"stock"}
              disabled={editable || productInformation !== null}
              required
              flex={1}
            />
          </div>
          <div className={style.formSection}>
            <div className={style.sectionTitle}>Alerta de stock</div>
            <p>
              Indica a partir de cuántas unidades deseas recibir la notificación
              para realizar un nuevo pedido.
            </p>
            <div className={style.alertContainer}>
              <div className={style.alertContent}>
                <div className={style.bellButtonContainer}>
                  <img
                    className={style.bellButton}
                    src={BellIcon}
                    alt="Notificaciones"
                  />
                </div>
                <TextInput
                  label="Unidades"
                  value={valuesFormEdited?.emergencyStock}
                  touched={touched?.emergencyStock}
                  error={errors?.emergencyStock}
                  handleBlur={(e) => handleBlurValues(e, valuesFormEdited)}
                  handleChange={(e) =>
                    handleChangeValues(e, valuesFormEdited, setValuesFormEdited)
                  }
                  placeholder={"5 uds."}
                  keyboardType={"text"}
                  name={"emergencyStock"}
                  disabled={disabled}
                  required
                  flex={1}
                />
              </div>
            </div>
          </div>
          <Waypoint
            onEnter={() => {
              setCurrentSection(2);
            }}
            onLeave={() => {
              setCurrentSection(1);
            }}
          />
        </form>
      )}
    </main>
  );
};

export default InformationView;
