import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import BaseProduct from "@Pages/BaseProduct/BaseProduct";

const CreateProduct = () => {
  const screenWidth = useScreenWidth();
  return (
    <BaseProduct
      title={"Nuevo producto"}
      availableHistory={false}
      titleMobile={"Nuevo Producto"}
      screenWidth={screenWidth}
    />
  );
};

export default CreateProduct;
