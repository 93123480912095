import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface GetProducts {
  isSuccess: boolean;
  status?: number;
  data: any;
  error: string | null;
}

export const getProducts = async (
  warehouseId: number
): Promise<GetProducts> => {
  const url = `${baseUrl}/LocalProducts/Search?idWareHouse=${warehouseId}`;
  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al obtener los productos, intente nuevamente.",
    };
  }
};
