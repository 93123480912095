import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface DeleteBrand {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const deleteBrand = async (idBrand: number): Promise<DeleteBrand> => {
  const url = `${baseUrl}/ProductBrands/DeleteBrand?idBrand=${idBrand}`;
  try {
    const brands = await axios.delete(url, headerAuthorization);
    return {
      isSuccess: true,
      status: brands.status,
      data: brands.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Error al eliminar la marca, intente nuevamente.",
    };
  }
};
