// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VyGcSSmz6oiuqHyw7nIA {
  display: flex;
  align-items: center;
  gap: 16px;
  /* min-width: 180px; */
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #937cf4;
  background: #f0edfe;
  color: #45348e;
  justify-content: space-between;

  @media screen and (width < 900px) {
    width: 100%;
  }
}

.VyGcSSmz6oiuqHyw7nIA:focus {
  outline: none;
  border-color: #937cf4;
}

.T6jQc6hRqoRUtF0S0U4Q {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.KPLQQhJ86vXwlBUtCkH8 {
  background-color: #00de66;
  border-radius: 3px;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
}
._HYacb9tiZrgjoMZtI7F {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/WarehouseSelect/ComponentsWarehouseSelect/ButtonWarehouseSelect/ButtonWarehouseSelect.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,8BAA8B;;EAE9B;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":[".select {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  /* min-width: 180px; */\n  padding: 8px 12px;\n  cursor: pointer;\n  border-radius: 8px;\n  border: 1px solid #937cf4;\n  background: #f0edfe;\n  color: #45348e;\n  justify-content: space-between;\n\n  @media screen and (width < 900px) {\n    width: 100%;\n  }\n}\n\n.select:focus {\n  outline: none;\n  border-color: #937cf4;\n}\n\n.warehouse__content {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n}\n\n.icoSale {\n  background-color: #00de66;\n  border-radius: 3px;\n  color: #fff;\n  padding-left: 5px;\n  padding-right: 5px;\n}\n.warehouseName {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `VyGcSSmz6oiuqHyw7nIA`,
	"warehouse__content": `T6jQc6hRqoRUtF0S0U4Q`,
	"icoSale": `KPLQQhJ86vXwlBUtCkH8`,
	"warehouseName": `_HYacb9tiZrgjoMZtI7F`
};
export default ___CSS_LOADER_EXPORT___;
