import { AdaptedReasonsWithdrawal } from "@Models/interfaces/AdaptedReasonsWithdrawal.interface";
import { ReasonsWithdrawal } from "@Models/interfaces/productsInterfaces/reasonWithdrawalInterface/reasonWithdrawal.interface";
import { getReasonWithdrawal } from "@Services/products/reasonWithdrawal/getReasonWithdrawal.services";

export const reasonsWithdrawalAdapter = async (
  setStateResponse,
  setErrorView
): Promise<AdaptedReasonsWithdrawal> => {
  const ReasonsWithdrawal = await getReasonWithdrawal();
  if (ReasonsWithdrawal.isSuccess) {
    const reasonsWithdrawalAdapt: ReasonsWithdrawal[] =
      ReasonsWithdrawal.data.map((motive: ReasonsWithdrawal) => {
        const warehouseAdapt: ReasonsWithdrawal = {
          id: motive.id,
          motive: motive.motive,
        };
        return warehouseAdapt;
      });
    return {
      isSuccess: true,
      status: ReasonsWithdrawal.status,
      reasonsWithdrawal: reasonsWithdrawalAdapt,
      error: null,
    };
  } else {
    setErrorView((prev) => ({ ...prev, errorReasonsWithdrawal: true }));
    setStateResponse({
      status: 400,
      message: ReasonsWithdrawal.error,
    });
    return {
      isSuccess: false,
      status: ReasonsWithdrawal.status,
      reasonsWithdrawal: [],
      error: ReasonsWithdrawal.error,
    };
  }
};
