import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const getInventory_service = async (inventoryId) => {
  const url = `${baseUrl}/Inventory/GetInventoryDetail?InventoryId=${inventoryId}`;
  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Ha ocurrido un error al obtener el inventario. Por favor, intente nuevamente.",
    };
  }
};
