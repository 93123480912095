import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import {
  editSalesWarehouse,
  editWarehouseName,
} from "@Services/warehouses/putWarehouse.services";

export const putWarehousesNameAdapter = async (
  nameWarehouse: string,
  IdWarehouse: number
): Promise<ResponseToRequest> => {
  const obj = { name: nameWarehouse, IdWarehouse: IdWarehouse };
  const response = await editWarehouseName(obj);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};

export const putSalesWarehousesAdapter = async (
  IdWarehouse: number
): Promise<ResponseToRequest> => {
  const response = await editSalesWarehouse(IdWarehouse);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: [],
    error: response.error,
  };
};
