import { getAllInventories_service } from "@Services/inventory/getAllInventories";

export interface IInventoryProduct {
  id: number;
  productId: number;
  stock: number;
  counter: number;
  differenceMessage: string;
}

export const getAllInventories = async (
  warehouseId: number,
  setStateResponse: any,
  setErrorView: any
) => {
  const response = await getAllInventories_service(warehouseId);

  if (response.isSuccess) {
    return {
      inventories: response.data,
      isSuccess: true,
      status: response.status,
      error: "",
    };
  } else {
    setStateResponse({
      message: response.error,
      status: response.status,
    });
    setErrorView((prev: any) => {
      return {
        ...prev,
        errorInventory: true,
      };
    });
    return {
      inventories: [],
      isSuccess: false,
      status: response.status,
      error: response.error,
    };
  }
};
