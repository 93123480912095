import { AdaptedWarehousesGetResponse } from "@Models/interfaces/AdaptedWarehousesGetResponse.interface";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { getWarehouses } from "@Services/warehouses/getWarehouse.services";

interface IGetWarehousesAdapter {
  setStateResponse?: any;
  setIsLoading?: any;
  setErrorView?: any;
}
export const getWarehousesAdapter = async ({
  setStateResponse,
  setIsLoading,
  setErrorView,
}: IGetWarehousesAdapter): Promise<AdaptedWarehousesGetResponse> => {
  const warehouse = await getWarehouses();
  if (warehouse.isSuccess) {
    const warehousesAdapt: Warehouse[] = warehouse.data.map((warehouse) => {
      const warehouseAdapt = {
        warehouseId: warehouse.warehouseId,
        warehouseName: warehouse.warehouseName,
        sales: warehouse.sales,
        warehouseValue: warehouse.warehouseId,
        isDowngrade: Boolean(warehouse.isDowngrade),
      };
      return warehouseAdapt;
    });
    setIsLoading && setIsLoading(false);
    return {
      isSuccess: true,
      status: warehouse.status,
      warehouses: warehousesAdapt,
      error: null,
    };
  } else {
    setIsLoading && setIsLoading(false);
    setStateResponse &
      setStateResponse({
        message: warehouse.error,
        status: warehouse.status,
      });
    setErrorView &&
      setErrorView((prev) => {
        return {
          ...prev,
          errorWarehouse: true,
        };
      });
    return {
      isSuccess: false,
      status: warehouse.status,
      warehouses: [],
      error: warehouse.error,
    };
  }
};
