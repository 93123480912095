import style from "./tableMobile.module.css";
import { formatToSoles } from "@Utilities/formatToSoles";
import {
  CommonArrowRightIcon,
  DeleteIconTwo,
  PencilWithFrameIcon,
  RedirectIcon,
} from "@Models/icons/icons";
import { Icon, validateModuleAccess } from "../../../../../resources";
import { deleteProduct } from "../../MainTable/functions/deleteProduct";
import { MODIFY_PRODUCTS_UUID } from "@Models/const/securityAccess";

export const tableMobile = ({
  product,
  screenWidth,
  index,
  navigate,
  setModal,
  setProductDelete,
  setSelectProduct,
}) => {
  const styleRed = {
    color: "#8c1044",
    fontWeight: "700",
  };
  const styleGreen = {
    color: "#1d9b75",
    fontWeight: "700",
  };
  const getUnitsText = (stock) => {
    if (stock === 1) {
      return stock + " uni.";
    } else if (stock === 0 || stock < 0) {
      return stock + " unid.";
    } else {
      return stock + " unid.";
    }
  };

  let header = [];
  if (screenWidth > 700) {
    header = [
      <p
        className={style.titles}
        style={{
          minWidth: "12rem",
        }}
      >
        {product.productName}
      </p>,
      <p
        className={style.titles}
        style={{
          minWidth: "8rem",
        }}
      >
        {formatToSoles(product.price)}
      </p>,
      <p
        className={style.titles}
        style={product.stock < 1 ? styleRed : styleGreen}
      >
        {product.stock} unidades.
      </p>,
    ];
  }
  if (screenWidth <= 700) {
    header = [
      <p
        className={style.titles}
        style={{
          minWidth: "12rem",
        }}
      >
        {product.productName}
      </p>,
      <p
        className={style.titles}
        style={product.stock < 1 ? styleRed : styleGreen}
      >
        {product.stock} unidades.
      </p>,
    ];
  }
  if (screenWidth <= 500) {
    header = [
      <p className={style.titles}>{product.productName}</p>,
      <p
        className={style.titles}
        style={product.stock < 1 ? styleRed : styleGreen}
      >
        {product.stock} unidades.
      </p>,
    ];
  }
  if (screenWidth <= 450) {
    header = [
      <p className={style.titles}>{product.productName}</p>,
      <p
        className={style.titles}
        style={product.stock < 1 ? styleRed : styleGreen}
      >
        {getUnitsText(product.stock)}
      </p>,
    ];
  }
  if (screenWidth <= 400) {
    header = [
      <p
        className={style.titles}
        style={{
          minWidth: "8rem",
        }}
      >
        {product.productName}
      </p>,
      <p
        className={style.titles}
        style={product.stock < 1 ? styleRed : styleGreen}
      >
        {getUnitsText(product.stock)}
      </p>,
    ];
  }

  return {
    id: index,
    header: header,
    body: [
      {
        item1: <p>Nombre</p>,
        item2: <p>{product.productName}</p>,
      },
      {
        item1: <p>Precio</p>,
        item2: <p>{formatToSoles(product.price)}</p>,
      },
      {
        item1: <p>Stock</p>,
        item2: (
          <p style={product.stock < 1 ? styleRed : styleGreen}>
            {getUnitsText(product.stock)}
          </p>
        ),
      },
      {
        item1: <p>Stock de emergencia</p>,
        item2: <p>{product.emergencyStock}</p>,
      },
      {
        item1: <p>Código</p>,
        item2: <p>{product.code}</p>,
      },
      {
        item1: <p>Marca</p>,
        item2: <p>{product.brand.brandName}</p>,
      },
      {
        item1: <p>Opciones</p>,
        item2: (
          <div className={style.containerButtonsMovementsTable}>
            <button
              className={style.containerRedirectionIcon}
              onClick={() => {
                setSelectProduct(product);
                setModal(5);
              }}
            >
              <Icon
                path={RedirectIcon}
                size={18}
                alt="Abrir opciones"
                color={"#45348e"}
              />
            </button>
            {product.stock === 0 && (
              <button
                onClick={() =>
                  deleteProduct(product, setModal, setProductDelete)
                }
                className={style.icon__delete}
              >
                <Icon
                  path={DeleteIconTwo}
                  color="#C36363"
                  size={16}
                  alt="Eliminar producto"
                />
              </button>
            )}
            <button
              onClick={() =>
                validateModuleAccess(MODIFY_PRODUCTS_UUID) &&
                navigate(`detalles/${product.productLocalId}`)
              }
              className={style.icon__edit}
            >
              <Icon
                path={CommonArrowRightIcon}
                color="#45348E"
                size={16}
                alt="Ver producto"
              />
            </button>
          </div>
        ),
      },
    ],
  };
};
