export const formatDateTime = (originalDate: string): string => {
  if (originalDate === " - ") return " - ";
  const dateObject = new Date(originalDate);

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    hourCycle: "h12",
  };

  const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
    dateObject
  );

  // Corregir la capitalización del meridiano (AM/PM) y eliminar la coma
  const uppercasedMeridian = formattedDate.slice(-2).toUpperCase();
  const correctedDate = formattedDate.slice(0, -2) + uppercasedMeridian;

  return correctedDate.replace(",", "");
};
