import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { createWarehouse } from "@Services/warehouses/postWarehouse.services";

export const postWarehousesAdapter = async (
  nameWarehouse: string
): Promise<ResponseToRequest> => {
  const obj = { nameWarehouse: nameWarehouse };
  const response = await createWarehouse(obj);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
