import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const createInventory_service = async (warehouseId: number) => {
  const url = `${baseUrl}/Inventory/CreateInventory?WareHouseId=${warehouseId}`;
  const crea_inventario = 29;
  trackUserAction(crea_inventario);
  try {
    const response = await axios.post(url, null, headerAuthorization);

    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Hubo un error al crear el inventario, por favor intenta de nuevo.",
    };
  }
};
