import {
  HiOutlineArrowSmallDown,
  HiOutlineArrowSmallUp,
} from "react-icons/hi2";
import style from "../StockMovements.module.css";

export const createObjectMobile = ({ stockMovement, screenWidth, index }) => {
  const SALIDA = "Egreso";
  const styleRed = {
    color: "#8c1044",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    gap: "2px",
    width: "fit-content",
  };
  const styleGreen = {
    color: "#1d9b75",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    gap: "2px",
    width: "fit-content",
  };
  let headerTitles = [];
  if (screenWidth <= 900 && screenWidth > 600) {
    headerTitles = [
      <span
        className={style.stockMovementsMobile__headerTitle}
        style={
          stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
        }
      >
        {stockMovement.typeOperationName === SALIDA ? (
          <HiOutlineArrowSmallDown />
        ) : (
          <HiOutlineArrowSmallUp />
        )}
        {stockMovement.typeOperationName}
      </span>,
      <span
        style={
          stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
        }
      >
        {stockMovement.operationQuantity}
      </span>,
      stockMovement.movementDate,
      stockMovement.employeeName,
    ];
  }
  if (screenWidth <= 600 && screenWidth > 490) {
    headerTitles = [
      <span
        className={style.stockMovementsMobile__headerTitle}
        style={
          stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
        }
      >
        {stockMovement.typeOperationName === SALIDA ? (
          <HiOutlineArrowSmallDown />
        ) : (
          <HiOutlineArrowSmallUp />
        )}
        {stockMovement.typeOperationName}
      </span>,
      <span
        style={
          stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
        }
      >
        {stockMovement.operationQuantity}
      </span>,
      stockMovement.movementDate,
    ];
  }
  if (screenWidth <= 490) {
    headerTitles = [
      <span
        className={style.stockMovementsMobile__headerTitle}
        style={
          stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
        }
      >
        {stockMovement.typeOperationName === SALIDA ? (
          <HiOutlineArrowSmallDown />
        ) : (
          <HiOutlineArrowSmallUp />
        )}
        {stockMovement.typeOperationName}
      </span>,
      stockMovement.movementDate,
    ];
  }

  return {
    id: index,
    header: headerTitles,
    body: [
      {
        item1: "Movimiento",
        item2: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.typeOperationName}
          </span>
        ),
      },
      {
        item1: "Cantidad",
        item2: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.operationQuantity}
          </span>
        ),
      },
      {
        item1: "Almacén",
        item2: stockMovement.originWarehouseName,
      },
      {
        item1: "Stock actualizado",
        item2: stockMovement.stock,
      },
      {
        item1: "Observaciones",
        item2: stockMovement.observation,
      },
      {
        item1: "Responsable",
        item2: stockMovement.employeeName,
      },
      {
        item1: "Fecha y hora",
        item2: stockMovement.movementDate,
      },
    ],
  };
};
