import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { createCategory } from "@Services/products/categories/postCategories.services";

export const postCategoryAdapter = async (
  nameNewCategory: string,
  setStateResponse
): Promise<ResponseToRequest> => {
  const obj = { nameNewCategory: nameNewCategory };
  setStateResponse({
    message: "Creando categoría...",
    status: "pending",
  });
  const response = await createCategory(obj);
  if (response.isSuccess) {
    setStateResponse({
      message: "Categoría creada exitosamente.",
      status: response.status,
    });
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  } else {
    setStateResponse({
      message: response.error,
      status: response.status,
    });
    return {
      isSuccess: false,
      status: response.status,
      data: null,
      error: response.error,
    };
  }
};
