// @ts-ignore
import style from "./UnitIncomeView.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom/dist";
import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import useProductInfo from "@Hooks/useProduct";
import { HeadBar, SlideNotification, CriticalErrorPage } from "../../resources";
import SuccessMessage from "@Components/SuccessMessage/SuccessMessage";
import Table from "@Components/Table/Table";
import { incomeProductsAdapter } from "@Adapters/productsAdapters/products/incomeProducts.adapter";
import Loader from "@Components/Loader/Loader";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";

const UnitIncomeView = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [incomeTotal, setIncomeTotal] = useState(0);
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const [errorView, setErrorView] = useState({
    errorProduct: false,
  });
  const [warehouseName, setWarehouseName] = useState<string>("");
  const navigate = useNavigate();
  const [warehouseLocalStorage, setWarehouseLocalStorage] =
    useState<Warehouse | null>(null);

  useEffect(() => {
    const fetchWarehouse = async () => {
      const warehouse = await selectedWarehouseGlobal();
      setWarehouseLocalStorage(warehouse);
      setWarehouseName(warehouse.warehouseName);
    };
    fetchWarehouse();
  }, []);

  const { id } = useParams();
  const { product, isLoading } = useProductInfo(
    id,
    setErrorView,
    setStateResponse
  );
  const [cost, setCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const screenWidth = useScreenWidth();
  const [incomePriceValue, setIncomePriceValue] = useState<number>(0);
  useEffect(() => {
    // una validacion para que me devuelva 0 si el precio es undefined
    const price = product?.price === undefined ? 0 : product?.price;
    setIncomePriceValue(Number(price));
  }, [product]);

  const incomeProducts = async () => {
    if (loading || !warehouseLocalStorage) return;
    setLoading(true);
    const response = await incomeProductsAdapter(
      product,
      incomeTotal,
      warehouseLocalStorage,
      cost
    );
    if (response.isSuccess) {
      setSuccessMsg(true);
      setLoading(false);
    } else {
      setLoading(false);
      setStateResponse({
        message: response.error,
        status: response.status,
      });
    }
  };

  const handleClick = () => {
    if (successMsg) {
      setSuccessMsg(false);
      setIncomeTotal(0);
      setCost(0);
      return;
    }
    return incomeProducts();
  };

  const title =
    screenWidth >= 900 ? `${warehouseName} - Ingresar unidades` : warehouseName;
  const previousAction = () => navigate(`/`);
  const textBttn = !successMsg ? "Continuar" : "Ingresar más unidades";

  const headerProps = {
    previousAction: () => previousAction(),
    warehouseProps: {
      title: title,
    },
    showButton: screenWidth >= 900,
    buttonProps: {
      textBttn: textBttn,
      handleClick: () => handleClick(),
      isDisabled: !cost || loading || isNaN(incomeTotal) || incomeTotal < 1,
      isPrimary: true,
      isLoading: loading,
      style: { maxHeight: "40px" },
    },
  };

  if (isLoading) {
    return (
      <div id="viuti-front-mainContent">
        <HeadBar {...headerProps} />
        <Loader />
      </div>
    );
  }
  if (errorView.errorProduct) {
    return (
      <div id="viuti-front-mainContent">
        <HeadBar {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }
  return (
    <div id="viuti-front-mainContent">
      <HeadBar {...headerProps} />
      {successMsg ? (
        <div className={style.success}>
          <SuccessMessage
            actionMsg={`Ingresaste ${incomeTotal} unidad/es de producto “${product?.productName}” a ${warehouseName}.`}
            actionTitle={"Ingreso de unidades exitoso"}
          />
        </div>
      ) : (
        <div className={style.containerBot}>
          <Table
            income={true}
            withdrawal={false}
            product={product}
            setIncomeTotal={setIncomeTotal}
            setCost={setCost}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            functionButton={incomeProducts}
            cost={cost}
            loading={loading}
            setStateResponse={setStateResponse}
            incomePriceValue={incomePriceValue}
            setIncomePriceValue={setIncomePriceValue}
          />
        </div>
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default UnitIncomeView;
