// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LjZj_bxrkqMxodCvA_9K {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.fRSWomrPZxVH6nq2KXnL {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 19.06rem;
}

.dPFyDTMVSDV2MglHk0kr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 4.94rem !important;
}

.ggs2puDV04nOlGeXWYCk {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalUnsavedChanges/ModalUnsavedChanges.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n.modalDescription {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  max-width: 19.06rem;\n}\n\n.modalIcon {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n  width: 4.94rem !important;\n}\n\n.title {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `LjZj_bxrkqMxodCvA_9K`,
	"modalDescription": `fRSWomrPZxVH6nq2KXnL`,
	"modalIcon": `dPFyDTMVSDV2MglHk0kr`,
	"title": `ggs2puDV04nOlGeXWYCk`
};
export default ___CSS_LOADER_EXPORT___;
