import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { transferProducts } from "@Services/products/products/transferProducts.services";

export const transferProductsAdapter = async (
  product: Product,
  transferTotal: number,
  warehouseMeta: string,
  warehouseIda: string
): Promise<ResponseToRequest> => {
  const obj = {
    transferProducts: [
      {
        idLocalProductToTransfer: product?.productLocalId,
        idProduct: product?.productId,
        idReason: 0,
        amountTransfer: transferTotal,
        idDestinationWarehouse: parseInt(warehouseMeta),
        idActualWarehouse: parseInt(warehouseIda),
      },
    ],
  };
  const response = await transferProducts(obj);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
