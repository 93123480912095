import React from "react";
// @ts-ignore
import style from "./DeleteProduct.module.css";
import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { deleteProductsAdapter } from "@Adapters/productsAdapters/products/deleteProducts.adapter";
import { ModalAcceptCancel } from "../../../../resources/index";
import { WarningTriangleIcon } from "@Models/icons/icons";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";

interface DeleteProductProps {
  close: () => void;
  productDelete: Product | null;
  resetProducts: boolean;
  setResetProducts: React.Dispatch<React.SetStateAction<boolean>>;
  setStateResponse: any;
  setProductsList: any;
  productsList: any;
  setProducts: any;
}

const DeleteProduct: React.FC<DeleteProductProps> = ({
  close,
  productDelete,
  resetProducts,
  setResetProducts,
  setStateResponse,
  setProductsList,
  productsList,
  setProducts,
}) => {
  const closeAndReset = () => {
    // setResetProducts(!resetProducts);
    close();
  };
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);

  const deleteProduct = async () => {
    setButtonDisabled(true);
    const res = await deleteProductsAdapter(
      productDelete?.productLocalId,
      productDelete?.productId
    );
    if (res.isSuccess) {
      setStateResponse({
        message: "Producto eliminado correctamente",
        status: 200,
      });
      setProducts((prevList: Product[]) =>
        prevList.filter(
          (product) => product.productId !== productDelete?.productId
        )
      );
      // setProductsList((prevList: Product[]) =>
      //   prevList.filter(product => product.productId !== productDelete?.productId)
      // );
      closeAndReset();
    } else {
      setButtonDisabled(false);
      setStateResponse({
        message: "No se pudo eliminar el producto",
        status: 400,
      });
    }
    return;
  };

  const screenWidth = useScreenWidth();
  return (
    <ModalAcceptCancel
      title="Eliminar producto"
      showButtons={{
        showButtonClose: true,
        showButtonOne: screenWidth > 380 ? true : false,
        showButtonTwo: true,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: () => close(),
      }}
      buttonTwo={{
        textButtonTwo: "Eliminar",
        actionButtonTwo: () => deleteProduct(),
        isDisabled: buttonDisabled,
        isLoading: buttonDisabled,
      }}
      actionButtonClose={close}
    >
      <div className={style.body}>
        <div className={style.containerForm}>
          <figure>
            <img src={WarningTriangleIcon} alt="Simbolo de advertencia" />
          </figure>
          <h3 className={style.h3msg}>
            ¿Estás seguro de que deseas eliminar el producto?
          </h3>
        </div>
        <section className={style.movement_info_container}>
          <div className={style.movement_info}>
            <h3>{productDelete.productName}</h3>
            <span className={style.movement_concept}>{productDelete.code}</span>
          </div>
          <div className={style.amount_info}>
            <p className={style.movement_date}>
              {productDelete.brand.brandName}
            </p>
            <p>{productDelete.stock} unidades</p>
          </div>
        </section>
      </div>
    </ModalAcceptCancel>
  );
};

export default DeleteProduct;
