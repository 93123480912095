import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./SelectWarehouseModal.module.css";
import { useNavigate } from "react-router";
import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import { ModalTransferProps } from "@Models/interfaces/ModalTransfer.interface";
import { ModalAcceptCancel, SelectInput } from "../../../../resources/index";
import { useWarehouseInventoryStore } from "@Hooks/useWarehouseInventoryStore";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { createInventory } from "@Adapters/inventoryAdapters/createInventory.adapter";
import { IToasterStore, useToasterStore } from "@ReduxService/useToasterStore";

const SelectWarehouseModal = ({
  close,
  warehousesList,
}: ModalTransferProps) => {
  const navigate = useNavigate();
  const { warehouse, setWarehouse } = useWarehouseInventoryStore() as {
    warehouse: Warehouse;
    setWarehouse: Function;
  };
  const defaultWarehouse = selectedWarehouseGlobal();
  const { setStatus, setMessage } = useToasterStore() as IToasterStore;

  const warehousesItems = warehousesList.map((warehouse) => {
    return {
      value: warehouse.warehouseId,
      name: warehouse.warehouseName,
    };
  });

  useEffect(() => {
    setWarehouse(defaultWarehouse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitInventory = async () => {
    setIsCreatingInventory(true);
    const response = await createInventory(warehouse.warehouseId);
    setIsCreatingInventory(false);

    if (response.isSuccess) {
      navigate(
        `/inventario/${warehouse.warehouseId}/${response.data.invetoryId}`
      );
    } else {
      setStatus(response.status);
      setMessage(response.error);
    }
  };

  const [isCreatingInventory, setIsCreatingInventory] = useState(false);

  return (
    <ModalAcceptCancel
      title={"Selección de Almacén"}
      showButtons={{
        showButtonClose: true,
        showButtonOne: false,
        showButtonTwo: true,
      }}
      actionButtonClose={() => close()}
      buttonTwo={{
        textButtonTwo: "Aceptar",
        actionButtonTwo: submitInventory,
        isDisabled: !warehouse || isCreatingInventory,
        isLoading: isCreatingInventory,
      }}
    >
      <div className={style.container}>
        <p className={style.message}>
          Elige un almacén para empezar a gestionar el inventario.
        </p>
        <div className={style.warehouseSelector}>
          <SelectInput
            value={warehouse.warehouseId}
            items={warehousesItems}
            name={"optionIda"}
            type={"text"}
            handleChange={(e) => {
              setWarehouse(
                warehousesList.find((warehouse) => {
                  return warehouse.warehouseId === e.target.value;
                })
              );
            }}
          />
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default SelectWarehouseModal;
