// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yAIK4A60IU7MsE2d4fgm {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/WarehouseSelect/ComponentsWarehouseSelect/WarehouseSearchResults/OpenOptionsWarehouse/OpenOptionsWarehouse.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".containerOptions {\n  height: 2.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerOptions": `yAIK4A60IU7MsE2d4fgm`
};
export default ___CSS_LOADER_EXPORT___;
