import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

interface WithdrawalsProducts {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

interface RequestBodyWithdrawalsProducts {
  withdrawProducts: {
    idLocalProduct: number;
    amount: number;
    idReason: number;
    idProduct: number;
    cost: number;
  }[];
}

export const withdrawalsProducts = async (
  obj: RequestBodyWithdrawalsProducts
): Promise<WithdrawalsProducts> => {
  const url = `${baseUrl}/LocalProducts/WithdrawalsProducts`;
  const retira_unidades = 27;
  trackUserAction(retira_unidades);
  try {
    const response = await axios.post(url, obj, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al retirar unidades, intente nuevamente.",
    };
  }
};
