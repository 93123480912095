import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export interface DeleteWarehouse {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const deleteWarehouse = async (
  IdWarehouse: number
): Promise<DeleteWarehouse> => {
  const url = `${baseUrl}/Warehouses/${IdWarehouse}/Warehouse?id=${IdWarehouse}`;
  const elimina_almacen = 21;
  trackUserAction(elimina_almacen);
  try {
    const response = await axios.delete(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message || "Error al eliminar almacén.",
    };
  }
};
