import React from "react";
// @ts-ignore
import styles from "./NavigationBar.module.css";

export const NavigationBar = ({ setInformationView, informationView }) => {
  return (
    <div className={styles.NavigationContainer}>
      <button
        className={`${styles.NavigationButton} ${
          informationView ? styles.selected : ""
        }`}
        onClick={() => setInformationView(true)}
      >
        <p>Información</p>
      </button>
      <button
        className={`${styles.NavigationButton} ${
          !informationView ? styles.selected : ""
        }`}
        onClick={() => setInformationView(false)}
      >
        <p>Movimientos de stock</p>
      </button>
    </div>
  );
};
