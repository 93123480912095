export const handleDoneClick = (setCurrentDate: any) => {
  const now = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat("es-AR", options).format(now);
  setCurrentDate(formattedDate);
};
