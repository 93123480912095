// @ts-ignore
import style from "./styles/inventoryCountRowsBuilder.module.css";
import { productTableBuilder } from "./productBuilder";
import { CalendarIcon, TrashBinIcon } from "@Models/icons/icons";
import { Icon, InputAdditionSubtract } from "../../../resources";
import { IProductInventory } from "@Hooks/useWarehouseInventoryStore";

export const inventoryCountRowsBuilder = (
  products: IProductInventory[],
  handleUpdateProucts: Function,
  onDifferenceClick: Function,
  isCheckingInventory: boolean,
  handleCountChange: Function,
  setStateResponse: any
) => {
  const tableRows = products.map((product) => {
    const productBuilderParams = {
      name: product.name,
      brand: product.brand.name,
      subBrand: product.line.name,
      code: product.code,
    };
    const productCountValue = Number(product.counter);

    const differenceJustification = product.differenceMessage || "";

    const difference = productCountValue - product.stock;

    return {
      Producto: productTableBuilder(setStateResponse, productBuilderParams),
      "1": (
        <button
          onClick={() => onDifferenceClick(product)}
          className={`${style.difference__resolve__button} ${
            difference !== 0 && !differenceJustification
              ? style.difference__resolve__button__show
              : ""
          }`}
        >
          <Icon path={CalendarIcon} color={"#BA1A1A"} size={14} />
          <p className={style.difference__resolve__button__text}>
            ¡Resolver problema!
          </p>
        </button>
      ),
      "Unidades registradas": product.stock,
      "Unidades contadas": (
        <InputAdditionSubtract
          value={productCountValue}
          setValue={(value: number) => handleCountChange(product.id, value)}
          min={-999}
          max={999}
          disabled={isCheckingInventory}
        />
      ),
      Diferencia: differenceJustification ? (
        <span className={style.difference__solved}>Resuelto</span>
      ) : (
        <span
          className={`${style.difference__container} ${
            difference !== 0 ? style.difference__show : ""
          }`}
        >
          {difference > 0 ? `+${difference}` : difference}
        </span>
      ),
      "2": (
        <button
          className={style.inventoryTable__row__action__delete}
          title="Eliminar producto"
          onClick={() => handleUpdateProucts(product)}
        >
          <Icon path={TrashBinIcon} color={"#C36363"} size={16} />
        </button>
      ),
      Stock: productCountValue,

      // isCheckingInventory
    };
  });

  return tableRows;
};
