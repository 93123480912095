// @ts-ignore
import style from "./UnitaryWithdrawalView.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useReasonsUnitaryWithdrawal from "@Hooks/UseReasonsUnitaryWithdrawal";
import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import useProductInfo from "@Hooks/useProduct";
import { HeadBar } from "../../resources";
import SuccessMessage from "@Components/SuccessMessage/SuccessMessage";
import Table from "@Components/Table/Table";
import { SlideNotification, CriticalErrorPage } from "../../resources/index";
import { withdrawalsProductsAdapter } from "@Adapters/productsAdapters/products/withdrawalsProducts.adapter";
import Loader from "@Components/Loader/Loader";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";

const UnitaryWithdrawalView: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [reasonId, setReasonId] = useState(0);
  const [withdrawalTotal, setWithdrawalTotal] = useState(0);
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const [errorView, setErrorView] = useState({
    errorProduct: false,
    errorReasonsWithdrawal: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const { product, isLoading } = useProductInfo(
    id,
    setErrorView,
    setStateResponse
  );
  const { reasons } = useReasonsUnitaryWithdrawal(
    setStateResponse,
    setErrorView
  );
  const [warehouseName, setWarehouseName] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const screenWidth = useScreenWidth();

  useEffect(() => {
    const fetchWarehouseName = async () => {
      const warehouseData = await selectedWarehouseGlobal();
      setWarehouseName(warehouseData.warehouseName);
    };
    fetchWarehouseName();
  }, []);

  const withdrawalProducts = async () => {
    if (loading) return;
    setLoading(true);
    const response = await withdrawalsProductsAdapter(
      product,
      withdrawalTotal,
      reasonId
    );
    if (response.isSuccess) {
      setSuccessMsg(true);
      setLoading(false);
    } else {
      setLoading(false);
      setStateResponse({
        message: response.error,
        status: response.status,
      });
    }
  };
  const handleClick = () => {
    if (successMsg) {
      setSuccessMsg(false);
      setWithdrawalTotal(0);
      setReasonId(0);
      return;
    }
    return withdrawalProducts();
  };

  const previousAction = () => {
    return navigate(`/`);
  };

  const textBttn = !successMsg ? "Continuar" : "Retirar mas unidades";

  const title =
    screenWidth > 900 ? `${warehouseName} - Retirar unidades` : warehouseName;
  const headerProps = {
    previousAction: () => previousAction(),
    warehouseProps: {
      title: title,
    },
    showButton: screenWidth >= 900,
    buttonProps: {
      textBttn: textBttn,
      handleClick: () => handleClick(),
      isDisabled:
        reasonId === 0 ||
        withdrawalTotal < 1 ||
        loading ||
        isNaN(withdrawalTotal),
      isPrimary: true,
      isLoading: loading,
      style: { maxHeight: "40px" },
    },
  };

  if (isLoading) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <HeadBar {...headerProps} />
        <Loader />
      </div>
    );
  }
  if (errorView.errorProduct || errorView.errorReasonsWithdrawal) {
    return (
      <div id="viuti-front-mainContent">
        <HeadBar {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }
  return (
    <div
      id="viuti-front-mainContent"
      style={{
        overflow: "auto",
      }}
    >
      <HeadBar {...headerProps} />
      {successMsg ? (
        <div className={style.success}>
          <SuccessMessage
            actionMsg={`Retiraste ${withdrawalTotal} unidad/es de producto “${product?.productName}” de ${warehouseName}.`}
            actionTitle={"Retiro de productos exitoso"}
          />
        </div>
      ) : (
        <div className={style.containerBot}>
          <Table
            income={false}
            withdrawal={true}
            product={product}
            setWithdrawalTotal={setWithdrawalTotal}
            listOfReason={reasons}
            setReasonId={setReasonId}
            reasonId={reasonId}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            functionButton={withdrawalProducts}
            loading={loading}
            setStateResponse={setStateResponse}
          />
        </div>
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default UnitaryWithdrawalView;
