// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MIVmH_rIY1jNtvyWYbzg {
  margin-top: 20px;
  height: 100%;
  overflow-y: auto;
}

.M_JGIgTjEDpGs4knruyn {
  color: #45348e;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/BaseProduct/BaseProduct.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".container {\n  margin-top: 20px;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.titleMobile {\n  color: #45348e;\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MIVmH_rIY1jNtvyWYbzg`,
	"titleMobile": `M_JGIgTjEDpGs4knruyn`
};
export default ___CSS_LOADER_EXPORT___;
