// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HhKug82BM0SpMmmuGVqW {
  width: 20.625rem;
  align-items: center;
  border: none;
  background: white;
}

.bWomY_xSrr8qK74ZV6Da {
  display: flex;
  justify-content: center;
}

.bWomY_xSrr8qK74ZV6Da h1 {
  font-family: "Mulish";
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0.0125rem;
  color: #45348e;
}

.W8dagnxtYVgDCqAh0oCm {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Ujp2PxgKhmwHcEUf22uN {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
  gap: 1.5625rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/ModalTransfer/ModalTransfer.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,qBAAqB;EACrB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;EACrB,wBAAwB;EACxB,cAAc;AAChB","sourcesContent":[".container {\n  width: 20.625rem;\n  align-items: center;\n  border: none;\n  background: white;\n}\n\n.containerTitle {\n  display: flex;\n  justify-content: center;\n}\n\n.containerTitle h1 {\n  font-family: \"Mulish\";\n  font-size: 1.5rem;\n  line-height: 1.875rem;\n  letter-spacing: 0.0125rem;\n  color: #45348e;\n}\n\n.icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.containerContent {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 1.5625rem;\n  margin-bottom: 1.5625rem;\n  gap: 1.5625rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HhKug82BM0SpMmmuGVqW`,
	"containerTitle": `bWomY_xSrr8qK74ZV6Da`,
	"icon": `W8dagnxtYVgDCqAh0oCm`,
	"containerContent": `Ujp2PxgKhmwHcEUf22uN`
};
export default ___CSS_LOADER_EXPORT___;
