import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { baseUrl, getData, headerAuthorization } from "@Services/constServices";
import axios from "axios";

interface GetWarehouses {
  isSuccess: boolean;
  status?: number;
  data: Warehouse[];
  error: string | null;
}

export const getWarehouses = async (): Promise<GetWarehouses> => {
  const url = `${baseUrl}/Warehouses`;
  try {
    const warehouses = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: warehouses.status,
      data: warehouses.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Hubo un error al obtener los almacenes.",
    };
  }
};

export const getWarehouseStatus = async (warehouseId: number) => {
  const url = `${baseUrl}/Warehouses/Status?warehouseId=${warehouseId}`;
  const successMessage = "Estado del almacén encontrado correctamente";
  const errorMessage =
    "Hubo un error al buscar el estado del almacén. Por favor, intente nuevamente.";
  return getData(url, successMessage, errorMessage);
};
