import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { deleteWarehouseAdapter } from "@Adapters/warehousesAdapters/deleteWarehouse.adapter";
import { setSessionWarehouse } from "@Utilities/warehouseUtils";

export const deleteWarehouse = async (
  warehouse: Warehouse,
  setShowWarehouse,
  setSelectedWarehouse,
  setWarehouse,
  warehouses: Warehouse[],
  resetWarehouses: boolean,
  setResetWarehouses,
  setStateResponse,
  setWarehousesList,
  resetProducts,
  setResetProducts,
  setSelectWarehouse
) => {
  // Recupera el almacén seleccionado del almacenamiento de sesión
  let warehouseLocalStorage = JSON.parse(
    localStorage.getItem("selectedWarehouse")
  );

  if (warehouse) {
    // Actualiza el estado para indicar que se está eliminando el almacén
    setStateResponse({
      message: "Eliminando almacén...",
      status: "pending",
    });

    // Llama al adaptador para eliminar el almacén
    await deleteWarehouseAdapter(warehouse.warehouseId).then((response) => {
      if (response.isSuccess) {
        // Si la eliminación fue exitosa, actualiza el estado con un mensaje de éxito
        setStateResponse({
          message: `El almacén "${warehouse?.warehouseName}" se ha eliminado correctamente`,
          status: response.status,
        });

        // SI EL ALMACEN ELIMINADO ES EL SELECCIONADO
        if (warehouse?.warehouseId === warehouseLocalStorage?.warehouseId) {
          // BUSCAMOS EL ALMACEN DE VENTA
          const warehouseSale = warehouses.find(
            (ware) =>
              ware?.sales && ware?.warehouseId !== warehouse?.warehouseId
          );

          // Filtra la lista de almacenes para eliminar el almacén eliminado
          const selectWarehouseNotDeleted = warehouses.filter(
            (ware) => ware?.warehouseId !== warehouse?.warehouseId
          );

          if (warehouseSale) {
            // Si hay un almacén de ventas, selecciona ese almacén
            setSelectedWarehouse(warehouseSale?.warehouseId);
            setWarehouse(warehouseSale?.warehouseName);
            setSessionWarehouse({
              warehouseId: warehouseSale?.warehouseId,
              warehouseName: warehouseSale?.warehouseName,
              sales: 1,
              warehouseValue: warehouseSale?.warehouseValue || 0,
            });
            setSelectWarehouse({
              warehouseId: warehouseSale?.warehouseId,
              warehouseName: warehouseSale?.warehouseName,
              sales: 1,
              warehouseValue: warehouseSale?.warehouseValue || 0,
            });
            setWarehousesList(
              (prev) =>
                prev.filter(
                  (ware) => ware.warehouseId !== warehouse.warehouseId
                ) // Filtra el almacén eliminado
            );
          } else {
            // Si no hay almacén de ventas, selecciona el primer almacén restante
            setSelectedWarehouse(selectWarehouseNotDeleted[0]?.warehouseId);
            setWarehouse(selectWarehouseNotDeleted[0]?.warehouseName);
            setSessionWarehouse({
              warehouseId: selectWarehouseNotDeleted[0]?.warehouseId,
              warehouseName: selectWarehouseNotDeleted[0]?.warehouseName,
              sales: 0,
              warehouseValue: selectWarehouseNotDeleted[0]?.warehouseValue || 0,
            });
            setSelectWarehouse({
              warehouseId: selectWarehouseNotDeleted[0]?.warehouseId,
              warehouseName: selectWarehouseNotDeleted[0]?.warehouseName,
              sales: 0,
              warehouseValue: selectWarehouseNotDeleted[0]?.warehouseValue || 0,
            });
            setWarehousesList(
              (prev) =>
                prev.filter(
                  (ware) => ware.warehouseId !== warehouse.warehouseId
                ) // Filtra el almacén eliminado
            );
          }
          // Oculta la vista del almacén y reinicia la lista de almacenes
          setShowWarehouse(false);
          // setResetWarehouses(!resetWarehouses);
          return response;
        } else {
          // Si el almacén eliminado no es el seleccionado, solo oculta la vista
          setShowWarehouse(false);
          // setResetWarehouses(!resetWarehouses);
          setWarehousesList(
            (prev) =>
              prev.filter((ware) => ware.warehouseId !== warehouse.warehouseId) // Filtra el almacén eliminado
          );
          return response;
        }
      } else {
        // Si la eliminación falla, actualiza el estado con un mensaje de error
        setStateResponse({
          message: `El almacén "${warehouse?.warehouseName}" no se ha podido eliminar, intente nuevamente`,
          status: 400,
        });
      }
    });
  }
};
