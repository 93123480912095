import React from "react";
// @ts-ignore
import style from "../../pages/MainView/MainView.module.css";
import { LoadingPulseIcon } from "@Models/icons/icons";

const Loader: React.FC = () => {
  return (
    <div className={style.loader}>
      <img
        className={style.AnimatedLoader}
        src={LoadingPulseIcon}
        alt="Cargando"
      />
    </div>
  );
};

export default Loader;
