// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RnJAtPionajP7hT9wBPV {
  position: relative;
  @media screen and (width < 1150px) {
    width: 100%;
  }
}

.Sou6gxArBgPI0QnKtLYR {
  position: relative;
  align-items: flex-start;
  color: #333;
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 50;
  border: 1px solid rgb(204, 204, 204);
  min-width: 300px;
  margin-top: 4px;
  @media screen and (width < 900px) {
    min-width: 10px;
  }
}

.Sou6gxArBgPI0QnKtLYR input {
  width: 240px;
  padding: 1rem !important;
  border: none;
}

#wefLOZAiOFQSTB9aLsDu:hover {
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/WarehouseSelect/WarehouseSelect.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB;IACE,WAAW;EACb;AACF;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,oCAAoC;EACpC,gBAAgB;EAChB,eAAe;EACf;IACE,eAAe;EACjB;AACF;;AAEA;EACE,YAAY;EACZ,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".warehouse {\n  position: relative;\n  @media screen and (width < 1150px) {\n    width: 100%;\n  }\n}\n\n.containerSearchWarehouse {\n  position: relative;\n  align-items: flex-start;\n  color: #333;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  border-radius: 4px;\n  background: #fff;\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  z-index: 50;\n  border: 1px solid rgb(204, 204, 204);\n  min-width: 300px;\n  margin-top: 4px;\n  @media screen and (width < 900px) {\n    min-width: 10px;\n  }\n}\n\n.containerSearchWarehouse input {\n  width: 240px;\n  padding: 1rem !important;\n  border: none;\n}\n\n#hv:hover {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warehouse": `RnJAtPionajP7hT9wBPV`,
	"containerSearchWarehouse": `Sou6gxArBgPI0QnKtLYR`,
	"hv": `wefLOZAiOFQSTB9aLsDu`
};
export default ___CSS_LOADER_EXPORT___;
