import { NavigationBar } from "./components/NavigationBar";
import InformationView from "./views/InformationView/InformationView";
import StockMovements from "./views/StockMovements/StockMovements";

const ProductForm = ({
  availableHistory,
  setAvailableHeaderAction,
  submitButtonRef,
  setHasUnsavedChanges,
  setStateResponse,
  informationView,
  setInformationView,
  errorView,
  setErrorView,
}) => {
  return (
    <>
      {availableHistory && (
        <NavigationBar
          informationView={informationView}
          setInformationView={setInformationView}
        />
      )}
      {informationView ? (
        <InformationView
          editable={availableHistory}
          setAvailableHeaderAction={setAvailableHeaderAction}
          submitButtonRef={submitButtonRef}
          setHasUnsavedChanges={setHasUnsavedChanges}
          setStateResponse={setStateResponse}
          setErrorView={setErrorView}
          errorView={errorView}
        />
      ) : (
        availableHistory && <StockMovements />
      )}
    </>
  );
};

export default ProductForm;
