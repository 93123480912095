import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { editProductCode } from "@Services/products/products/editProductCode.services";

export const editProductCodeAdapter = async (
  idProduct: number,
  code: string
): Promise<ResponseToRequest> => {
  const data = {
    idProduct: idProduct,
    code: code,
  };
  const response = await editProductCode(data);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
