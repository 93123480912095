import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

interface IncomeProducts {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

interface RequestBodyIncomeProducts {
  incomeProducts: {
    idLocalProduct: number;
    amountEnter: number;
    idWarehouse: number;
    totalCost: number;
  }[];
}

export const incomeProducts = async (
  obj: RequestBodyIncomeProducts
): Promise<IncomeProducts> => {
  const url = `${baseUrl}/LocalProducts/IncomeProducts`;
  const ingresa_unidades = 26;
  trackUserAction(ingresa_unidades);
  try {
    const response = await axios.put(url, obj, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al ingresar unidades, intente nuevamente.",
    };
  }
};
