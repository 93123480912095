import { uploadSpreadsheet_service } from "@Services/products/products/uploadSpreadsheet.service";

export const uploadSpreadsheet = async (file: File) => {
  const response = await uploadSpreadsheet_service(file);
  if (response.isSuccess) {
    localStorage.setItem(
      "masiveProducts",
      JSON.stringify(response.data.resumeCreateMasiveProducts)
    );
    window.location.replace("/almacen/productos/crear-masivo/resumen");
  }
  return response;
};
