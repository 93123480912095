import { AdaptedSubBrandsResponse } from "@Models/interfaces/AdaptedSubBrandsResponse.interface";
import { SubBrand } from "@Models/interfaces/productsInterfaces/subBrandInterface/subBrand.interface";
import { getSubBrands } from "@Services/products/subBrands/getSubBrands.services";

export const getSubBrandsAdapter = async (
  subBrandId: number,
  setStateResponse
): Promise<AdaptedSubBrandsResponse> => {
  const subBrands = await getSubBrands(subBrandId);
  if (subBrands.isSuccess) {
    const subBrandsAdapt: SubBrand[] = subBrands.data.map(
      (subBrand: SubBrand) => {
        const warehouseAdapt: SubBrand = {
          subBrandId: subBrand.subBrandId,
          subBrandName: subBrand.subBrandName,
        };
        return warehouseAdapt;
      }
    );
    return {
      isSuccess: true,
      status: subBrands.status,
      subBrands: subBrandsAdapt,
      error: null,
    };
  } else {
    setStateResponse({
      status: subBrands.status,
      message: subBrands.error,
    });
    return {
      isSuccess: false,
      status: subBrands.status,
      subBrands: [],
      error: subBrands.error,
    };
  }
};
