import { useEffect, useRef } from "react";
import style from "./ButtonWarehouseSelect.module.css";
import select_store from "../../../../../../assets/svgs/select_store.svg";
import triangle_select from "../../../../../../assets/svgs/triangle_select.svg";
import { truncateString } from "@Utilities/truncateString";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";

const ButtonWarehouseSelect = ({
  openCloseSelect,
  setShowWarehouse,
  showWarehouse,
  closeOptions,
  warehouseName,
  defaultWarehouse,
  menuRef,
  closeOptionsSelect,
}) => {
  const screenWidth = useScreenWidth();
  const buttonRef = useRef(null);

  const warehouseNameFunction = () => {
    // const length = screenWidth < 500 ? 20 : 100;
    // if (warehouseName) {
    //   return truncateString(warehouseName, length);
    // } else {
    //   return truncateString(defaultWarehouse?.warehouseName, length);
    // }
    return defaultWarehouse?.warehouseName;
  };

  const handleClickOutside = (event) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target) &&
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      closeOptionsSelect(setShowWarehouse);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWarehouse]);

  return (
    <button
      className={style.select}
      onClick={() =>
        openCloseSelect(setShowWarehouse, showWarehouse, closeOptions)
      }
      ref={buttonRef}
    >
      <div className={style.warehouse__content}>
        <img src={select_store} alt="Seleccionar almacén" />
        <p className={style.warehouseName}>
          {warehouseNameFunction()}
          {defaultWarehouse?.sales === 1 && (
            <p className={style.icoSale}>Venta</p>
          )}
        </p>
      </div>
      <img
        src={triangle_select}
        alt="Seleccionar"
        style={{
          transform: showWarehouse ? "rotate(180deg)" : "rotate(0deg)",
        }}
      />
    </button>
  );
};

export default ButtonWarehouseSelect;
