import React from "react";

// @ts-ignore
import style from "./InventoryTable.module.css";

interface InventoryTableProps {
  colgroup?: string;
  columns: string[];
  rows: any[];
  screenWidth?: number;
}

const InventoryTable = ({
  colgroup,
  columns,
  rows,
  screenWidth,
}: InventoryTableProps) => {
  colgroup = colgroup || columns.map(() => "1fr").join(" ");

  if (screenWidth > 900) {
    // Render the table with rows
    return (
      <table className={`${style.container} ${style.table}`}>
        <thead className={style.header}>
          <tr
            style={{
              gridTemplateColumns: colgroup,
            }}
            className={style.tr}
          >
            {columns.map((column, index) => {
              const hideLabel = Number(column);
              const uniqueKey = `column-${column}-${index}`;
              return (
                <th
                  key={uniqueKey}
                  className={`${style.column__name} ${
                    colgroup ? "" : style.default__cell
                  } ${style.th}`}
                >
                  {hideLabel ? "" : column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={style.tbody}>
          {rows.map((row, index) => {
            const uniqueKey = `row-${index}`;
            return (
              <tr
                key={uniqueKey}
                style={{
                  gridTemplateColumns: colgroup,
                }}
                className={style.tr}
              >
                {columns.map((column, index) => {
                  const uniqueKey = `row-${index}-${column}`;
                  return (
                    <td
                      key={uniqueKey}
                      className={`${
                        colgroup ? style.spaced__cell : style.default__cell
                      } ${style.td}`}
                    >
                      <span
                        className={`${style.cell__text} ${style.centered__cell}`}
                      >
                        {row[column]}
                      </span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    // Render cards instead of rows
    return (
      <div className={style.cardContainer}>
        {rows.map((row, index) => {
          const uniqueKey = `row-${index}`;
          return (
            <div key={uniqueKey} className={style.card}>
              {columns.map((column, index) => {
                const uniqueKey = `row-${index}-${column}`;
                return (
                  <div key={uniqueKey} className={style.cardItem}>
                    <span
                      className={style.cardLabel}
                      style={{
                        opacity: column === "2" || column === "1" ? 0 : 1,
                      }}
                    >
                      {column}
                    </span>
                    <span className={style.cardValue}>{row[column]}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
};

export default InventoryTable;
