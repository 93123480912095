// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p8XS7glwvyQt7Kr45QPw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: none;
  background: white;
  margin-bottom: 6px;
  min-height: 100px;
}

.FWJS337OUMJwVFYkVvCj {
  display: flex;
  justify-content: center;
}

.dAdPKBs5jid5KkfkT7El {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  text-align: center;
}

.uSzL8qjQsfEnLjb25P45 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/ModalTransfer copy/SelectWarehouseModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n  border: none;\n  background: white;\n  margin-bottom: 6px;\n  min-height: 100px;\n}\n\n.containerTitle {\n  display: flex;\n  justify-content: center;\n}\n\n.message {\n  font-size: 14px;\n  font-weight: 500;\n  color: #000000;\n  text-align: center;\n}\n\n.warehouseSelector {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `p8XS7glwvyQt7Kr45QPw`,
	"containerTitle": `FWJS337OUMJwVFYkVvCj`,
	"message": `dAdPKBs5jid5KkfkT7El`,
	"warehouseSelector": `uSzL8qjQsfEnLjb25P45`
};
export default ___CSS_LOADER_EXPORT___;
