import { IProductInventory } from "@Hooks/useWarehouseInventoryStore";
import {
  ISaveInventoryProduct,
  saveInventory_service,
} from "@Services/inventory/saveInventory";

export const saveInventory = async (
  inventoryId: number,
  products: IProductInventory[]
) => {
  const inventoryProducts: ISaveInventoryProduct[] = products.map((product) => {
    return {
      inventoryDetailId: product.inventoryId || null,
      localProductId: product.id,
      count: Number(product.counter),
      comments: product.differenceMessage || null,
    };
  });

  const payload = {
    inventoryId: inventoryId,
    inventorySaveChangeDetails: inventoryProducts,
  };

  const response = await saveInventory_service(payload);
  return response;
};
