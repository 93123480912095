import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export interface CreateWarehouse {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

export const createWarehouse = async (
  nameWarehouse: object
): Promise<CreateWarehouse> => {
  const url = `${baseUrl}/Warehouses/Warehouse`;
  const crea_almacen = 19;
  trackUserAction(crea_almacen);
  try {
    const response = await axios.post(url, nameWarehouse, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message || "Error al crear almacén.",
    };
  }
};
