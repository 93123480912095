// @ts-ignore
import { truncateString } from "@Utilities/truncateString";
import style from "./styles/tableProductBuild.module.css";
import { copyCodeToClipboard } from "@Utilities/copyCodeToClipboard";

interface ITableProduct {
  name: string;
  brand: string;
  subBrand: string;
  code: string;
}

export const productTableBuilder = (
  setStateResponse,
  { name, brand, subBrand, code }: ITableProduct
) => {
  const capitalizedSubBrand = subBrand
    ?.split(" ")
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase()
    )
    .join(" ");

  return (
    <article className={style.container}>
      {/* <span className={style.brand}>{brand}</span> */}
      <div className={style.content}>
        <span className={style.product__name}>{name}</span>
        <span className={style.product__brand}>
          {brand}{" "}
          {capitalizedSubBrand &&
            `/ ${truncateString(capitalizedSubBrand, 15)}`}
        </span>
        <button
          className={style.product__code}
          onClick={() => {
            setStateResponse({
              message: "Código copiado al portapapeles",
              status: 200,
            });
            copyCodeToClipboard(code);
          }}
        >
          Cod: {code}
        </button>
      </div>
    </article>
  );
};
