import React, { useEffect } from "react";
import ReactDOM from "react-dom";

interface ModalFullScreenProps {
  children: React.ReactNode;
}

const ModalFullScreen: React.FC<ModalFullScreenProps> = ({ children }) => {
  const portalNode = document.createElement("div");

  useEffect(() => {
    document.body.appendChild(portalNode);
    return () => {
      document.body.removeChild(portalNode);
    };
  }, [portalNode]);

  return ReactDOM.createPortal(children, portalNode);
};

export default ModalFullScreen;
