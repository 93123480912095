import React from "react";
// @ts-ignore
import styled from "../../SearchBar.module.css";

const SearchResult = ({
  name,
  brand,
  category,
  family,
  cod,
  isLastElement = false,
  handleSubmit,
}) => {
  const resultElement = (
    <div className={styled.results__brand_name}>
      <img
        className={styled.results__brand_icon}
        src="https://vectorlogoseek.com/wp-content/uploads/2018/12/loreoal-paris-vector-logo.png"
        alt="Marca"
      />
      <article className={styled.results__lastElement}>
        <strong className={styled.results__name}>{name}</strong>
        <p className={styled.results__brand}>
          {brand} / {family}
        </p>
        <p className={styled.results__cod}>{cod}</p>
      </article>
    </div>
  );

  return (
    <button
      className={
        isLastElement
          ? styled.results__elementContainer__lastElement
          : styled.results__elementContainer
      }
      onClick={() => handleSubmit({ name, brand, category, family, cod })}
    >
      {resultElement}
      <div className={styled.results__tagsContainer}>
        {category &&
          category.map((c, i) => (
            <p key={i} className={styled.results__tags}>
              {c}
            </p>
          ))}
      </div>
    </button>
  );
};

export default SearchResult;
