import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { useState, useEffect } from "react";
import { getProductsAdapter } from "@Adapters/productsAdapters/products/getProducts.adapter";
import selectedWarehouseGlobal from "./UseSelectedWarehouse";

const useProductInfo = (
  id: string,
  setErrorView,
  setStateResponse?,
  productInfo?
) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [product, setProduct] = useState<Product | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [hasFetchedWarehouses, setHasFetchedWarehouses] = useState(false);

  const selectedWarehouseString = selectedWarehouseGlobal();

  useEffect(() => {
    setIsLoading(true);
    if (!hasFetchedWarehouses) {
      setHasFetchedWarehouses(true);
      (async () => {
        try {
          const selectedWarehouse = await selectedWarehouseString;

          if (selectedWarehouse?.warehouseId) {
            const response = await getProductsAdapter(
              selectedWarehouse.warehouseId,
              setErrorView,
              setStateResponse
            );
            if (response.isSuccess) {
              setProducts(response.products);
            }
          }
        } catch (error) {
          console.error("Error al obtener el almacén seleccionado:", error);
          setErrorView(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We filter the product by id and then return the product information
  useEffect(() => {
    if (productInfo) {
      setProduct(productInfo);
      return;
    } else {
      const filteredProduct = products?.find(
        (product) => product.productLocalId === parseInt(id)
      );
      setProduct(filteredProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, id, productInfo]);

  return { product, isLoading, products };
};

export default useProductInfo;
