import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import styled from "./SearchBar.module.css";
import SearchInput from "./components/SearchInput/SearchInput";
import SearchResults from "./components/SearchResults/SearchResults";
import Filter from "./components/Filter/Filter";
import SearchResultsSimpleData from "./components/SearchResultsSimpleData/SearchResultsSimpleData";
import { SearchBarProps } from "@Models/interfaces/SearchBar.interface";

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Buscar por nombre producto o código de barras",
  dataSearchable = [],
  isSimpleData = false,
  onClickResult = (result) => {},
  isFiltered = true,
  filterOptions = ["order", "productsType", "productsCategories"],
  isSearchAvailable = true,
  handleClick = () => {},
  returnNewResults,
  markedBrand = "",
  setMarkedBrand,
  value = "",
  markedCategory = [],
  handleButtonClickDelete,
  isCategory = false,
  messageCreateItem,
  error,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchView, setSearchView] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const handleFilterChange = () => {};
  const searchContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainer?.current &&
        !searchContainer.current.contains(event.target) &&
        event.target !== searchInputRef?.current
      ) {
        if (markedBrand) {
          setSearchInput("");
        }
        if (!markedBrand) {
          if (setMarkedBrand) {
            setMarkedBrand(value);
            setSearchInput("");
          }
        }
        if (isCategory) {
          setSearchInput("");
        }
        setSearchView(false);
      }
    };

    const handleKeyDown = (event) => {
      if (
        event?.key === "Escape" ||
        event?.key === "Esc" ||
        event?.key === "Enter"
      ) {
        if (markedBrand) {
          setSearchInput("");
        }
        setSearchView(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContainer, searchInputRef, markedBrand]);

  const onSearch = (result: string) => {
    if (result === "") return;
    onClickResult(result);
    setSearchInput("");
  };

  return (
    <div className={styled.searchBar}>
      <section className={styled.searchBar__container}>
        <SearchInput
          onClick={() => {
            setSearchView(true);
            handleClick();
          }}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          placeholder={placeholder}
          isSearchAvailable={isSearchAvailable}
          formTypeInput="myFormType"
          refProp={searchInputRef}
          markedBrand={markedBrand}
          setMarkedBrand={setMarkedBrand}
          error={error}
        />
        <section
          ref={searchContainer}
          className={
            searchView
              ? styled.searchBarResults__containerActive
              : styled.searchBarResults__container
          }
        >
          {!isSimpleData ? (
            <SearchResults
              searchInput={searchInput}
              dataSearchable={dataSearchable}
              handleSubmit={(e) => onSearch(e)}
            />
          ) : (
            <SearchResultsSimpleData
              handleSubmit={(e) => onSearch(e)}
              searchInput={searchInput}
              dataSearchable={dataSearchable}
              returnNewResults={returnNewResults}
              inputRef={searchInputRef}
              handleButtonClickDelete={handleButtonClickDelete}
              markedBrand={value}
              markedCategory={markedCategory}
              isCategory={isCategory}
              messageCreateItem={messageCreateItem}
            />
          )}
        </section>
      </section>
      {isFiltered ? (
        <Filter
          filterUsed={filterOptions}
          handleFilterChange={handleFilterChange}
        />
      ) : null}
    </div>
  );
};

export default SearchBar;
