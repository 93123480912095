export const convertDate = (date: string | null | undefined): string => {
  if (!date || date.trim() === "") {
    return "";
  }

  const [day, month, year] = date.split("/");

  if (!day || !month || !year) {
    return "";
  }

  return `${year}-${month}-${day}`;
};

export const formatDate = (dateStr: string | null | undefined): string => {
  if (!dateStr || typeof dateStr !== "string") {
    return "";
  }

  const [year, month, day] = dateStr.split("-");

  if (!year || !month || !day) {
    return "";
  }

  return `${day}/${month}/${year}`;
};
