// @ts-ignore
import style from "./Table.module.css";
import React, { useState, useEffect } from "react";
import { handleIncomePriceChange } from "./functions/TableFunctions";
import { TableProps } from "@Models/interfaces/Table.interface";
import {
  SelectInput,
  InputAdditionSubtract,
  TextInput,
  ButtonWithoutIcon,
} from "../../resources/index";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { copyCodeToClipboard } from "@Utilities/copyCodeToClipboard";

const Table: React.FC<TableProps> = ({
  income,
  withdrawal,
  transfer,
  product,
  setWithdrawalTotal,
  setIncomeTotal,
  setTransferTotal,
  listOfReason = false,
  setReasonId,
  setCost,
  setErrorMessage,
  errorMessage,
  reasonId,
  functionButton,
  cost,
  loading,
  setStateResponse,
  incomePriceValue,
  setIncomePriceValue,
}) => {
  const [withdrawalValue, setWithdrawalValue] = useState<number>(1);
  const [transferValue, setTransferValue] = useState<number>(1);
  const [incomeValue, setIncomeValue] = useState<number>(1);
  const screenWidth = useScreenWidth();

  const copyCode = (code: string) => {
    setStateResponse({
      message: "Código copiado al portapapeles",
      status: 200,
    });
    copyCodeToClipboard(code);
  };

  useEffect(() => {
    setWithdrawalTotal && setWithdrawalTotal(withdrawalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawalValue]);

  useEffect(() => {
    setIncomeTotal && setIncomeTotal(incomeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomeValue]);

  useEffect(() => {
    setTransferTotal && setTransferTotal(transferValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferValue]);

  useEffect(() => {
    setCost && setCost(incomePriceValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomePriceValue]);

  // si screenWidth es mayor a retorname todo, sino retorname todo el mobile
  if (screenWidth >= 900) {
    return (
      <div className={style.container}>
        <div className={style.containerTop}>
          <span
            className={style.span}
            style={{
              marginLeft: "1.5rem",
            }}
          >
            Producto
          </span>
          <div className={style.containerOtherSpans}>
            {withdrawal && (
              <span className={style.span}>Unidades a retirar</span>
            )}
            {income && <span className={style.span}>Unidades a ingresar</span>}
            {transfer && (
              <span className={style.span}>Unidades a transferir</span>
            )}
            {withdrawal && <span className={style.span}>Motivo</span>}
            {income && <span className={style.span}>Costo Unitario</span>}
          </div>
        </div>
        <div className={style.containerBottom}>
          <div className={style.containerGen}>
            <div className={style.containerProduct}>
              <div>
                {screenWidth > 900 && (
                  <div className={style.brandImg}>
                    <div>{product?.brand?.brandName}</div>
                  </div>
                )}
                {product?.line?.lineName && (
                  <div className={style.subBrandImg}>
                    {product?.line?.lineName}
                  </div>
                )}
              </div>
              <div className={style.containerInfoProducto}>
                <span className={style.name}>{product?.productName}</span>
                {product?.code && (
                  <button
                    onClick={() => copyCode(product?.code)}
                    className={style.codeButton}
                  >
                    Cod. {product?.code}
                  </button>
                )}
              </div>
            </div>
            <div
              className={style.containerInputsInfo}
              style={{
                marginRight: withdrawal ? "1rem" : "0rem",
              }}
            >
              {withdrawal && (
                <div className={style.withdrawalS}>
                  <InputAdditionSubtract
                    value={withdrawalValue}
                    setValue={setWithdrawalValue}
                    min={1}
                    max={product?.stock}
                    placeholder={"0"}
                  />
                </div>
              )}
              {income && (
                <div className={style.incomeM}>
                  <InputAdditionSubtract
                    value={incomeValue}
                    setValue={setIncomeValue}
                    min={1}
                    max={999}
                    placeholder={"0"}
                  />
                </div>
              )}
              {income && (
                <div className={style.incomeT}>
                  <TextInput
                    value={incomePriceValue}
                    handleChange={(e) =>
                      handleIncomePriceChange(
                        e,
                        setErrorMessage,
                        setIncomePriceValue
                      )
                    }
                    placeholder={"0"}
                    keyboardType={"number"}
                    width={"90px"}
                  />
                </div>
              )}
              {transfer && (
                <div
                  className={style.paddingTransfer}
                  style={{
                    marginRight: transfer ? "3.9rem" : "0rem",
                  }}
                >
                  <InputAdditionSubtract
                    value={transferValue}
                    setValue={setTransferValue}
                    min={1}
                    max={product?.stock}
                    placeholder={"0"}
                  />
                </div>
              )}

              {listOfReason && (
                <SelectInput
                  value={reasonId === 0 ? "" : reasonId}
                  touched={errorMessage}
                  error={errorMessage}
                  items={listOfReason}
                  handleChange={(e) => {
                    setReasonId(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {withdrawal && (
          <div className={style.containerCard}>
            <p className={style.cardTitle}>Retirar unidades</p>
            <div>
              <p>Producto</p>
              <p className={style.productNameStyle}>{product?.productName}</p>
              <button
                onClick={() => copyCode(product?.code)}
                className={style.codeButton}
              >
                Cod. {product?.code}
              </button>
            </div>
            <div className={style.containerItem}>
              <p>
                Unidades a retirar <span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <TextInput
                  value={withdrawalValue || 1}
                  handleChange={(e) =>
                    setWithdrawalValue(Number(e.target.value))
                  }
                  placeholder={"0"}
                  keyboardType={"number"}
                  width={"90px"}
                />
              </div>
            </div>
            <div className={style.containerItem}>
              <p>
                Motivo <span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <SelectInput
                  value={reasonId === 0 ? "" : reasonId}
                  touched={errorMessage}
                  error={errorMessage}
                  items={listOfReason}
                  handleChange={(e) => {
                    setReasonId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className={style.containerButton}>
              <ButtonWithoutIcon
                textBttn={"Continuar"}
                handleClick={() => functionButton()}
                isDisabled={
                  !reasonId ||
                  loading ||
                  withdrawalValue === 0 ||
                  isNaN(withdrawalValue)
                }
                isPrimary={true}
                isLoading={loading}
              />
            </div>
          </div>
        )}
        {income && (
          <div className={style.containerCard}>
            <p className={style.cardTitle}>Ingresar unidades</p>
            <div>
              <p>Producto</p>
              <p className={style.productNameStyle}>{product?.productName}</p>
              <button
                onClick={() => copyCode(product?.code)}
                className={style.codeButton}
              >
                Cod. {product?.code}
              </button>
            </div>
            <div className={style.containerItem}>
              <p>
                Unidades a ingresar <span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <TextInput
                  value={incomeValue}
                  handleChange={(e) => setIncomeValue(Number(e.target.value))}
                  placeholder={"0"}
                  keyboardType={"number"}
                  width={"90px"}
                />
              </div>
            </div>
            <div className={style.containerItem}>
              <p>
                Costo Unitario <span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <TextInput
                  value={incomePriceValue}
                  handleChange={(e) =>
                    handleIncomePriceChange(
                      e,
                      setErrorMessage,
                      setIncomePriceValue
                    )
                  }
                  placeholder={"0"}
                  keyboardType={"number"}
                  width={"90px"}
                />
              </div>
            </div>
            <div className={style.containerButton}>
              <ButtonWithoutIcon
                textBttn={"Continuar"}
                handleClick={() => functionButton()}
                isDisabled={!cost || loading}
                isPrimary={true}
                isLoading={loading}
              />
            </div>
          </div>
        )}
        {transfer && (
          <div className={style.containerCard}>
            <p className={style.cardTitle}>Transferir unidades</p>
            <div>
              <p>Producto</p>
              <p className={style.productNameStyle}>{product?.productName}</p>
              <button
                onClick={() => copyCode(product?.code)}
                className={style.codeButton}
              >
                Cod. {product?.code}
              </button>
            </div>
            <div className={style.containerItem}>
              <p>
                Unidades a transferir <span style={{ color: "red" }}>*</span>
              </p>
              <div>
                <InputAdditionSubtract
                  value={transferValue}
                  setValue={setTransferValue}
                  min={1}
                  max={product?.stock}
                  placeholder={"0"}
                />
              </div>
            </div>
            <div className={style.containerButton}>
              <ButtonWithoutIcon
                textBttn={"Continuar"}
                handleClick={() => functionButton()}
                isDisabled={loading}
                isPrimary={true}
                isLoading={loading}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Table;
