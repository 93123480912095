import { create } from "zustand";

export interface IInventoryHeaderStore {
  previousAction: () => void;
  setPreviousAction: (action: () => void) => void;
  warehouseProps: {
    title: string;
  };
  setWarehouseProps: (props: any) => void;
  showButton: boolean;
  setShowButton: (show: boolean) => void;
  showSecondaryButton: boolean;
  setShowSecondaryButton: (show: boolean) => void;
  buttonProps: {
    textBttn: string;
    handleClick: () => void;
    isDisabled: boolean;
    isPrimary: boolean;
    style: React.CSSProperties;
  };
  setButtonProps: (props: any) => void;
  secondaryButtonProps: {
    textBttn: string;
    handleClick: () => void;
    isDisabled: boolean;
    isPrimary: boolean;
    style: React.CSSProperties;
  };
  setSecondaryButtonProps: (props: any) => void;
  styleContainer: React.CSSProperties;
}

export const useInventoryHeaderStore = create((set) => ({
  previousAction: () => {},
  setPreviousAction: (action: () => void) => set({ previousAction: action }),
  warehouseProps: {
    title: "Almacen - Inventario",
  },
  setWarehouseProps: (props: any) => set({ warehouseProps: props }),
  showButton: true,
  setShowButton: (show: boolean) => set({ showButton: show }),
  showSecondaryButton: true,
  setShowSecondaryButton: (show: boolean) => set({ showSecondaryButton: show }),
  buttonProps: {
    textBttn: "Revisar",
    handleClick: () => {},
    isDisabled: false,
    isPrimary: true,
    style: { maxHeight: "40px" },
  },
  setButtonProps: (props: any) => set({ buttonProps: props }),
  secondaryButtonProps: {
    textBttn: "Guardar",
    handleClick: () => {},
    isDisabled: false,
    isPrimary: false,
    style: { maxHeight: "40px" },
  },
  setSecondaryButtonProps: (props: any) => set({ secondaryButtonProps: props }),
  styleContainer: { gap: "20px", pading: "0px" },
}));
