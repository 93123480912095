export const handleIncomePriceChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setErrorMessage: any,
  setIncomePriceValue: any
) => {
  let value = event.target.value;

  value = value.replace(",", ".");

  const regex = /^\d{0,4}(\.\d{0,2})?$/;
  if (!regex.test(value)) {
    setErrorMessage && setErrorMessage(true);
    return;
  }
  setErrorMessage && setErrorMessage(false);

  if (value === "" || value === ".") {
    setIncomePriceValue("");
  } else {
    setIncomePriceValue(parseFloat(value));
  }
};
