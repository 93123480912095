import React, { useEffect, useState } from "react";
// @ts-ignore
import styled from "./SuccessMessage.module.css";
import { SuccessMessageProps } from "@Models/interfaces/SuccessMessage.interface";
import { CalendarIcon, OperationSuccessIcon } from "@Models/icons/icons";
import { handleDoneClick } from "@Utilities/CurrentDate";

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  actionTitle = "Aqui va el accionTitle",
  actionMsg = "Aqui va el accionMsg",
  startDate = "",
  endDate = "",
  showStatus = true,
}) => {
  const [currentDate, setCurrentDate] = useState("");
  useEffect(() => {
    handleDoneClick(setCurrentDate);
  }, []);
  const dateContainer = (typeDate: string, date: string) => {
    return (
      <article className={styled.successMsg__datesContainerArticle}>
        <img src={CalendarIcon} alt="Calendario" />
        <strong>{typeDate}</strong>
        <p>{date}</p>
      </article>
    );
  };

  return (
    <div className={styled.successMsg__container}>
      {showStatus && (
        <>
          <img src={OperationSuccessIcon} alt="Calendario" />
          <h3 className={styled.successMsg__title}>{actionTitle}</h3>
        </>
      )}
      <section className={styled.successMsg__infoContainer}>
        {actionMsg}
      </section>
      <section className={styled.successMsg__datesContainer}>
        {currentDate && dateContainer("Realizado", currentDate)}
        {startDate && dateContainer("Iniciado", startDate)}
        {endDate && dateContainer("Finalizado", endDate)}
      </section>
    </div>
  );
};

export default SuccessMessage;
