// @ts-ignore
import style from "./MainView.module.css";
import { useEffect, useRef, useState } from "react";
import ModalFullScreen from "../../components/ModalFullScreen/ModalFullScreen";
import { useNavigate } from "react-router";
import {
  HeaderNavigation,
  SearchWithColoredBorder,
  SlideNotification,
  Icon,
  TableMobile,
  PaginationWrapper,
  CriticalErrorPage,
  validateModuleAccess,
  UnauthorizedMessage,
} from "../../resources";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { getWarehousesAdapter } from "@Adapters/warehousesAdapters/getWarehouses.adapter";
import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { getProductsAdapter } from "@Adapters/productsAdapters/products/getProducts.adapter";
import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import ModalTransfer from "./componentsMainView/ModalTransfer/ModalTransfer";
import DeleteProduct from "./componentsMainView/DeleteProduct/DeleteProduct";
import WarehouseSelect from "./componentsMainView/WarehouseSelect/WarehouseSelect";
import MainTable from "./componentsMainView/MainTable/MainTable";
import { clickHandler } from "@Utilities/clickHandler";
import { filteredProducts } from "./functions/filteredProducts";
import {
  FilteredOutIcon,
  LoadingPulseIcon,
  OpenBoxIcon,
} from "@Models/icons/icons";
import { postWarehousesAdapter } from "@Adapters/warehousesAdapters/postWarehouses.adapter";
import SelectWarehouseModal from "./componentsMainView/ModalTransfer copy/SelectWarehouseModal";
import InventorySelect from "./componentsMainView/InventorySelect/InventorySelect";
import FilterProducts from "./componentsMainView/FilterProducts/FilterProducts";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { tableMobile } from "./componentsMainView/WarehouseSelect/tableMobile/tableMobile";
import ModalOptionsProduct from "@Components/ModalOptionsProduct/ModalOptionsProduct";
// @ts-ignore
import { IconWithOptions, trackUserAction } from "@viuti/recursos";
import { HiMiniListBullet } from "react-icons/hi2";
import {
  CREATE_PRODUCTS_UUID,
  MASSIVE_LOAD_PRODUCTS_UUID,
  SHOW_INVENTORY_HISTORY_UUID,
  SHOW_PRODUCTS_AND_WAREHOUSES_UUID,
  UPSERT_INVENTORIES_UUID,
} from "@Models/const/securityAccess";
import AvailableWarehouses from "@Components/AvailableWarehouses/AvailableWarehouses";
import WarehouseStatusCard from "@Components/WarehouseStatusCard/WarehouseStatusCard";
import {
  getWarehouseStatusAdapter,
  WarehouseStatus,
} from "@Adapters/warehousesAdapters/getWarehouseStatus.adapter";
import { setSessionWarehouse } from "@Utilities/warehouseUtils";

const MainView = () => {
  const navigate = useNavigate();
  const mobileScreenBreakpoint = 1150;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(true);
  const [warehousesList, setWarehousesList] = useState<Warehouse[]>([]);
  const [selectWarehouse, setSelectWarehouse] = useState<Warehouse | null>(
    null
  );
  const [products, setProducts] = useState<Product[]>([]);
  const [warehouseStatus, setWarehouseStatus] =
    useState<WarehouseStatus | null>(null);
  const [errorView, setErrorView] = useState({
    errorProduct: false,
    errorWarehouse: false,
    errorWarehouseStatus: false,
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [productDelete, setProductDelete] = useState<Product | null>(null);
  const [resetProducts, setResetProducts] = useState<boolean>(false);
  const [resetWarehouses, setResetWarehouses] = useState<boolean>(false);
  const [idProductModal, setIdProductModal] = useState<number>(0);
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const [modal, setModal] = useState<number>(0);
  const [unitOrVolume, setUnitOrVolume] = useState({
    unit: false,
    volume: false,
  });
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [productsList, setProductsList] = useState<Product[]>([]);
  const [showWarehouse, setShowWarehouse] = useState(false);
  const [selectProduct, setSelectProduct] = useState<number | null>(null);
  const screenWidth = useScreenWidth();
  const [filtered, setFiltered] = useState({
    order: {
      defaultOrder: false,
      lowStock: false,
      highStock: false,
    },
    viewForProducts: {
      inStock: false,
      outOfStock: false,
    },
    categories: [],
  });

  const ingresa_vista_productos = 18;
  useEffect(() => {
    trackUserAction(ingresa_vista_productos);
  }, []);
  const productsList_ = filteredProducts(products, searchInput);
  let productFilter = productsList_.filter((product) => {
    if (
      filtered.order.defaultOrder === false &&
      filtered.order.lowStock === false &&
      filtered.order.highStock === false &&
      filtered.viewForProducts.inStock === false &&
      filtered.viewForProducts.outOfStock === false &&
      filtered.categories.length === 0
    ) {
      return product;
    }
    if (
      !filtered.viewForProducts.inStock &&
      !filtered.viewForProducts.outOfStock
    ) {
      return product;
    }
    if (filtered.viewForProducts.inStock && product.stock > 0) {
      return product;
    }
    if (filtered.viewForProducts.outOfStock && product.stock <= 0) {
      return product;
    }
  });

  const productsListOrdered = productFilter.sort((a, b) => {
    if (filtered.order.defaultOrder) return 0;
    if (filtered.order.lowStock) return a.stock - b.stock;
    if (filtered.order.highStock) return b.stock - a.stock;
    return 0;
  });

  const productsListCategory = productsListOrdered.filter((product) => {
    if (filtered.categories.length === 0) {
      return product;
    }
    if (filtered.categories.length !== 0) {
      return filtered.categories.includes(product.category[0].categoryName);
    }
  });

  useEffect(() => {
    const productsListPaginated = productsListCategory.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    const productsListPaginatedDefault = productsList_.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    if (
      filtered.order.defaultOrder === false &&
      filtered.order.lowStock === false &&
      filtered.order.highStock === false &&
      filtered.viewForProducts.inStock === false &&
      filtered.viewForProducts.outOfStock === false &&
      filtered.categories.length === 0
    ) {
      setProductsList(productsListPaginatedDefault);
    } else {
      setProductsList(productsListPaginated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination, searchInput, products, filtered]);

  useEffect(() => {
    setCurrentPagination(1);
  }, [searchInput]);

  // Efecto para cargar la lista de almacenes
  useEffect(() => {
    async function fetchWarehouses() {
      setIsLoading(true);
      const warehousesResponse = await getWarehousesAdapter({
        setStateResponse,
        setIsLoading: () => {},
        setErrorView,
      });
      if (warehousesResponse.isSuccess) {
        let selectedWarehouse = JSON.parse(
          localStorage.getItem("selectedWarehouse")
        );
        setWarehousesList(warehousesResponse.warehouses);
        // Seleccionar el almacén por defecto
        const defaultWarehouse =
          selectedWarehouse ||
          warehousesResponse.warehouses.find((w) => w.sales) ||
          warehousesResponse.warehouses[0];
        setSelectWarehouse(defaultWarehouse);
        setSessionWarehouse({
          warehouseId: defaultWarehouse?.warehouseId,
          warehouseName: defaultWarehouse?.warehouseName,
          sales: defaultWarehouse.sales,
          warehouseValue: defaultWarehouse?.warehouseValue || 0,
        });
      } else {
        setErrorView((prev) => ({ ...prev, errorWarehouse: true }));
      }
      setIsLoading(false);
    }

    fetchWarehouses();
  }, []);

  // Efecto para cargar productos y estado del almacén cuando se selecciona un almacén
  useEffect(() => {
    async function fetchWarehouseData() {
      if (!selectWarehouse) return;
      setIsLoadingStatus(true);
      setIsLoading(true);
      let hasError = false;

      // Obtener productos
      const productsResponse = await getProductsAdapter(
        selectWarehouse.warehouseId,
        setErrorView
      );
      setIsLoading(false);
      if (productsResponse.isSuccess) {
        const recentProductsFirst = productsResponse.products.reverse();
        setProducts(recentProductsFirst);
      } else {
        hasError = true;
      }
      // Obtener estado del almacén
      const warehouseStatusResponse = await getWarehouseStatusAdapter(
        selectWarehouse.warehouseId
      );
      setIsLoadingStatus(false);
      if (warehouseStatusResponse.isSuccess) {
        setWarehouseStatus(warehouseStatusResponse.data);
      } else {
        hasError = true;
        setErrorView((prev) => ({ ...prev, errorWarehouseStatus: true }));
      }

      if (hasError) {
        setStateResponse({
          message: "Hubo un error al cargar algunos datos",
          status: 500,
        });
      }
    }

    fetchWarehouseData();
  }, [selectWarehouse]);

  // cuando se cambia de almacen el currentPagination se setea en 1
  useEffect(() => {
    setCurrentPagination(1);
  }, [selectWarehouse]);

  const closeModal = () => {
    setModal(0);
  };

  const renderModal = (idRenderModal: number) => {
    const modalContent = {
      1: (
        <ModalTransfer
          close={closeModal}
          warehousesList={warehousesList}
          unitOrVolume={unitOrVolume}
          idProduct={idProductModal}
          selectWarehouse={selectWarehouse}
        />
      ),
      2: (
        <SelectWarehouseModal
          close={closeModal}
          warehousesList={warehousesList}
          unitOrVolume={unitOrVolume}
          idProduct={idProductModal}
        />
      ),
      4: (
        <DeleteProduct
          close={closeModal}
          productDelete={productDelete}
          resetProducts={resetProducts}
          setResetProducts={setResetProducts}
          setStateResponse={setStateResponse}
          setProductsList={setProductsList}
          productsList={productsList}
          setProducts={setProducts}
        />
      ),
      5: (
        <ModalOptionsProduct
          close={closeModal}
          navigate={navigate}
          setSelectProduct={setSelectProduct}
          setUnitOrVolume={setUnitOrVolume}
          setIdProductModal={setIdProductModal}
          setModal={setModal}
          selectProduct={selectProduct}
          screenWidth={screenWidth}
        />
      ),
    };
    return modalContent[idRenderModal];
  };

  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(!openFilter);
  };
  const closeFilter = () => {
    setOpenFilter(false);
  };
  const filterRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        closeFilter();
      }
    };

    if (openFilter) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openFilter]);

  const otherComponent = () => {
    if (screenWidth < mobileScreenBreakpoint) {
      return (
        <div>
          <IconWithOptions
            buttonsStyles={{
              padding: "15px",
            }}
            containerStyles={{
              borderRadius: "6px",
            }}
            icon={<HiMiniListBullet size={40} color={"#473693"} />}
            actions={[
              {
                label: "Nuevo producto",
                handler: () => {
                  validateModuleAccess(CREATE_PRODUCTS_UUID) &&
                    clickHandler(navigate, "productos/crear");
                },
              },
              {
                label: "Carga masiva",
                handler: () => {
                  validateModuleAccess(MASSIVE_LOAD_PRODUCTS_UUID) &&
                    navigate("productos/crear-masivo");
                },
              },
              {
                label: "Hacer inventario",
                handler: () => {
                  validateModuleAccess(UPSERT_INVENTORIES_UUID) && setModal(2);
                },
              },
              {
                label: "Historial de inventario",
                handler: () => {
                  validateModuleAccess(SHOW_INVENTORY_HISTORY_UUID) &&
                    clickHandler(navigate, "historial-inventario");
                },
              },
            ]}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  if (isLoading)
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <figure className={style.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );

  if (errorView.errorWarehouse || errorView.errorProduct) {
    return <CriticalErrorPage />;
  }

  return (
    <div id="viuti-front-mainContent">
      {!errorView.errorWarehouse ? (
        <>
          <HeaderNavigation
            title={"Productos"}
            previousAction={false}
            previousActionMovement={""}
            buttonProps={{
              textBttn: "Nuevo producto",
              handleClick: () =>
                validateModuleAccess(CREATE_PRODUCTS_UUID) &&
                clickHandler(navigate, "productos/crear"),
              isDisabled: false,
              isPrimary: true,
              isHidden: screenWidth < mobileScreenBreakpoint,
            }}
            buttonTwoProps={{
              textBttn: "Carga masiva",
              handleClick: () => navigate("productos/crear-masivo"),
              isDisabled: false,
              isPrimary: false,
              isHidden: screenWidth < mobileScreenBreakpoint,
            }}
            otherComponent={otherComponent()}
          />
          <main className={style.view__content}>
            <section className={style.warehouse_status_container}>
              {isLoadingStatus && !errorView.errorWarehouseStatus ? (
                <p>Cargando...</p>
              ) : (
                <div className={style.warehouse_status_container}>
                  {errorView.errorWarehouseStatus ? (
                    <p className={style.errorMessageInfoWarehouse}>
                      Hubo un problema al traer la información del almacén
                    </p>
                  ) : (
                    <>
                      <AvailableWarehouses
                        warehouses={warehousesList}
                        parameterName={"almacenes"}
                      />
                      <div className={style.warehouse_status_content}>
                        <WarehouseStatusCard
                          warehouseValue={warehouseStatus?.totalValue || 0}
                          incomingMovements={warehouseStatus?.entries || 0}
                          outgoingMovements={warehouseStatus?.withdrawals || 0}
                          salesMovements={warehouseStatus?.sales || 0}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </section>
            <div className={style.view_content}>
              <span className={style.Header_actions}>
                <div className={style.containerWarehouse}>
                  {!validateModuleAccess(
                    SHOW_PRODUCTS_AND_WAREHOUSES_UUID,
                    false
                  ) ? (
                    <UnauthorizedMessage
                      restrictedFeatureName={"Ver almacenes"}
                      restrictedUUID={SHOW_PRODUCTS_AND_WAREHOUSES_UUID}
                      type={"small"}
                    />
                  ) : (
                    <WarehouseSelect
                      showWarehouse={showWarehouse}
                      setShowWarehouse={setShowWarehouse}
                      warehousesList={warehousesList}
                      setWarehousesList={setWarehousesList}
                      defaultWarehouse={selectWarehouse}
                      setSelectWarehouse={setSelectWarehouse}
                      setResetWarehouses={setResetWarehouses}
                      resetWarehouses={resetWarehouses}
                      setStateResponse={setStateResponse}
                      setSelectProduct={setSelectProduct}
                      resetProducts={resetProducts}
                      setResetProducts={setResetProducts}
                    />
                  )}
                  {screenWidth >= mobileScreenBreakpoint && (
                    <InventorySelect setModal={setModal} />
                  )}
                </div>

                <div className={style.containerSearchWithFilter}>
                  <SearchWithColoredBorder
                    width={"100%"}
                    label={""}
                    value={searchInput}
                    placeholder={
                      "Buscar por nombre producto o código de barras"
                    }
                    setValue={setSearchInput}
                    withoutTheContainerWithTheResults={true}
                    clearInput={false}
                  />
                  <div className={style.containerFilter} ref={filterRef}>
                    <button
                      className={style.containerFilterIcon}
                      onClick={handleOpenFilter}
                      title="Filtrar productos"
                    >
                      <Icon
                        path={FilteredOutIcon}
                        alt={"Filtro"}
                        size={"23px"}
                      />
                    </button>
                    <FilterProducts
                      openFilter={openFilter}
                      closeFilter={closeFilter}
                      productsList_={productsList_}
                      filtered={filtered}
                      setFiltered={setFiltered}
                    />
                  </div>
                </div>
              </span>
              {!validateModuleAccess(
                SHOW_PRODUCTS_AND_WAREHOUSES_UUID,
                false
              ) ? (
                <div className={style.unauthorized}>
                  <UnauthorizedMessage
                    restrictedFeatureName={"Ver productos"}
                    restrictedUUID={SHOW_PRODUCTS_AND_WAREHOUSES_UUID}
                  />
                </div>
              ) : (
                <>
                  {screenWidth >= mobileScreenBreakpoint ? (
                    <MainTable
                      productsList_={productsList_}
                      productsList={productsList}
                      setModal={setModal}
                      searchInput={searchInput}
                      setProductDelete={setProductDelete}
                      setUnitOrVolume={setUnitOrVolume}
                      setIdProductModal={setIdProductModal}
                      selectWarehouse={selectWarehouse}
                      showWarehouse={showWarehouse}
                      setShowWarehouse={setShowWarehouse}
                      setSelectProduct={setSelectProduct}
                      selectProduct={selectProduct}
                      openFilter={openFilter}
                      productsListCategory={productsListCategory}
                      currentPagination={currentPagination}
                      setCurrentPagination={setCurrentPagination}
                      setStateResponse={setStateResponse}
                      errorView={errorView}
                    />
                  ) : (
                    <div className={style.containerTableMobile}>
                      {searchInput?.length && !productsList.length ? (
                        <div className={style.tableMsg}>
                          <span className={style.icon__container}>
                            <Icon
                              path={OpenBoxIcon}
                              alt="vacío"
                              size={"76px"}
                            />
                          </span>
                          <p className={style.emptyWarehouse__message}>
                            No hay resultados para tu búsqueda
                            <span>Intenta usar otras palabras.</span>
                          </p>
                        </div>
                      ) : (
                        <div className={style.containerTableMobile}>
                          <TableMobile
                            rowStyles={{
                              gap: "20px",
                            }}
                            styleTitles={{
                              justifyContent:
                                screenWidth < 700 ? "space-between" : "start",
                              width: "100%",
                            }}
                            isLoading={false}
                            dataTable={productsList.map((product, index) =>
                              tableMobile({
                                product,
                                screenWidth,
                                index,
                                navigate,
                                setModal,
                                setProductDelete,
                                setSelectProduct,
                              })
                            )}
                          />
                          <span className={style.containerPagination}>
                            {productsListCategory.length > 10 && (
                              <PaginationWrapper
                                currentPagination={currentPagination}
                                setCurrentPagination={setCurrentPagination}
                                optionsPerPage={10}
                                options={productsListCategory}
                              />
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </main>
          <ModalFullScreen>{renderModal(modal)}</ModalFullScreen>
        </>
      ) : (
        <CriticalErrorPage />
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default MainView;
