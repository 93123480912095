import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { deleteCategory } from "@Services/products/categories/deleteCategory.services";

export const deleteCategoryAdapter = async (
  id: number,
  idCategory: number,
  setStateResponse
): Promise<ResponseToRequest> => {
  const response = await deleteCategory(id, idCategory);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  } else {
    setStateResponse({
      message: response.error,
      status: response.status,
    });
    return {
      isSuccess: false,
      status: response.status,
      data: null,
      error: response.error,
    };
  }
};
