import { getWarehousesAdapter } from "@Adapters/warehousesAdapters/getWarehouses.adapterCopy";
import React, { useEffect } from "react";

export const useGetWarehouse = () => {
  const [warehouseList, setWarehouseList] = React.useState([]);
  useEffect(() => {
    (async () => {
      const response = await getWarehousesAdapter();
      if (response.isSuccess) {
        setWarehouseList(response.data);
      }
    })();
  }, []);

  return { warehouseList };
};
