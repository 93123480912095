import useProductInfo from "@Hooks/useProduct";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import BaseProduct from "@Pages/BaseProduct/BaseProduct";
import { useState } from "react";
import { useParams } from "react-router";

const ViewProduct = () => {
  const { id } = useParams();
  const [errorView, setErrorView] = useState({
    errorProduct: false,
  });
  const { product } = useProductInfo(id, setErrorView);
  const screenWidth = useScreenWidth();
  return (
    <BaseProduct
      title={product?.productName}
      availableHistory={true}
      screenWidth={screenWidth}
      titleMobile={product?.productName || ""}
    />
  );
};

export default ViewProduct;
