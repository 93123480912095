import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import {
  RequestBodyCreateProducts,
  createProduct,
} from "@Services/products/products/createProduct.services";

export const createProductAdapter = async (
  obj: RequestBodyCreateProducts
): Promise<ResponseToRequest> => {
  const response = await createProduct(obj);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
