import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { editProductStock } from "@Services/products/products/editProductStock.services";

export const updateEmergencyStockAdapter = async (
  idProduct: number,
  emergencyStock: number,
  idWareHouse: number,
  productLocalId: number,
  stock: number
): Promise<ResponseToRequest> => {
  let obj = {
    idProduct: idProduct,
    emergencyStock: emergencyStock,
    idWareHouse: idWareHouse,
    stock: stock,
  };
  const response = await editProductStock(obj, productLocalId);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
