import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface DeleteSubBrand {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const deleteSubBrand = async (
  idSubBrand: number
): Promise<DeleteSubBrand> => {
  const url = `${baseUrl}/ProductSubBrands/DeleteSubBrand?SubBrandId=${idSubBrand}`;
  try {
    const subBrands = await axios.delete(url, headerAuthorization);
    return {
      isSuccess: true,
      status: subBrands.status,
      data: subBrands.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error: error?.response?.data?.message || "Error al eliminar submarca.",
    };
  }
};
