import { getInventory_service } from "@Services/inventory/getInventory";

export interface IInventoryProduct {
  id: number;
  productId: number;
  stock: number;
  counter: number;
  differenceMessage: string;
}

export const getInventory = async (
  inventoryId: number,
  setStateResponse,
  setErrorView
) => {
  const response = await getInventory_service(inventoryId);

  const inventory: IInventoryProduct[] = response.data.map((product: any) => {
    return {
      id: product.inventoryDetailId,
      productId: product.localProductId,
      stock: product.beforeStock,
      counter: product.quantity,
      differenceMessage: product.comment,
      comment: product.comment,
      name: product.productName,
      productBrandName: product.productBrandName,
      productSubBrandName: product.productSubBrandName,
      productCode: product.productCode,
    };
  });
  if (response.isSuccess) {
    return {
      inventory: inventory,
      isSuccess: true,
      status: response.status,
      error: "",
    };
  } else {
    setErrorView((prevState) => ({
      ...prevState,
      errorInventory: true,
    }));
    setStateResponse({
      status: response.status,
      message: response.error,
    });
    return {
      inventory: [],
      isSuccess: false,
      status: response.status,
      error: response.error,
    };
  }
};
