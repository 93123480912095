import { bulkUploadViaInvoice_service } from "@Services/products/products/bulkUploadViaInvoice.service";

export const bulkUploadViaInvoice_adapter = async (
  obj,
  idWarehouse,
  warehouseName,
  setStateResponse,
  setIsSubmittingFile,
  products,
  idSupplier
) => {
  // const adaptRequest = {
  //   ...obj,
  //   isS
  // }convertDate
  const response = await bulkUploadViaInvoice_service(
    obj,
    idWarehouse,
    idSupplier
  );

  if (!response.isSuccess) {
    setIsSubmittingFile(false);
    setStateResponse({
      message: response.message,
      status: response.status,
    });
  } else {
    setStateResponse({
      message: "Carga masiva exitosa",
      status: 200,
    });

    const adaptedProducts = products?.map((product) => {
      return {
        idProduct: product?.id,
        idLocalProduct: product?.id,
        productName: product?.name,
        wareHouse: {
          isNew: false,
          name: warehouseName,
        },
        brand: {
          isNew: false,
          name: "",
        },
        subBrand: {
          isNew: false,
          name: "",
        },
        categories: [],
        unitPrice: product?.price,
        stock: product?.quantity,
        alertStock: product?.emergencyStock,
      };
    });

    localStorage.setItem("masiveProducts", JSON.stringify(adaptedProducts));
    window.location.replace("/almacen/productos/crear-masivo/resumen");
  }
};

// {
//   "code": "1001232a",
//   "name": "Shampoo",
//   "description": "Shampoo",
//   "price": 118,
//   "quantity": 60,
//   "total": 6000,
//   "nameInvoice": "Shampoo",
//   "existInWarehouse": true,
//   "isFree": null,
//   "id": 256157,
//   "brand": {
//       "id": 2254,
//       "name": "DAADADADASD"
//   },
//   "category": [
//       {
//           "id": 2089,
//           "name": "DAD12K3123"
//       }
//   ],
//   "emergencyStock": 2
// }
