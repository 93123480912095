import { CommonArrowRightIcon } from "@Models/icons/icons";
// @ts-ignore
import { Icon, validateModuleAccess } from "@viuti/recursos";
import styles from "../InventoryHistory.module.css";
import { UPSERT_INVENTORIES_UUID } from "@Models/const/securityAccess";

export const dataMobileInventoryHistory = ({
  inventory,
  screenWidth,
  index,
  navigate,
}) => {
  // const style
  const style =
    inventory.status === "Completado"
      ? {
          color: "#1d9b75",
          fontWeight: "700",
          padding: "6px 10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }
      : inventory.status === "Subsanar"
      ? {
          color: "#FBB",
          fontWeight: "700",
          padding: "6px 10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        }
      : {
          color: "#937cf49c",
          fontWeight: "700",
          padding: "6px 10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
        };
  const dateEnd =
    inventory["Fecha finalizado"] === "-" ? "" : inventory["Fecha finalizado"];

  let headerTitles = [];
  if (screenWidth <= 900 && screenWidth > 800) {
    headerTitles = [
      inventory.name,
      <p
        style={{
          ...style,
        }}
      >
        {inventory.status}
      </p>,
      inventory["Fecha de inicio"],
      dateEnd,
    ];
  }
  if (screenWidth <= 800) {
    headerTitles = [
      inventory.name,
      <p style={{ ...style }}>{inventory.status}</p>,
    ];
  }

  return {
    id: index,
    header: headerTitles,
    body: [
      {
        item1: "Inventario",
        item2: inventory.name,
      },
      {
        item1: "Estado",
        item2: inventory.status,
      },
      {
        item1: "Fecha de inicio",
        item2: inventory["Fecha de inicio"],
      },
      {
        item1: "Fecha finalizado",
        item2: inventory["Fecha finalizado"],
      },
      {
        item1: "Opciones",
        item2: (
          <button
            className={`${styles.selectBubbleContainer}`}
            onClick={() => {
              if (inventory.status !== "Completado") {
                validateModuleAccess(UPSERT_INVENTORIES_UUID) &&
                  navigate(
                    `/inventario/${inventory.idWarehouse}/${inventory.id}`
                  );
              } else
                navigate(
                  `/inventario/resumen/${inventory.idWarehouse}/${inventory.id}`
                );
            }}
            title="Ver inventario"
          >
            <Icon
              path={CommonArrowRightIcon}
              size={16}
              alt={"Ver Inventario"}
              color={"#45348e"}
            />
          </button>
        ),
      },
    ],
  };
};
