export const truncateString = (
  str: string | undefined | null,
  number: number
): string => {
  if (!str) {
    return "";
  }
  if (str.length > number) {
    return str.slice(0, number) + "...";
  } else {
    return str;
  }
};
