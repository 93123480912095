import React, { useEffect } from "react";
import styles from "./ProductTable.module.css";
// @ts-ignore
import { TextInput, ButtonWithoutIcon } from "@viuti/recursos";
import useScreenWidth from "../hooks/useScreenWidth/useScreenWidth";
import { formatToSoles } from "@Utilities/formatToSoles";
import { Product } from "@Models/interfaces/BulkUploadViaInvoice.interface";
interface Props {
  products: Product[];
  onQuantityChange: (code: string, newQuantity: number) => void;
  onNewProduct: (boolean: boolean) => void;
  setProductInformation: (product: any) => void;
  selectedWarehouse: Warehouse;
  createdProducts: CreatedProduct[];
}
interface CreatedProduct {
  warehouseId: number;
  name: string;
  code: string;
  brand: number;
  subBrand: string;
  categoriesIds: number[];
  price: number;
  freeProduct: number;
  stock: number;
  emergencyStock: number;
}
interface Warehouse {
  warehouseId: number;
  warehouseName: string;
}

const ProductTable: React.FC<Props> = ({
  products,
  onQuantityChange,
  onNewProduct,
  setProductInformation,
  selectedWarehouse,
  createdProducts,
}) => {
  const codigosDeProductosCreados = createdProducts.map(
    (product: CreatedProduct) => product.code
  );
  const [codProdCreate, setCodProdCreate] = React.useState<string[]>(
    codigosDeProductosCreados
  );
  const screenWidth = useScreenWidth();

  useEffect(() => {
    setCodProdCreate(codigosDeProductosCreados);
  }, [codigosDeProductosCreados]);

  return (
    <table className={styles.productTable}>
      <thead>
        <tr>
          <th>Código</th>
          <th>Producto</th>
          <th>Cantidad</th>
          <th>Precio Unitario</th>
          <th>Total</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {products.map((product, index) => (
          <tr
            key={index}
            className={
              codProdCreate.includes(product.code) ? styles.createdProduct : ""
            }
          >
            <td>
              {screenWidth < 900 && <span>Código</span>}
              {product.code}
            </td>
            <td>
              {screenWidth < 900 && <span>Producto</span>}
              {product?.name}({product.nameInvoice})
              {codProdCreate.includes(product.code) && (
                <span className={styles.newProductLabel}>Nuevo</span>
              )}
              {!product.existInWarehouse &&
                !codProdCreate.includes(product.code) && (
                  <span className={styles.productNotRegistered}>
                    No registrado
                  </span>
                )}
            </td>
            <td className={styles.quantityInput}>
              {screenWidth < 900 && <span>Cantidad</span>}
              <TextInput
                type="number"
                value={product.quantity}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const newQuantity = parseInt(e.target.value, 10);
                  if (!isNaN(newQuantity)) {
                    onQuantityChange(product.code, newQuantity);
                  }
                }}
                width="70px"
                className={styles.TextInput}
              />
            </td>
            <td>
              {/* <Tooltip
                activated={
                  calculatePriceVariation(
                    product.price,
                    product.lastPrice,
                    product.existInWarehouse
                  ) !== ""
                }
                message={calculatePriceVariation(
                  product.price,
                  product.lastPrice,
                  product.existInWarehouse
                )}
                distance={{ bottom: -60, left: 25 }}
              > */}
              {/* <span
                  className={
                    calculatePriceVariation(
                      product.price,
                      product.lastPrice,
                      product.existInWarehouse
                    ) !== ""
                      ? styles.priceVariation
                      : ""
                  }
                > */}
              {screenWidth < 900 && <span>Precio Unitario</span>}
              {formatToSoles(product.price)}
              {/* </span>
              </Tooltip> */}
            </td>
            <td>
              {screenWidth < 900 && <span>Total</span>}
              {formatToSoles(product.total)}
            </td>
            <td>
              {!product.existInWarehouse &&
                !codProdCreate.includes(product.code) && (
                  <ButtonWithoutIcon
                    textBttn={"Registrar"}
                    isPrimary={false}
                    handleClick={() => {
                      setProductInformation({
                        stock: 0,
                        productName: product.nameInvoice,
                        code: product.code,
                        price: "",
                        brandImageUrl: "",
                        productId: 0,
                        warehouse: {
                          warehouseId: selectedWarehouse.warehouseId,
                          warehouseName: selectedWarehouse.warehouseName,
                        },
                        brand: {
                          brandId: 0,
                          brandName: "",
                        },
                        line: {
                          lineId: 0,
                          lineName: "",
                        },
                        category: [],
                        description: product.description,
                      });
                      onNewProduct(true);
                    }}
                  />
                )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductTable;
