import React, { useEffect } from "react";
import style from "./StockMovements.module.css";
import {
  TableWrapper,
  PaginationWrapper,
  DateInput,
  ButtonWithoutIcon,
  SelectInput,
  TooltipForStrings,
  TableMobile,
  SlideNotification,
  CriticalErrorPage,
} from "../../../../resources";
import {
  IconWithOptions,
  Icon,
  ModalAcceptCancel,
  trackUserAction,
  // @ts-ignore
} from "@viuti/recursos";
import { useParams } from "react-router";
import useProductInfo from "@Hooks/useProduct";
import {
  getStockMovementsAdapter,
  stockMovementsAdapt,
} from "@Adapters/productsAdapters/products/stockMovements.adapter";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { createObjectMobile } from "./StockMovementsMobile/StockMovementsMobile";
import { getWarehousesAdapter } from "@Adapters/warehousesAdapters/getWarehouses.adapter";
import {
  HiOutlineArrowSmallDown,
  HiOutlineArrowSmallUp,
} from "react-icons/hi2";
import { TripleDotsIcon } from "@Models/icons/icons";

const StockMovements: React.FC = () => {
  let { id } = useParams();
  const [errorView, setErrorView] = React.useState({
    errorProduct: false,
    errorWarehouse: false,
    errorStockMovements: false,
  });
  const { product } = useProductInfo(id, setErrorView);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [stockMovementsList, setStockMovementsList] = React.useState([]);
  const [form, setForm] = React.useState({
    initialDate: "",
    finalDate: "",
    warehouseSelected: "Todas",
  });
  const [warehousesList, setWarehousesList] = React.useState([]);
  const [maxPage, setMaxPage] = React.useState(1);
  const [isLoadingButton, setIsLoadingButton] = React.useState(false);
  const [isLoadingTable, setIsLoadingTable] = React.useState(true);
  const [stateResponse, setStateResponse] = React.useState({
    message: "",
    status: 0,
  });
  const [error, setError] = React.useState({
    endDate: "",
  });
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [firstRender, setFirstRender] = React.useState(true);
  const screenWidth = useScreenWidth();
  useEffect(() => {
    const historial_movimientos_producto = 33;
    trackUserAction(historial_movimientos_producto);
    const getFirstDayOfMonth = () => {
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      return firstDayOfMonth.toISOString().split("T")[0]; // Formato YYYY-MM-DD
    };
    setForm({
      ...form,
      initialDate: getFirstDayOfMonth(),
      finalDate: new Date().toISOString().split("T")[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchWarehouses = async () => {
      const response = await getWarehousesAdapter({ setStateResponse });
      if (response.isSuccess) {
        setWarehousesList([
          {
            warehouseId: "Todas",
            warehouseName: "Todos los almacenes",
            sale: 0,
          },
          ...response.warehouses,
        ]);
      }
    };
    fetchWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cargar = async () => {
    if (form.initialDate > form.finalDate) {
      setError({ ...error, endDate: `Debe ser posterior a fecha inicio.` });
      return;
    }
    setError({ ...error, endDate: "" });
    setIsLoadingTable(true);
    setIsLoadingButton(true);
    if (currentPage !== 1) {
      setCurrentPage(1);
      setFirstRender(true);
    }
    const response: any = await getStockMovementsAdapter(
      product?.productId,
      form.warehouseSelected,
      form.initialDate,
      form.finalDate,
      1,
      setStateResponse,
      setErrorView
    );
    if (response.isSuccess) {
      setStockMovementsList(stockMovementsAdapt(response));
      setMaxPage(response.stockMovements.maxPage);
    }
    setIsLoadingButton(false);
    setIsLoadingTable(false);
  };

  useEffect(() => {
    (async () => {
      if (firstRender) {
        setFirstRender(false);
        return;
      }
      setIsLoadingTable(true);
      const response: any = await getStockMovementsAdapter(
        product?.productId,
        form.warehouseSelected,
        form.initialDate,
        form.finalDate,
        currentPage,
        setStateResponse,
        setErrorView
      );
      if (response.isSuccess) {
        setStockMovementsList(stockMovementsAdapt(response));
        setMaxPage(response.stockMovements.maxPage);
      }
      setIsLoadingTable(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // primer render
  useEffect(() => {
    if (product?.productId !== undefined) {
      const fetchStockMovements = async () => {
        setIsLoadingTable(true);
        const response: any = await getStockMovementsAdapter(
          product?.productId,
          form.warehouseSelected,
          form.initialDate,
          form.finalDate,
          1,
          setStateResponse,
          setErrorView
        );
        if (response.isSuccess) {
          setStockMovementsList(stockMovementsAdapt(response));
          setMaxPage(response.stockMovements.maxPage);
        }
        setIsLoadingTable(false);
      };
      fetchStockMovements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  const adaptSelectWarehouse = warehousesList.map((warehouse) => {
    return {
      name: warehouse.warehouseName,
      value: warehouse.warehouseId,
    };
  });
  // cuando doy enter quiero que se ejecute la funcion cargar
  useEffect(() => {
    const handleEnter = (e) => {
      if (e.key === "Enter") {
        cargar();
      }
    };
    window.addEventListener("keydown", handleEnter);
    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, currentPage]);

  const titles = (screenWidth) => {
    const titles = [
      "Movimiento",
      "Cantidad",
      "Almacén",
      // "Destino",
      "Stock actualizado",
      "Observaciones",
      "Responsable",
      "Fecha y hora",
    ];

    if (screenWidth < 1380 && screenWidth >= 1200) {
      titles.splice(4, 1);
      titles.push("");
    }
    if (screenWidth < 1200) {
      titles.splice(5, 1);
      titles.splice(4, 1);
      titles.push("");
    }
    return titles;
  };
  const getTitles = (screenWidth) => {
    if (screenWidth >= 1200) {
      return ["Observaciones"];
    }
    return ["Observaciones", "Responsable"];
  };
  const getData = (selectedRow, screenWidth) => {
    // Crear objeto de datos de forma condicional según el ancho de la pantalla
    if (screenWidth >= 1200) {
      return [
        {
          Observaciones: selectedRow.observation,
        },
      ];
    }
    return [
      {
        Observaciones: selectedRow.observation,
        Responsable: selectedRow.employeeName,
      },
    ];
  };

  const createObject = (stockMovement, screenWidth) => {
    const SALIDA = "Egreso";
    const styleRed = {
      color: "#8c1044",
      fontWeight: "700",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "2px",
    };
    const styleGreen = {
      color: "#1d9b75",
      fontWeight: "700",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "2px",
    };
    const optionsSalesDocuments = (
      <IconWithOptions
        containerStyles={{
          borderRadius: "6px",
        }}
        icon={
          <span className={style.icon_options}>
            <Icon path={TripleDotsIcon} size={16} alt={"Tres puntos"} />
          </span>
        }
        actions={[
          {
            label: "Ver más detalles",
            handler: () => {
              setSelectedRow(stockMovement);
              setOpenModal(true);
            },
          },
        ]}
      />
    );
    if (screenWidth >= 1380) {
      return {
        typeOperationName: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.typeOperationName === SALIDA ? (
              <HiOutlineArrowSmallDown />
            ) : (
              <HiOutlineArrowSmallUp />
            )}
            {stockMovement.typeOperationName}
          </span>
        ),
        operationQuantity: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.operationQuantity}
          </span>
        ),
        originWarehouseName: TooltipForStrings(
          stockMovement.originWarehouseName,
          15
        ),
        // destinationWarehouseName: TooltipForStrings(
        //   stockMovement.destinationWarehouseName,
        //   15
        // ),
        stock: stockMovement.stock,
        observation: stockMovement.observation,
        employeeName: stockMovement.employeeName,
        movementDate: stockMovement.movementDate,
      };
    }
    if (screenWidth < 1380 && screenWidth >= 1200) {
      return {
        typeOperationName: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.typeOperationName === SALIDA ? (
              <HiOutlineArrowSmallDown />
            ) : (
              <HiOutlineArrowSmallUp />
            )}
            {stockMovement.typeOperationName}
          </span>
        ),
        operationQuantity: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.operationQuantity}
          </span>
        ),
        originWarehouseName: TooltipForStrings(
          stockMovement.originWarehouseName,
          15
        ),
        // destinationWarehouseName: TooltipForStrings(
        //   stockMovement.destinationWarehouseName,
        //   15
        // ),
        stock: stockMovement.stock,
        employeeName: stockMovement.employeeName,
        movementDate: stockMovement.movementDate,
        options: optionsSalesDocuments,
      };
    }
    if (screenWidth < 1200) {
      return {
        typeOperationName: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.typeOperationName === SALIDA ? (
              <HiOutlineArrowSmallDown />
            ) : (
              <HiOutlineArrowSmallUp />
            )}
            {stockMovement.typeOperationName}
          </span>
        ),
        operationQuantity: (
          <span
            style={
              stockMovement.typeOperationName === SALIDA ? styleRed : styleGreen
            }
          >
            {stockMovement.operationQuantity}
          </span>
        ),
        originWarehouseName: TooltipForStrings(
          stockMovement.originWarehouseName,
          15
        ),
        // destinationWarehouseName: TooltipForStrings(
        //   stockMovement.destinationWarehouseName,
        //   15
        // ),
        stock: stockMovement.stock,
        movementDate: stockMovement.movementDate,
        options: optionsSalesDocuments,
      };
    }
  };

  if (
    errorView.errorProduct ||
    errorView.errorWarehouse ||
    errorView.errorStockMovements
  ) {
    return (
      <div id="viuti-front-mainContent">
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }

  return (
    <section className={style.containerRecord}>
      <div className={style.headerFilter}>
        <SelectInput
          label="Almacén"
          value={form.warehouseSelected}
          items={adaptSelectWarehouse}
          handleChange={(e) => {
            setForm({ ...form, warehouseSelected: e.target.value });
          }}
        />
        <div className={style.containerDate}>
          <DateInput
            label="Fecha de inicio"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: form.initialDate,
              onChange: (e) =>
                setForm({ ...form, initialDate: e.target.value }),
            }}
          />
        </div>
        <div className={style.containerDate}>
          <DateInput
            label="Fecha de fin"
            inputProps={{
              placeholder: "Seleccionar fecha",
              value: form.finalDate,
              onChange: (e) => setForm({ ...form, finalDate: e.target.value }),
            }}
            touched={true}
            error={error.endDate}
          />
        </div>
        <ButtonWithoutIcon
          textBttn={"Cargar"}
          handleClick={cargar}
          isLoading={isLoadingButton}
          isPrimary={false}
          isDisabled={false}
        />
      </div>
      {screenWidth >= 900 ? (
        <TableWrapper
          titles={titles(screenWidth)}
          data={stockMovementsList.map((stockMovement) => ({
            ...createObject(stockMovement, screenWidth),
          }))}
          isLoading={isLoadingTable}
          TableWrapperStyle={{ fontSize: "13px" }}
          containerStyle={{ width: "100%" }}
          containerTableStyle={{ width: "100%" }}
        />
      ) : (
        <TableMobile
          isLoading={isLoadingTable}
          dataTable={stockMovementsList.map((stockMovement, index) => ({
            ...createObjectMobile({
              stockMovement,
              screenWidth,
              index,
            }),
          }))}
        />
      )}
      {maxPage > 1 && (
        <PaginationWrapper
          currentPagination={currentPage}
          setCurrentPagination={setCurrentPage}
          optionsPerPage={10}
          numberOfButtons={maxPage}
        />
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
      {openModal && (
        <ModalAcceptCancel
          title="Más detalles"
          showButtons={{
            showButtonClose: true,
            showButtonOne: false,
            showButtonTwo: false,
          }}
          actionButtonClose={() => {
            setSelectedRow(null);
            setOpenModal(false);
          }}
        >
          <TableWrapper
            titles={getTitles(screenWidth)}
            data={getData(selectedRow, screenWidth)}
            TableWrapperStyle={{ fontSize: "13px" }}
            containerStyle={{ width: "100%" }}
            cellStyle={{ padding: "10px" }}
            numberOfRows={1}
            containerTableStyle={{
              minHeight: "0px",
            }}
          />
        </ModalAcceptCancel>
      )}
    </section>
  );
};

export default StockMovements;
