// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QLHGC2Mv_BErLt9xIIcy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;
}

.VRvrm0yzG24x5XdAI3z7 {
  color: #000;
  font: 800 1.125rem/1.375rem "Mulish", sans-serif;
  text-transform: uppercase;

  width: 7.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 56.25rem) {
  .VRvrm0yzG24x5XdAI3z7 {
    display: none;
  }
}
.LLpHpwnRCVCSsU8VbAFI {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.125rem;
}

.Q0FazUQMTZxjjhCFugGT {
  color: #392b75;
  font: 600 1rem/1.25rem "Mulish", sans-serif;
}

.taoVlH19Jl3m8GuMKmmN {
  color: #45348e;
  font: 400 0.875rem/1rem "Mulish", sans-serif;
}

.BxnB9OJlqsvHzJWlgi72 {
  color: #937cf4;
  font: 400 0.875rem/1rem "Mulish", sans-serif;
}
.BxnB9OJlqsvHzJWlgi72:hover {
  cursor: pointer;
  text-decoration: underline;
}
.BxnB9OJlqsvHzJWlgi72:active {
  transform: scale(0.95);
  transition: transform 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Inventory/builder/styles/tableProductBuild.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gDAAgD;EAChD,yBAAyB;;EAEzB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE;IACE,aAAa;EACf;AACF;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE,cAAc;EACd,2CAA2C;AAC7C;;AAEA;EACE,cAAc;EACd,4CAA4C;AAC9C;;AAEA;EACE,cAAc;EACd,4CAA4C;AAC9C;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,sBAAsB;EACtB,0BAA0B;AAC5B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 1.25rem;\n}\n\n.brand {\n  color: #000;\n  font: 800 1.125rem/1.375rem \"Mulish\", sans-serif;\n  text-transform: uppercase;\n\n  width: 7.5rem;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n@media (max-width: 56.25rem) {\n  .brand {\n    display: none;\n  }\n}\n.content {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  gap: 0.125rem;\n}\n\n.product__name {\n  color: #392b75;\n  font: 600 1rem/1.25rem \"Mulish\", sans-serif;\n}\n\n.product__brand {\n  color: #45348e;\n  font: 400 0.875rem/1rem \"Mulish\", sans-serif;\n}\n\n.product__code {\n  color: #937cf4;\n  font: 400 0.875rem/1rem \"Mulish\", sans-serif;\n}\n.product__code:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n.product__code:active {\n  transform: scale(0.95);\n  transition: transform 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QLHGC2Mv_BErLt9xIIcy`,
	"brand": `VRvrm0yzG24x5XdAI3z7`,
	"content": `LLpHpwnRCVCSsU8VbAFI`,
	"product__name": `Q0FazUQMTZxjjhCFugGT`,
	"product__brand": `taoVlH19Jl3m8GuMKmmN`,
	"product__code": `BxnB9OJlqsvHzJWlgi72`
};
export default ___CSS_LOADER_EXPORT___;
