import { postValidProductsListInWarehouse_service } from "@Services/warehouses/postValidProductsListInWarehouse.service";

export const postValidProductsListInWarehouse_adapter = async (
  products: string[]
) => {
  const response = await postValidProductsListInWarehouse_service(products);

  if (!response.isSuccess) {
    return {
      isSuccess: response.isSuccess,
      status: response.status,
      data: response.data,
      message: response.message,
    };
  }

  const adaptResponse = response.data.map((warehouse) => {
    return {
      name: warehouse.productName,
      existInWarehouse: warehouse.productExistInWarehouse,
      id: warehouse.productId,
      code: warehouse.productCode,
      price: warehouse.productPrice,
      isFree: warehouse.isProductFree,
      brand: {
        id: warehouse?.productBrand?.brandId,
        name: warehouse?.productBrand?.brandName,
      },
      category: warehouse?.productCategory?.map((category) => {
        return {
          id: category?.categoryId,
          name: category?.categoryName,
        };
      }),
      emergencyStock: warehouse?.emergencyStock,
    };
  });
  return {
    isSuccess: response.isSuccess,
    status: response.status,
    data: adaptResponse,
    message: response.message,
  };
};

// {
//   "productName": "Shampoo",
//   "productExistInWarehouse": true,
//   "productId": 256157,
//   "productCode": "1001232a",
//   "productAveragePrice": null,
//   "isProductFree": null,
//   "productBrand": {
//       "brandId": 2254,
//       "brandName": "DAADADADASD"
//   },
//   "productCategory": [
//       {
//           "categoryId": 2089,
//           "categoryName": "DAD12K3123"
//       }
//   ],
//   "stock": 0,
//   "emergencyStock": 2
// },
