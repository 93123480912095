import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface GetBrands {
  isSuccess: boolean;
  status?: number;
  data: any;
  error: string | null;
}

export const getBrands = async (): Promise<GetBrands> => {
  const url = `${baseUrl}/ProductBrands/BrandsProducts`;
  try {
    const response = await axios.get(url, headerAuthorization);

    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 500,
      data: [],
      error: error?.response?.data?.message || "Error al obtener las marcas",
    };
  }
};
