import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const getHistorialInventoryService = async (id?: number | string) => {
  let url = `${baseUrl}/Inventory/GetInventory`;
  if (id) url = `${url}?wareHouseId=${id}`;

  try {
    const historialInventory = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: historialInventory.status,
      data: historialInventory.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Hubo un error al obtener el historial de inventario. Por favor, intente de nuevo.",
    };
  }
};
