import { getWarehouses } from "@Services/warehouses/getWarehouseCopy.service";

interface Warehouse {
  warehouseId: number;
  warehouseName: string;
  sales: number;
}
export const getWarehousesAdapter = async () => {
  const response = await getWarehouses();
  if (!response.isSuccess) {
    return {
      isSuccess: response.isSuccess,
      status: response.status,
      message: response.message,
      data: [],
    };
  }

  const adaptedData = response.data.map((warehouse: Warehouse) => {
    return {
      value: warehouse.warehouseId,
      name: warehouse.warehouseName,
    };
  });

  return {
    isSuccess: response.isSuccess,
    status: response.status,
    message: response.message,
    data: adaptedData,
  };
};
