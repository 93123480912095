import React, { useEffect, useState } from "react";
import styles from "./BulkUploadViaInvoice.module.css";
import FileUpload from "./FileUpload/FileUpload";
import InvoicePreview from "./InvoicePreview/InvoicePreview";
import WarehouseSelect from "./WarehouseSelect/WarehouseSelect";
// @ts-ignore
import { CriticalErrorPage } from "@viuti/recursos";
import { postValidProductsListInWarehouse_adapter } from "@Adapters/warehousesAdapters/postValidateProductsListInWarehouse.adapter";
import { getValidateSupplierByDocument_adapter } from "@Adapters/Supplier/getValidateSupplierByDocument.adapter";
import { LoadingPulseIcon } from "@Models/icons/icons";
import { Product } from "@Models/interfaces/BulkUploadViaInvoice.interface";

const BulkUploadViaInvoice = ({
  isSummaryVisible,
  setIsSummaryVisible,
  setShowModalCreateProduct,
  setProductInformation,
  createdProducts,
  products,
  setProducts,
  invoiceData,
  setInvoiceData,
  selectedWarehouse,
  setSelectedWarehouse,
  setStateResponse,
  infoCreatedProducts,
  setIsLoadingInvoice,
  isLoadingInvoice,
}) => {
  const [error, setError] = useState({
    warehouseError: "",
  });

  const [isNewSupplier, setIsNewSupplier] = useState(false);
  const [criticalError, setCriticalError] = useState(false);

  useEffect(() => {
    if (selectedWarehouse.warehouseId) {
      setError((prev) => ({ ...prev, warehouseError: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWarehouse]);

  useEffect(() => {
    (async () => {
      if (!invoiceData.supplierRuc) return;
      const response = await getValidateSupplierByDocument_adapter(
        1,
        invoiceData.supplierRuc
      );
      if (!response.isSuccess) return setCriticalError(true);
      setIsNewSupplier(!response.data.supplierExist);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData]);

  const handleFileUpload = async (
    uploadedProducts: Product[],
    billData: any
  ) => {
    setIsLoadingInvoice(true);
    const response = await postValidProductsListInWarehouse_adapter(
      uploadedProducts.map((product) => product.code)
    );
    setIsLoadingInvoice(false);

    if (response.isSuccess) {
      const updatedProducts = uploadedProducts.map((product) => {
        const dbProduct = response.data.find(
          (dbP) => dbP.code === product.code
        );
        if (dbProduct) {
          return {
            ...product,
            name: dbProduct.name,
            nameInvoice: product.name,
            productAveragePrice: dbProduct.productAveragePrice,
            price: product.price,
            existInWarehouse: dbProduct.existInWarehouse,
            isFree: dbProduct.isFree,
            id: dbProduct.id,
            brand: dbProduct.brand,
            category: dbProduct.category,
            emergencyStock: dbProduct.emergencyStock,
          };
        }
        return product;
      });

      setProducts(updatedProducts);
    } else {
      setCriticalError(true);
      setStateResponse({
        message:
          "Hubo un error al verificar los productos en el almacén, por favor intente nuevamente.",
        status: 400,
      });
    }

    setIsSummaryVisible(true);
    setInvoiceData({
      supplierName: billData.billSupplierDetail.supplierName,
      supplierAddress: billData.billSupplierDetail.supplierAddress,
      supplierRuc: billData.billSupplierDetail.supplierId,
      customerName: billData.billSupplierDetail.customerName,
      customerAddress: billData.billSupplierDetail.customerAddress,
      customerRuc: billData.billSupplierDetail.customerRuc,
      issueDate: billData.billSupplierDetail.issueDate,
      dueDate: billData.billSupplierDetail.dueDate,
      billCode: billData.billSupplierDetail.billCode,
      billSeries: billData.billSupplierDetail.billSeries,
      billNumber: billData.billSupplierDetail.billNumber,
      billTotalAmount: billData.billSupplierDetail.billTotalAmount,
    });
  };

  useEffect(() => {
    if (infoCreatedProducts) {
      setProducts((prevProducts) =>
        prevProducts.map((product) => {
          const createdProduct = infoCreatedProducts.find(
            (p) => p.code === product.code
          );
          if (createdProduct) {
            return {
              ...product,
              name: createdProduct.name,
              id: createdProduct.localProductId,
              existInWarehouse: true,
              isNew: true,
              brand: {
                brandId: createdProduct?.brandId,
                brandName: createdProduct?.brandName,
              },
              category: [
                {
                  categoryId: createdProduct?.categoryId,
                  categoryName: createdProduct?.categoryName,
                },
              ],
              emergencyStock: createdProduct?.emergencyStock,
            };
          }
          return product;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoCreatedProducts]);

  useEffect(() => {
    const updatedProducts = products.map((product) => {
      const createdProduct = createdProducts.find(
        (p) => p.code === product.code
      );
      if (createdProduct) {
        return {
          ...product,
          name: createdProduct.name,
          id: createdProduct.localProductId,
          existInWarehouse: true,
          isNew: true,
          brand: {
            brandId: createdProduct?.brandId,
            brandName: createdProduct?.brandName,
          },
          category: [
            {
              categoryId: createdProduct?.categoryId,
              categoryName: createdProduct?.categoryName,
            },
          ],
          emergencyStock: createdProduct?.emergencyStock,
        };
      }
      return product;
    });
    setProducts(updatedProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdProducts]);

  const handleQuantityChange = (code: string, newQuantity: number) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.code === code
          ? {
              ...product,
              quantity: newQuantity,
              // total: newQuantity * product.price,
            }
          : product
      )
    );
  };

  const handleNewProduct = (boolean: boolean) => {
    setShowModalCreateProduct(boolean);
  };

  const propsTableProducts = {
    products,
    onQuantityChange: handleQuantityChange,
    onNewProduct: handleNewProduct,
    setProductInformation,
    selectedWarehouse,
    createdProducts,
  };

  if (criticalError) {
    return (
      <div className={styles.container}>
        <CriticalErrorPage />
      </div>
    );
  }
  if (isLoadingInvoice) {
    return (
      <div className={styles.container}>
        <figure className={styles.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <WarehouseSelect
        onSelectWarehouse={setSelectedWarehouse}
        selectedWarehouse={selectedWarehouse}
        error={error}
        isSummaryVisible={isSummaryVisible}
      />
      {!isSummaryVisible && (
        <FileUpload
          onFileUpload={handleFileUpload}
          selectedWarehouse={selectedWarehouse}
          setError={setError}
        />
      )}

      {isSummaryVisible && (
        <div className={styles.previewContainer}>
          <InvoicePreview
            propsTableProducts={propsTableProducts}
            invoiceData={invoiceData}
            isNewSupplier={isNewSupplier}
          />
        </div>
      )}
    </div>
  );
};

export default BulkUploadViaInvoice;
