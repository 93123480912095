import React from "react";
import style from "./AvailableWarehouses.module.css";
// @ts-ignore
import { getUserPermissions, Icon } from "@viuti/recursos";
import { Link } from "react-router-dom";
import { FindBoxIcon } from "@Models/icons/icons";

interface IAvailableWarehousesProps {
  warehouses: any;
  parameterName: string;
}

const AvailableWarehouses: React.FC<IAvailableWarehousesProps> = ({
  warehouses,
  parameterName,
}) => {
  const availableWarehouses = getUserPermissions().warehousesLimitCount;
  const totalWarehouses = warehouses.length;
  const usagePercentage = (totalWarehouses / availableWarehouses) * 100;

  const getProgressColor = (percentage: number) => {
    if (percentage < 50) return "#1AAD70";
    if (percentage < 75) return "#FFD700";
    if (percentage < 90) return "#FFA500";
    return "#F23D4E";
  };

  return (
    <article className={style.container}>
      <div className={style.usageContainer}>
        <span className={style.users_icon}>
          <Icon path={FindBoxIcon} size={16} color="#45348E" />
        </span>

        <p className={style.usageText}>
          <b>{totalWarehouses}</b> de <b>{availableWarehouses}</b>{" "}
          {parameterName} usados
        </p>
      </div>
      <div className={style.progressContainer}>
        <div
          className={style.progressBar}
          style={{
            width: `${usagePercentage}%`,
            backgroundColor: getProgressColor(usagePercentage),
          }}
        ></div>
      </div>

      <a href="/mi-cuenta/planes" target="_blank" className={style.upgradeLink}>
        Obtener más {parameterName}
      </a>
    </article>
  );
};

export default AvailableWarehouses;
