import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import {
  RequestBodyEditProductData,
  editProductData,
} from "@Services/products/products/editProductData.services";

export const editProductDataAdapter = async (
  obj: RequestBodyEditProductData
): Promise<ResponseToRequest> => {
  const response = await editProductData(obj);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
