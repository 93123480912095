import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
// @ts-ignore
import style from "./Inventory.module.css";
import {
  ButtonWithoutIcon,
  ConfirmModal,
  HeadBar,
  SearchWithColoredBorder,
  SlideNotification,
  CriticalErrorPage,
} from "../../resources";

import {
  IWarehouseInventoryStore,
  IProductInventory,
  useWarehouseInventoryStore,
} from "@Hooks/useWarehouseInventoryStore";
import { LoadingPulseIcon } from "@Models/icons/icons";
import { useWarehouseData } from "../../redux/useWarehouseData";
import InventoryTable from "@Components/InventoryTable/InventoryTable";
import { inventoryCountRowsBuilder } from "./builder/inventoryCountRowsBuilder";
import JustificationModal from "./components/JustificationModal/JustificationModal";
import {
  IInventoryHeaderStore,
  useInventoryHeaderStore,
} from "../../redux/useInventoryHeaderStore";
import { IToasterStore, useToasterStore } from "@ReduxService/useToasterStore";
import { IInventoryProduct } from "@Adapters/inventoryAdapters/getInventory.adapter";
import { saveInventory } from "@Adapters/inventoryAdapters/saveInventory.adapter";
import { finishInventory } from "@Adapters/inventoryAdapters/finishInventory.adapter";
import { getAllInventories } from "@Adapters/inventoryAdapters/getAllInventories.adapter";
import { deleteInventoryProduct } from "@Adapters/inventoryAdapters/deleteInventoryProduct.adapter";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import ModalUnsavedChanges from "@Components/ModalUnsavedChanges/ModalUnsavedChanges";

const Inventory = () => {
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [errorView, setErrorView] = useState({
    errorProduct: false,
    errorWarehouse: false,
    errorInventory: false,
  });

  const navigate = useNavigate();
  const { warehouseId, inventoryId } = useParams();
  const { warehouse, products, setProducts } =
    useWarehouseInventoryStore() as IWarehouseInventoryStore;
  let warehouseData = warehouse;
  const { setStatus, setMessage } = useToasterStore() as IToasterStore;
  const screenWidth = useScreenWidth();
  const {
    previousAction,
    setPreviousAction,
    warehouseProps,
    setWarehouseProps,
    showButton,
    showSecondaryButton,
    buttonProps,
    setButtonProps,
    secondaryButtonProps,
    setShowSecondaryButton,
    setSecondaryButtonProps,
    styleContainer,
  } = useInventoryHeaderStore() as IInventoryHeaderStore;
  const headerProps = {
    previousAction,
    warehouseProps,
    showButton,
    showSecondaryButton,
    buttonProps,
    secondaryButtonProps,
    styleContainer,
  };

  const [productsResponse, setProductsResponse] = useState<{
    products: IProductInventory[];
    isSuccess: boolean;
    status: number;
    error: string;
  }>({
    products: [],
    isSuccess: false,
    status: 0,
    error: "",
  });

  const [warehousesResponse, setWarehousesResponse] = useState({
    warehouses: [],
    isSuccess: false,
    status: 0,
    error: "",
  });

  const [inventoryResponse, setInventoryResponse] = useState<{
    inventory: IInventoryProduct[];
    isSuccess: boolean;
    status: number;
    error: string;
  }>({
    inventory: [],
    isSuccess: false,
    status: 0,
    error: "",
  });

  const [searchInput, setSearchInput] = useState("");

  const [activeProductDifference, setActiveProductDifference] = useState(null);

  const [isJustificationModalVisible, setIsJustificationModalVisible] =
    useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isUnsavedChangesModalVisible, setIsUnsavedChangesModalVisible] =
    useState(false);

  const [isCheckingInventory, setIsCheckingInventory] = useState(false);

  const [tableProps, setTableProps] = useState({
    columns: [
      "Producto",
      "1",
      "Unidades registradas",
      "Unidades contadas",
      "Diferencia",
      "2",
    ],
    rows: [],
    colgroup: `auto 200px 180px 180px 130px 80px`,
  });
  const [showResults, setShowResults] = useState(true);
  const handleUpdateProucts = (product: IProductInventory) => {
    // Check if the product already exists in the products array
    setShowResults(false);
    const productExists = products.some((p) => p.id === product.id);

    if (!productExists) {
      setProducts([
        ...products,
        { ...product, counter: String(product.stock), differenceMessage: "" },
      ]);
    } else {
      setActiveProductDifference(product);
      setIsDeleteModalVisible(true);
    }
  };

  useEffect(() => {
    setShowResults(true);
  }, [searchInput]);

  const handleRemoveProduct = async (product: IProductInventory) => {
    const ignoreService = !product.inventoryId;
    let response = null;

    if (!ignoreService) {
      response = await deleteInventoryProduct(
        Number(inventoryId),
        product.inventoryId
      );
    } else {
      response = {
        isSuccess: true,
        status: 200,
        data: null,
        message: "Detalle eliminado con exito.",
      };
    }

    if (response.isSuccess || ignoreService) {
      const updatedProducts = products.filter((p) => {
        return p.id !== product.id;
      });
      setProducts(updatedProducts);
    }

    setIsDeleteModalVisible(false);
    return response;
  };

  const handleCountChange = (productId: number, value: number) => {
    setProducts(
      products.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            counter: String(value),
            difference: value - product.stock,
            differenceMessage: "",
          };
        }
        return product;
      })
    );
  };

  const handleSaveChanges = async () => {
    const tempIsDisabled = buttonProps.isDisabled;
    setButtonProps({
      ...buttonProps,
      isDisabled: true,
      isLoading: true,
    });
    setSecondaryButtonProps({
      ...secondaryButtonProps,
      isLoading: true,
    });
    const response = await saveInventory(Number(inventoryId), products);
    setButtonProps({
      ...buttonProps,
      isDisabled: !tempIsDisabled,
      isLoading: false,
    });
    setSecondaryButtonProps({
      ...secondaryButtonProps,
      isLoading: false,
    });

    if (!response.isSuccess) {
      setStatus(response.status);
      setMessage(response.error);
    }

    return response;
  };

  // This effect is used to disable the next step button if there are no products in the warehouse or if there are differences in the count
  useEffect(() => {
    setButtonProps({
      ...buttonProps,
      isDisabled: false,
    });
    setSecondaryButtonProps({
      ...secondaryButtonProps,
      handleClick: () => handleSaveChanges(),
    });

    if (productsResponse.isSuccess) {
      if (productsResponse.products.length === 0 || products.length === 0) {
        setButtonProps({
          ...buttonProps,
          isDisabled: true,
        });
      } else {
        products.map((product) => {
          const count = product.counter || product.stock;
          const result = Number(count) - product.stock;
          const justification = product.differenceMessage || "";

          if (result !== 0 && justification === "") {
            setButtonProps({
              ...buttonProps,
              isDisabled: true,
            });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsResponse.products.length, products]);

  useEffect(() => {
    (async () => {
      const allInventoriesResponse = await getAllInventories(
        Number(warehouseId),
        setStateResponse,
        setErrorView
      );
      const currentInventory = allInventoriesResponse?.inventories.find(
        (inv: any) => {
          return inv.inventoryId === Number(inventoryId);
        }
      );

      if (
        currentInventory?.inventoryId &&
        currentInventory?.dateStart &&
        currentInventory?.dateEnd
      ) {
        navigate(`/inventario/resumen/${warehouseId}/${inventoryId}`);
      }
    })();

    setPreviousAction(() => setIsUnsavedChangesModalVisible(true));
    setWarehouseProps({
      ...warehouseProps,
      title: `${warehouse.warehouseName} - Inventario`,
    });
    setButtonProps({
      ...buttonProps,
      handleClick: () => setIsCheckingInventory(true),
    });
    setShowSecondaryButton(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const inventoryProductsFormatted: IProductInventory[] =
      productsResponse.products
        .map((product: IProductInventory) => {
          const inventoryProduct = inventoryResponse.inventory.find(
            (invProduct: IInventoryProduct) =>
              invProduct.productId === product.id
          );

          if (inventoryProduct) {
            return {
              ...product,
              inventoryId: inventoryProduct?.id,
              counter:
                String(inventoryProduct?.counter) || String(product.stock),
              difference:
                inventoryProduct?.counter - inventoryProduct?.stock || 0,
              differenceMessage: inventoryProduct?.differenceMessage || "",
            };
          } else return undefined;
        })
        .filter((product) => product !== undefined);
    setProducts(inventoryProductsFormatted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryResponse.inventory, productsResponse.products]);

  const handleFinishInventory = async () => {
    const saveResponse = await handleSaveChanges();
    if (!saveResponse.isSuccess) {
      setStatus(saveResponse.status);
      setMessage(saveResponse.error);
      return true;
    } else {
      const finishResponse = await finishInventory(
        Number(warehouseId),
        Number(inventoryId)
      );
      if (finishResponse.isSuccess) {
        setStatus(finishResponse.status);
        setMessage("Inventario finalizado");
        setTimeout(() => {
          navigate(`/inventario/resumen/${warehouseId}/${inventoryId}`);
        }, 2000);
      } else {
        setButtonProps({
          ...buttonProps,
          textBttn: "Finalizar inventario",
          handleClick: () => handleFinishInventory(),
        });
        setStatus(finishResponse.status);
        setMessage(finishResponse.error);
      }
    }
  };

  useEffect(() => {
    if (isCheckingInventory) {
      setButtonProps({
        ...buttonProps,
        textBttn: "Finalizar inventario",
        handleClick: handleFinishInventory,
      });
      setShowSecondaryButton(false);
      setTableProps({
        ...tableProps,
        columns: [
          "Producto",
          "Unidades registradas",
          "Unidades contadas",
          "Diferencia",
          "Stock",
        ],
        colgroup: `auto 180px 180px 130px 100px`,
      });
    } else {
      setButtonProps({
        ...buttonProps,
        textBttn: "Revisar",
        handleClick: () => setIsCheckingInventory(true),
      });
      setShowSecondaryButton(true);
      setTableProps({
        ...tableProps,
        columns: [
          "Producto",
          "1",
          "Unidades registradas",
          "Unidades contadas",
          "Diferencia",
          "2",
        ],
        colgroup: `auto 200px 180px 180px 130px 80px`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckingInventory]);

  // This is a custom hook that fetches the warehouse data and the products data
  useWarehouseData(
    warehouseData,
    setWarehousesResponse,
    setProductsResponse,
    setInventoryResponse,
    navigate,
    setWarehouseProps,
    setStateResponse,
    setErrorView
  );

  const onDifferenceClick = (product: any) => {
    setActiveProductDifference(product);
    setIsJustificationModalVisible(true);
  };

  if (
    warehousesResponse.status === 0 ||
    productsResponse.status === 0 ||
    inventoryResponse.status === 0
  ) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <figure className={style.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );
  }

  if (
    errorView.errorInventory ||
    errorView.errorProduct ||
    errorView.errorWarehouse
  ) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <CriticalErrorPage />;
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  } else {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <HeadBar {...headerProps} />
        <div className={style.body}>
          {isCheckingInventory ? (
            <span className={`${style.inventory__description}`}>
              <h1>Revisar inventario</h1>
              <p>
                Este es un resumen de inventario antes de finalizar. Si deseas
                contar más productos presiona en <b>Regresar</b>.
              </p>
              <ButtonWithoutIcon
                textBttn={"Regresar"}
                handleClick={() => setIsCheckingInventory(false)}
                isPrimary={false}
              />
            </span>
          ) : (
            <span className={`${style.inventory__description}`}>
              <h1>Añadir productos</h1>
              <p>
                Selecciona los productos que deseas añadir al inventario. Si no
                encuentras el producto que buscas, verifica que este se
                encuentre en el almacen seleccionado.
              </p>
            </span>
          )}
          <div className={style.search__container}>
            <SearchWithColoredBorder
              value={searchInput}
              placeholder={"Buscar producto por nombre o código"}
              setValue={setSearchInput}
              allData={productsResponse.products}
              onClickResult={(product) => handleUpdateProucts(product)}
              showResults={showResults}
              disabled={isCheckingInventory}
              searchBy={["name", "code"]}
            />
          </div>
          <section
            className={
              productsResponse.products.length === 0
                ? style.emptyWarehouse__container
                : style.inventoryTable__container
            }
          >
            {productsResponse.products.length === 0 ? (
              <p className={style.emptyWarehouse__message}>
                Actualmente no hay productos en este almacén.
                <span>
                  Por favor, elige otro almacén o añade productos para generar
                  el reporte.
                </span>
              </p>
            ) : (
              <InventoryTable
                {...tableProps}
                screenWidth={screenWidth}
                rows={inventoryCountRowsBuilder(
                  products,
                  handleUpdateProucts,
                  onDifferenceClick,
                  isCheckingInventory,
                  handleCountChange,
                  setStateResponse
                )}
              />
            )}
          </section>
        </div>
        <JustificationModal
          setStateResponse={setStateResponse}
          visibility={{
            isVisible: isJustificationModalVisible,
            setIsVisible: setIsJustificationModalVisible,
          }}
          onDifferenceChange={(value: string) => {
            setProducts(
              products.map((product) => {
                if (product.id === activeProductDifference.id) {
                  return {
                    ...product,
                    differenceMessage: value,
                  };
                }
                return product;
              })
            );
            setIsJustificationModalVisible(false);
            setTimeout(() => {
              setActiveProductDifference(null);
            }, 300);
          }}
          activeProductDifference={activeProductDifference}
        />
        <ConfirmModal
          title={"Remover producto"}
          message={
            <p className={style.modalMessage}>
              Estás seguro que deseas remover el producto{" "}
              <b>{activeProductDifference?.name}</b> del inventario? Se removerá
              el conteo y justificaciones ingresadas.
            </p>
          }
          buttonLbl={"Confirmar"}
          controller={{
            visible: isDeleteModalVisible,
            setVisible: setIsDeleteModalVisible,
          }}
          confirmAction={async () => {
            return handleRemoveProduct(activeProductDifference);
          }}
          successAction={(response) => {
            setStatus(response.status);
            setMessage(response.message);
          }}
        />
        <ModalUnsavedChanges
          changesModalVisible={isUnsavedChangesModalVisible}
          setChangesModalVisible={setIsUnsavedChangesModalVisible}
          navigate={navigate}
          screenWidth={screenWidth}
        />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }
};

export default Inventory;
