// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProductCard.module.css */
.VJ04adkyC8l9P9SG4g1n {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  min-width: 280px;
}

.mFj0diR2d8VSRsnqyqhD {
  font-size: 1.2em;
  margin-bottom: 8px;
}

.GHszNfmJ58EBNSInjg31 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 0.9em;
  color: #555;
}

.dLyN7xeaO9GCfMb9dy9G {
  color: green;
  font-weight: bold;
}

.m1bqINR3wb14Hc_9MPVj {
  color: red;
  font-size: 0.8em;
  margin-left: 4px;
}

.rgx_USt1xPyA99sEPwO5 {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.8em;
  cursor: pointer;
  margin-top: 8px;
  transition: background-color 150ms ease-in-out;

  &:hover {
    background-color: #ddd;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreateMasiveProductsOverview/components/ProductCard/ProductCard.module.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,8CAA8C;;EAE9C;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* ProductCard.module.css */\n.card {\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  padding: 16px;\n  min-width: 280px;\n}\n\n.title {\n  font-size: 1.2em;\n  margin-bottom: 8px;\n}\n\n.details {\n  display: flex;\n  flex-direction: column;\n  gap: 6px;\n  font-size: 0.9em;\n  color: #555;\n}\n\n.stock {\n  color: green;\n  font-weight: bold;\n}\n\n.new {\n  color: red;\n  font-size: 0.8em;\n  margin-left: 4px;\n}\n\n.button__edit {\n  background-color: #f0f0f0;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  padding: 8px 12px;\n  font-size: 0.8em;\n  cursor: pointer;\n  margin-top: 8px;\n  transition: background-color 150ms ease-in-out;\n\n  &:hover {\n    background-color: #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `VJ04adkyC8l9P9SG4g1n`,
	"title": `mFj0diR2d8VSRsnqyqhD`,
	"details": `GHszNfmJ58EBNSInjg31`,
	"stock": `dLyN7xeaO9GCfMb9dy9G`,
	"new": `m1bqINR3wb14Hc_9MPVj`,
	"button__edit": `rgx_USt1xPyA99sEPwO5`
};
export default ___CSS_LOADER_EXPORT___;
