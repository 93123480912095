// @ts-ignore
import style from "./ModalUnsavedChanges.module.css";
import { ModalAcceptCancel } from "../../resources/index";
import { WarningTriangleIcon } from "@Models/icons/icons";

const ModalUnsavedChanges = ({
  changesModalVisible,
  setChangesModalVisible,
  navigate,
  screenWidth,
}) => {
  const isMobile = screenWidth < 500;
  return (
    changesModalVisible && (
      <ModalAcceptCancel
        showButtons={{
          showButtonClose: isMobile,
          showButtonOne: true,
          showButtonTwo: !isMobile,
        }}
        buttonOne={{
          textButtonOne: "Descartar cambios",
          actionButtonOne: () => navigate(`/`),
        }}
        buttonTwo={{
          textButtonTwo: "Continuar editando",
          actionButtonTwo: () => setChangesModalVisible(false),
        }}
        actionButtonClose={() => setChangesModalVisible(false)}
      >
        <div className={style.body}>
          <h3 className={style.title}>Cambios sin guardar</h3>
          <img
            src={WarningTriangleIcon}
            alt="Advertencia"
            className={style.modalIcon}
          />
          <p className={style.modalDescription}>
            Si cierras la página, se perderán todos los cambios que hayas
            realizado.
          </p>
        </div>
      </ModalAcceptCancel>
    )
  );
};

export default ModalUnsavedChanges;
