// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AqVJLpYIJ1tJC8uOXEkw {
  width: 100%;
  border-bottom: 0.0625rem solid #dadada;
  display: flex;
  flex-direction: row;
}

.x30m83qlRssZdlrSjJ3S {
  display: inline-block;
  padding: 0.9375rem 1.875rem;
  border-bottom: 0.125rem solid transparent;
  font-weight: 500;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  font-family: "Mulish", sans-serif;
  color: #45348e;
}

.x30m83qlRssZdlrSjJ3S.fWOM8KrHmA87GbqV_EYx {
  border-bottom-color: #45348e;
  font-weight: 700;
}

.x30m83qlRssZdlrSjJ3S:hover:not(.fWOM8KrHmA87GbqV_EYx) {
  border-bottom-color: #9285c7;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductForm/components/NavigationBar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sCAAsC;EACtC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;EAC3B,yCAAyC;EACzC,gBAAgB;EAChB,eAAe;EACf,iCAAiC;EACjC,iCAAiC;EACjC,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".NavigationContainer {\n  width: 100%;\n  border-bottom: 0.0625rem solid #dadada;\n  display: flex;\n  flex-direction: row;\n}\n\n.NavigationButton {\n  display: inline-block;\n  padding: 0.9375rem 1.875rem;\n  border-bottom: 0.125rem solid transparent;\n  font-weight: 500;\n  cursor: pointer;\n  transition: all 150ms ease-in-out;\n  font-family: \"Mulish\", sans-serif;\n  color: #45348e;\n}\n\n.NavigationButton.selected {\n  border-bottom-color: #45348e;\n  font-weight: 700;\n}\n\n.NavigationButton:hover:not(.selected) {\n  border-bottom-color: #9285c7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavigationContainer": `AqVJLpYIJ1tJC8uOXEkw`,
	"NavigationButton": `x30m83qlRssZdlrSjJ3S`,
	"selected": `fWOM8KrHmA87GbqV_EYx`
};
export default ___CSS_LOADER_EXPORT___;
