export const ROUTE_STORE = {
  ROUTE_MAIN_VIEW: "/",
  ROUTE_CREATE_PRODUCT: "/productos/crear",
  ROUTE_MASIVE_CREATE_PRODUCT: "/productos/crear-masivo",
  ROUTE_MASIVE_CREATE_PRODUCT_RESUME: "/productos/crear-masivo/resumen",
  ROUTE_PRODUCT_DETAIL: "/detalles/:id?",
  ROUTE_ENTER_UNITARY: "/ingresar/:id?",
  ROUTE_TRANSFER_UNITARY: "/transferir/:almacenIda?/:almacenMeta?/:id?/",
  ROUTE_REMOVE_UNITARY: "/retirar/:id?",
  ROUTE_INVENTORY: "/inventario/:warehouseId/:inventoryId",
  ROUTE_INVENTORY_SUMMARY: "/inventario/resumen/:warehouseId/:inventoryId",
  ROUTE_INVENTORY_HISTORY: "/historial-inventario",
  ROUTE_ENTER_MULTIPLE: "/ingresarMasivo",
  ROUTE_TRANSFER_MULTIPLE: "/transferirMasivo",
  ROUTE_REMOVE_MULTIPLE: "/retirarMasivo",
};
