import { getProductsAdapter } from "@Adapters/productsAdapters/products/getProducts.adapter";

export const productsInWarehouse = async (
  idWarehouse: number
): Promise<number> => {
  try {
    const products = await getProductsAdapter(idWarehouse);
    if (products.products[0]) {
      return 1;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};
