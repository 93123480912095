import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./EditNameModal.module.css";
import { EditNameModalProps } from "@Models/interfaces/EditNameModal.interface";
import { edit } from "./functions/edit";
import { ModalAcceptCancel, TextInput } from "../../../../resources/index";

const EditNameModal: React.FC<EditNameModalProps> = ({
  close,
  warehouseToEdit,
  setShowWarehouse,
  setSelectedWarehouse,
  setWarehouse,
  warehouses,
  setResetWarehouses,
  resetWarehouses,
  setSelectWarehouse,
  setStateResponse,
  setWarehousesList,
}) => {
  const [warehouseName, setWarehouseName] = useState<string | undefined>(
    warehouseToEdit?.warehouseName
  );
  const [error, setError] = useState<string | undefined>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setError("");
  }, [warehouseName]);

  return (
    <ModalAcceptCancel
      showButtons={{
        showButtonClose: false,
        showButtonOne: true,
        showButtonTwo: true,
      }}
      buttonOne={{
        textButtonOne: "Cancelar",
        actionButtonOne: () => close(),
      }}
      buttonTwo={{
        textButtonTwo: "Aceptar",
        isDisabled: isLoading,
        isLoading: isLoading,
        actionButtonTwo: () =>
          edit(
            warehouses,
            warehouseName,
            setError,
            warehouseToEdit,
            setShowWarehouse,
            setResetWarehouses,
            resetWarehouses,
            setSelectedWarehouse,
            setSelectWarehouse,
            setWarehouse,
            close,
            setStateResponse,
            setIsLoading,
            setWarehousesList
          ),
      }}
    >
      <div className={style.body}>
        <h2>Editar Almacén</h2>
        <div className={style.containerForm}>
          <TextInput
            label={"Nombre del almacén"}
            value={warehouseName || ""}
            touched={error}
            error={error}
            handleChange={(e) => setWarehouseName(e.target.value)}
            placeholder={warehouseToEdit?.warehouseName || ""}
            keyboardType={"text"}
          />
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default EditNameModal;
