import { getWarehouseStatus } from "@Services/warehouses/getWarehouse.services";

export interface WarehouseStatus {
  name: string;
  totalValue: number;
  entries: number;
  withdrawals: number;
  sales: number;
}

export const getWarehouseStatusAdapter = async (warehouseId: number) => {
  const response = await getWarehouseStatus(warehouseId);

  if (!response.isSuccess) {
    return response;
  }

  const data: WarehouseStatus = {
    name: response.data.warehouseName,
    totalValue: response.data.totalValue,
    entries: response.data.movementEntry,
    withdrawals: response.data.movementWithdrawal,
    sales: response.data.movementSales,
  };

  return {
    isSuccess: response.isSuccess,
    status: response.status,
    message: response.message,
    data: data,
  };
};
