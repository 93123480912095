// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wg_JEDDGPldrotClfwZA {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 24px;
  margin-top: 24px;
}

.cfttjkPvJH3LF0eskzHU {
  display: grid;
  max-height: -webkit-fill-available;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  overflow-y: auto;
}

.IRmGD2bSPMNtupl0CxEV {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font-size-h5);
  background: #e8e3ff;
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  color: #45348e;
}

.FPCzJRlqCOQwEcku6FQa {
  background: #45348e;
  color: white;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 0.6em;
}

.nIzq1fdM2CT6PvQXxMSe {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreateMasiveProductsOverview/CreateMasiveProductsOverview.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,4DAA4D;EAC5D,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".masive__products__overview__container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  gap: 24px;\n  margin-top: 24px;\n}\n\n.masive__products__overview__grid {\n  display: grid;\n  max-height: -webkit-fill-available;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  grid-gap: 16px;\n  overflow-y: auto;\n}\n\n.masive__products__overview__warehouse__title {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: var(--font-size-h5);\n  background: #e8e3ff;\n  padding: 8px 16px;\n  margin-bottom: 8px;\n  border-radius: 4px;\n  color: #45348e;\n}\n\n.masive__products__overview__warehouse__new__badge {\n  background: #45348e;\n  color: white;\n  padding: 4px 8px;\n  border-radius: 6px;\n  font-size: 0.6em;\n}\n\n.masive__products__overview__warehouse {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"masive__products__overview__container": `wg_JEDDGPldrotClfwZA`,
	"masive__products__overview__grid": `cfttjkPvJH3LF0eskzHU`,
	"masive__products__overview__warehouse__title": `IRmGD2bSPMNtupl0CxEV`,
	"masive__products__overview__warehouse__new__badge": `FPCzJRlqCOQwEcku6FQa`,
	"masive__products__overview__warehouse": `nIzq1fdM2CT6PvQXxMSe`
};
export default ___CSS_LOADER_EXPORT___;
