import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export interface DeleteCategory {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const deleteCategory = async (
  id: number,
  idCategory: number
): Promise<DeleteCategory> => {
  const url = `${baseUrl}/LocalProducts/${id}/Category/${idCategory}`;
  try {
    const response = await axios.delete(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message || "Error al editar la categoría.",
    };
  }
};

export const deleteCategoryServices = async (idCategory: number) => {
  const url = `${baseUrl}/Categories/DeleteCategory`;
  const obj = {
    categoryId: idCategory,
  };
  try {
    const response = await axios.delete(url, {
      data: obj,
      headers: headerAuthorization.headers,
    });
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message || "Error al eliminar la categoría.",
    };
  }
};
