import { getHistorialInventoryService } from "@Services/historialInventory/getHistorialInventory.service";

const getInventoryHistorial = async (
  id?: number | string,
  setStateResponse?: any,
  setErrorView?: any
) => {
  let idWarehouse = id;
  if (id === "Todas") {
    idWarehouse = "";
  }
  const response = await getHistorialInventoryService(idWarehouse);

  if (response.isSuccess) {
    const formatData = response.data
      .map((item: any) => {
        const status = ["Completado", "En proceso", "Subsanar"];
        const dateStartDay = item.dateStart.split(" ")[0];
        const conflictStatus =
          item.dateEnd !== null
            ? status[0]
            : item.conflict === 0
            ? status[1]
            : status[2];
        return {
          name: dateStartDay + " - " + item.warehouseName,
          status: conflictStatus,
          id: item.inventoryId,
          idWarehouse: item.warehouseId,
          "Fecha de inicio": item.dateStart,
          "Fecha finalizado": item.dateEnd === null ? "-" : item.dateEnd,
        };
      })
      .reverse();
    return {
      isSuccess: true,
      status: response.status,
      data: formatData,
      error: null,
    };
  } else {
    setErrorView &&
      setErrorView((prev: any) => {
        return {
          ...prev,
          errorHistorial: true,
        };
      });
    setStateResponse &&
      setStateResponse({
        message: response.error,
        status: response.status,
      });
    return {
      isSuccess: false,
      status: response.status,
      data: [],
      error: response.error,
    };
  }
};

export default getInventoryHistorial;
