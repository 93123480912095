import React, { useState, useEffect, useRef } from "react";
// @ts-ignore
import style from "../../MainView.module.css";
import select_edition_volume from "../../../../assets/svgs/select_edition_volume.svg";
import { useNavigate } from "react-router";
import { clickHandler } from "@Utilities/clickHandler";
import { FindBoxIcon, BoxIcon, FindIcon } from "../../../../models/icons/icons";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";
import {
  SHOW_INVENTORY_HISTORY_UUID,
  UPSERT_INVENTORIES_UUID,
} from "@Models/const/securityAccess";

const InventorySelect = ({ setModal }: { setModal: Function }) => {
  const [inventory, setInventory] = useState(false);
  const navigate = useNavigate();
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setInventory(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.store} ref={selectRef}>
      <button
        onClick={() => setInventory(!inventory)}
        className={style.selectInventory}
      >
        <img src={FindBoxIcon} alt="Seleccionar inventario" />
        <p>Inventario</p>
        <img
          src={select_edition_volume}
          alt="Seleccionar edición en volumen"
          style={{ transform: inventory ? "rotate(180deg)" : "rotate(0deg)" }}
        />
      </button>

      {inventory && (
        <div className={style.containerSelectInventory}>
          <button
            onClick={() => {
              if (validateModuleAccess(UPSERT_INVENTORIES_UUID)) {
                setInventory(false);
                setModal(2);
              }
            }}
          >
            <img src={BoxIcon} alt="Hacer inventario" />
            <p>Hacer inventario</p>
          </button>
          <button
            onClick={() =>
              validateModuleAccess(SHOW_INVENTORY_HISTORY_UUID) &&
              clickHandler(navigate, "historial-inventario")
            }
          >
            <img src={FindIcon} alt="Historial" />
            <p>Historial</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default InventorySelect;
