// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xw11UHGV_1RYlvdexkJ2 {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.any8L9MSfWwBUE7Mvryk {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/UnitIncomeView/UnitIncomeView.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".container {\n  max-width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.containerBot {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Xw11UHGV_1RYlvdexkJ2`,
	"containerBot": `any8L9MSfWwBUE7Mvryk`
};
export default ___CSS_LOADER_EXPORT___;
