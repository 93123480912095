import styles from "./InvoicePreview.module.css";
import ProductTable from "../ProductTable/ProductTable";

const InvoicePreview = ({ propsTableProducts, invoiceData, isNewSupplier }) => {
  const {
    supplierName,
    supplierAddress,
    supplierRuc,
    billCode,
    customerName,
    customerAddress,
    customerRuc,
    issueDate,
    dueDate,
    billSeries,
    billNumber,
    billTotalAmount,
  } = invoiceData;
  const igv = billTotalAmount * 0.18;
  const valorVenta = billTotalAmount - igv;

  return (
    <div className={styles.invoicePreview}>
      <div className={styles.header}>
        <div className={styles.logo}>
          {supplierName}
          {isNewSupplier && (
            <span className={styles.newProductLabel}>Nuevo proveedor</span>
          )}
        </div>
        <div className={styles.companyInfo}>
          <p>
            <strong>DOMICILIO FISCAL</strong>
          </p>
          <p>{supplierAddress}</p>
        </div>
        <div className={styles.invoiceInfo}>
          <p>
            <strong>R.U.C. {supplierRuc}</strong>
          </p>
          <p>
            <strong>FACTURA ELECTRONICA</strong>
          </p>
          <p>
            <strong>{billCode}</strong>
          </p>
        </div>
      </div>
      {/* <div className={styles.customerInfo}>
        <p>
          <strong>SEÑOR (ES):</strong> {customerName}
        </p>
        <p>
          <strong>R.U.C. No.:</strong> {customerRuc}
        </p>
        <p>
          <strong>DIRECCIÓN:</strong> {customerAddress}
        </p>
      </div> */}
      <table className={styles.orderDetails}>
        <tbody>
          <tr>
            <td>
              <strong>FECHA EMISIÓN</strong>
            </td>
            <td>
              <strong>FECHA VENCIMIENTO</strong>
            </td>
          </tr>
          <tr>
            <td>{issueDate}</td>
            <td>{dueDate}</td>
          </tr>
        </tbody>
      </table>
      <ProductTable {...propsTableProducts} />
      <div className={styles.totalAmount}>
        <p>
          <span>OP. GRAVADA</span> <span>S/ {valorVenta.toFixed(2)}</span>
        </p>
        <p>
          <span>I.G.V.</span> <span>S/ {igv.toFixed(2)}</span>
        </p>
        <p>
          <span>TOTAL DE LA FACTURA</span>{" "}
          <span>S/ {billTotalAmount.toFixed(2)}</span>{" "}
        </p>
      </div>
    </div>
  );
};

export default InvoicePreview;
