import React from "react";
// @ts-ignore
import style from "./CheckboxInput.module.css";
import { CheckboxInputProps } from "@Models/interfaces/CheckBoxInput.interface";

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  name,
  value = "",
  handleChange,
  disabled,
  checked,
}) => {
  const checkboxId = `checkbox-${name}`;

  return (
    <div className={style.inputContainer}>
      <input
        id={checkboxId}
        className={style.inputCheckbox}
        type="checkbox"
        name={name}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
      />
      <label className={style.inputLabel} htmlFor={checkboxId}>
        <p>{label}</p>
      </label>
    </div>
  );
};

export default CheckboxInput;
