import { getWarehousesAdapter } from "@Adapters/warehousesAdapters/getWarehouses.adapter";
import { useEffect } from "react";

const selectedWarehouseGlobal = async () => {
  // Parche
  const getWarehouses = async () => {
    const response = await getWarehousesAdapter({
      setStateResponse: () => {},
      setIsLoading: () => {},
    });
    if (response.isSuccess) {
      return response.warehouses;
    }
  };

  try {
    let selectedWarehouse = JSON.parse(
      localStorage.getItem("selectedWarehouse")
    );

    if (!selectedWarehouse) {
      const warehouses = await getWarehouses();

      const warehouse =
        warehouses.find((warehouse) => warehouse.sales === 1) || warehouses[0];
      selectedWarehouse = {
        warehouseId: warehouse.warehouseId,
        warehouseName: warehouse.warehouseName,
        sale: warehouse.sales,
      };
      localStorage.setItem(
        "selectedWarehouse",
        JSON.stringify(selectedWarehouse)
      );
    }

    if (selectedWarehouse?.nombreAlmacen && selectedWarehouse?.idAlmacen) {
      selectedWarehouse = {
        warehouseId: selectedWarehouse.idAlmacen,
        warehouseName: selectedWarehouse.nombreAlmacen,
        sale: selectedWarehouse.venta,
      };
      return selectedWarehouse;
    } else if (
      selectedWarehouse?.warehouseId &&
      selectedWarehouse?.warehouseName
    ) {
      return selectedWarehouse;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default selectedWarehouseGlobal;
