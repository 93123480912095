import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { putSalesWarehousesAdapter } from "@Adapters/warehousesAdapters/putWarehouses.adapter";
import { setSessionWarehouse } from "@Utilities/warehouseUtils";

export const saleWarehouse = async (
  warehouse: Warehouse | undefined,
  setShowWarehouse: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedWarehouse: any,
  setWarehouse: any,
  resetWarehouses: boolean,
  setResetWarehouses: React.Dispatch<React.SetStateAction<boolean>>,
  setStateResponse: any
): Promise<void> => {
  if (warehouse) {
    setStateResponse({
      message: "Actualizando almacén...",
      status: "pending",
    });
    let response = await putSalesWarehousesAdapter(warehouse.warehouseId);
    if (response.isSuccess) {
      setShowWarehouse(false);
      setSelectedWarehouse(warehouse.warehouseId);
      setWarehouse(warehouse.warehouseName);
      setSessionWarehouse({
        warehouseId: warehouse?.warehouseId,
        warehouseName: warehouse?.warehouseName,
        sales: 1,
        warehouseValue: warehouse?.warehouseValue || 0,
      });
      setStateResponse({
        message: `El almacén "${warehouse?.warehouseName}" se ha actualizado correctamente`,
        status: response.status,
      });
      setResetWarehouses(!resetWarehouses);
    } else {
      setStateResponse({
        message: `El almacén "${warehouse?.warehouseName}" no se ha podido actualizar, intente nuevamente`,
        status: response.status,
      });
    }
  }
};
