// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z7bryTRCang2grgExMoS {
  text-align: start;
}

.IXNGR3EajK2sH9sAHMXT {
  display: flex;
  gap: 20px;
  padding-bottom: 10px;
}

.nRbvcHO9Q62nmYxy9er9,
.UkRfrjbmOw054y_anAHH {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 150ms ease-in-out;
}

.nRbvcHO9Q62nmYxy9er9 {
  background-color: #dad1f4;

  &:active {
    transform: scale(0.9);
    transition: all 150ms ease-in-out;
  }
}

.UkRfrjbmOw054y_anAHH {
  background-color: #fde6e6;

  &:active {
    transform: scale(0.9);
    transition: all 150ms ease-in-out;
  }
}

.OJ5bgMyAsFdyLF_FdgmQ {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  width: 35px;
  height: 35px;
  min-height: 35px;
  min-width: 35px;
  border-radius: 50%;
  background: #dad1f4;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.OJ5bgMyAsFdyLF_FdgmQ:hover {
  background: #d0c3f4;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/WarehouseSelect/tableMobile/tableMobile.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,oBAAoB;AACtB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;;EAEzB;IACE,qBAAqB;IACrB,iCAAiC;EACnC;AACF;;AAEA;EACE,yBAAyB;;EAEzB;IACE,qBAAqB;IACrB,iCAAiC;EACnC;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".titles {\n  text-align: start;\n}\n\n.containerButtonsMovementsTable {\n  display: flex;\n  gap: 20px;\n  padding-bottom: 10px;\n}\n\n.icon__edit,\n.icon__delete {\n  width: 35px;\n  height: 35px;\n  min-width: 35px;\n  min-height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 50%;\n  transition: all 150ms ease-in-out;\n}\n\n.icon__edit {\n  background-color: #dad1f4;\n\n  &:active {\n    transform: scale(0.9);\n    transition: all 150ms ease-in-out;\n  }\n}\n\n.icon__delete {\n  background-color: #fde6e6;\n\n  &:active {\n    transform: scale(0.9);\n    transition: all 150ms ease-in-out;\n  }\n}\n\n.containerRedirectionIcon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0.625rem;\n  width: 35px;\n  height: 35px;\n  min-height: 35px;\n  min-width: 35px;\n  border-radius: 50%;\n  background: #dad1f4;\n  cursor: pointer;\n  transition: all 100ms ease-in-out;\n}\n\n.containerRedirectionIcon:hover {\n  background: #d0c3f4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titles": `z7bryTRCang2grgExMoS`,
	"containerButtonsMovementsTable": `IXNGR3EajK2sH9sAHMXT`,
	"icon__edit": `nRbvcHO9Q62nmYxy9er9`,
	"icon__delete": `UkRfrjbmOw054y_anAHH`,
	"containerRedirectionIcon": `OJ5bgMyAsFdyLF_FdgmQ`
};
export default ___CSS_LOADER_EXPORT___;
