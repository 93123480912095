import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
// @ts-ignore
import style from "./InventoryHistory.module.css";
import {
  HeaderNavigation,
  PaginationWrapper,
  Icon,
  SelectInput,
  ButtonWithoutIcon,
  TableMobile,
  SlideNotification,
  CriticalErrorPage,
  validateModuleAccess,
} from "../../resources";
import InventoryTable from "@Components/InventoryTable/InventoryTable";
import { getWarehouses } from "@Services/warehouses/getWarehouse.services";
import SelectWarehouseModal from "@Pages/MainView/componentsMainView/ModalTransfer copy/SelectWarehouseModal";
import getInventoryHistorial from "@Adapters/inventoryHistorial/InventarioHistorial.adapter";
import Loader from "@Components/Loader/Loader";
import { FindIcon, TimesIcon } from "@Models/icons/icons";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { dataMobileInventoryHistory } from "./dataMobileInventoryHistory/dataMobileInventoryHistory";
// @ts-ignore
import { IconWithOptions } from "@viuti/recursos";
import { HiMiniListBullet } from "react-icons/hi2";
import { UPSERT_INVENTORIES_UUID } from "@Models/const/securityAccess";

// ID ALMACEN / ID INVENTARIO
const getColorByEstado = (estado) => {
  switch (estado) {
    case "Completado":
      return "#D7FED0";
    case "En proceso":
      return "#FFE8BB";
    case "Subsanar":
      return "#FBB";
    default:
      return "";
  }
};
const InventoryHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [inventoryPaginated, setInventoryPaginated] = useState([]);
  const [inventoryList, setInventoryList] = useState<any>([]);
  const navigate = useNavigate();
  const [warehousesList, setWarehousesList] = useState<any>([]);
  const [currentPagination, setCurrentPagination] = useState<number>(1);
  const [form, setForm] = useState({
    initialDate: "",
    finalDate: "",
    warehouseSelected: "Todas",
  });
  const [initialForm, setInitialForm] = useState({
    initialDate: "",
    finalDate: "",
    warehouseSelected: "Todas",
  });
  const adaptSelectWarehouse = warehousesList.map((warehouse) => {
    return {
      name: warehouse.warehouseName,
      value: warehouse.warehouseId,
    };
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [error, setError] = useState({
    endDate: "",
  });
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [errorView, setErrorView] = useState({
    errorHistorial: false,
  });

  useEffect(() => {
    const getFirstDayOfMonth = () => {
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      return firstDayOfMonth.toISOString().split("T")[0]; // Formato YYYY-MM-DD
    };
    setForm({
      ...form,
      initialDate: getFirstDayOfMonth(),
      finalDate: new Date().toISOString().split("T")[0],
    });
    setInitialForm({
      ...form,
      initialDate: getFirstDayOfMonth(),
      finalDate: new Date().toISOString().split("T")[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const unitOrVolume = {
    unit: false,
    volume: false,
  };
  const idProductModal = 0;

  const [openModalNewInventory, setOpenModalNewInventory] = useState(false);
  const screenWidth = useScreenWidth();

  const columns = [
    "Inventario",
    "Estado",
    "Fecha de inicio",
    "Fecha finalizado",
  ];
  const rows = inventoryPaginated.map((item, index) => ({
    Inventario: (
      <button
        key={item.id}
        className={style.inventaryName}
        onClick={() => {
          if (item.status !== "Completado") {
            validateModuleAccess(UPSERT_INVENTORIES_UUID) &&
              navigate(`/inventario/resumen/${item.idWarehouse}/${item.id}`);
          } else navigate(`/inventario/resumen/${item.idWarehouse}/${item.id}`);
        }}
      >
        {item.name}
      </button>
    ),
    Estado: (
      <div
        key={item.id}
        className={style.status}
        style={{ backgroundColor: getColorByEstado(item.status) }}
      >
        <p>{item.status}</p>
      </div>
    ),
    "Fecha de inicio": item["Fecha de inicio"],
    "Fecha finalizado": item["Fecha finalizado"],
  }));

  const colgroup = "auto 200px 200px 200px";

  const closeModalNewInventory = () => {
    setOpenModalNewInventory(false);
  };

  useEffect(() => {
    const fetchWarehouses = async () => {
      const response = await getWarehouses();
      if (response.isSuccess) {
        setWarehousesList([
          {
            warehouseId: "Todas",
            warehouseName: "Todos los almacenes",
            sale: 0,
          },
          ...response.data,
        ]);
      } else {
        setStateResponse({
          message: response.error,
          status: response.status,
        });
      }
    };
    fetchWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const backResponse = await getInventoryHistorial(
        null,
        setStateResponse,
        setErrorView
      );
      if (backResponse.isSuccess) {
        setInventoryList(backResponse.data);
        const paginatedData = backResponse.data.slice(0, 10);
        setInventoryPaginated(paginatedData);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      form.initialDate === initialForm.initialDate &&
      form.finalDate === initialForm.finalDate &&
      form.warehouseSelected === initialForm.warehouseSelected
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.warehouseSelected, form.initialDate, form.finalDate]);

  useEffect(() => {
    const paginatedData = inventoryList.slice(
      (currentPagination - 1) * 10,
      currentPagination * 10
    );
    setInventoryPaginated(paginatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPagination, inventoryList]);

  const cargar = async () => {
    if (form.initialDate > form.finalDate) {
      setError({ ...error, endDate: `Debe ser posterior a fecha inicio.` });
      setIsButtonDisabled(true);
      return;
    }
    setError({ ...error, endDate: "" });
    setIsLoadingButton(true);
    setInitialForm(form);
    if (currentPagination !== 1) {
      setCurrentPagination(1);
    }
    const backResponse = await getInventoryHistorial(
      form.warehouseSelected,
      setStateResponse,
      setErrorView
    );
    if (backResponse.isSuccess) {
      setInventoryList(backResponse.data);
      const paginatedData = backResponse.data.slice(0, 10);
      setInventoryPaginated(paginatedData);
    }
    setIsLoadingButton(false);
    setIsButtonDisabled(true);
  };
  const buttonTittle = screenWidth < 600 ? "Nuevo" : "Nuevo inventario";

  const otherComponent = () => {
    if (screenWidth < 900) {
      return (
        <div>
          <IconWithOptions
            buttonsStyles={{
              padding: "15px",
            }}
            containerStyles={{
              borderRadius: "6px",
            }}
            icon={<HiMiniListBullet size={40} color={"#473693"} />}
            actions={[
              {
                label: "Nuevo inventario",
                handler: () => {
                  validateModuleAccess(UPSERT_INVENTORIES_UUID) &&
                    setOpenModalNewInventory(true);
                },
              },
            ]}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  if (errorView.errorHistorial) {
    return (
      <div id="viuti-front-mainContent">
        <HeaderNavigation
          title={"Historial de inventario"}
          previousAction={() => window.location.replace("/almacen")}
          previousActionMovement={""}
          buttonProps={{
            textBttn: buttonTittle,
            handleClick: () =>
              validateModuleAccess(UPSERT_INVENTORIES_UUID) &&
              setOpenModalNewInventory(true),
            isDisabled: false,
            isPrimary: true,
            isHidden: screenWidth < 900,
          }}
        />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title={"Historial de inventario"}
        previousAction={() => window.location.replace("/almacen")}
        previousActionMovement={""}
        buttonProps={{
          textBttn: buttonTittle,
          handleClick: () =>
            validateModuleAccess(UPSERT_INVENTORIES_UUID) &&
            setOpenModalNewInventory(true),
          isDisabled: false,
          isPrimary: true,
          isHidden: screenWidth < 900,
        }}
        otherComponent={screenWidth < 900 && otherComponent()}
      />
      <div className={style.body}>
        <div className={style.headerFilter}>
          <SelectInput
            label="Almacén"
            value={form.warehouseSelected}
            items={adaptSelectWarehouse}
            handleChange={(e) => {
              setForm({ ...form, warehouseSelected: e.target.value });
            }}
          />
          <ButtonWithoutIcon
            textBttn={"Cargar"}
            handleClick={cargar}
            isLoading={isLoadingButton}
            isPrimary={true}
            isDisabled={isButtonDisabled}
          />
        </div>
        {isLoading && screenWidth >= 900 && <Loader />}
        {screenWidth >= 900 ? (
          <InventoryTable
            columns={columns}
            rows={rows}
            colgroup={colgroup}
            screenWidth={screenWidth}
          />
        ) : (
          <div>
            <TableMobile
              isLoading={isLoading}
              styleTitles={{
                justifyContent: screenWidth < 600 ? "space-between" : "start",
                width: "100%",
              }}
              dataTable={inventoryPaginated.map((inventory, index) => ({
                ...dataMobileInventoryHistory({
                  inventory,
                  screenWidth,
                  index,
                  navigate,
                }),
              }))}
            />
          </div>
        )}
        {!isLoading && inventoryPaginated.length === 0 && (
          <div className={style.tableMsg}>
            <span className={style.icon__container}>
              <Icon path={FindIcon} alt="Icono de inventario" size={"76px"} />
            </span>
            <p className={style.emptyWarehouse__message}>
              Actualmente no hay inventarios registrados en este almacén.
              <span>
                Por favor, elige otro almacén o has un inventario nuevo
              </span>
            </p>
          </div>
        )}
        {inventoryList.length > 10 && (
          <div className={style.containerPagination}>
            <PaginationWrapper
              currentPagination={currentPagination}
              setCurrentPagination={setCurrentPagination}
              optionsPerPage={10}
              options={inventoryList}
            />
          </div>
        )}
      </div>
      {openModalNewInventory && (
        <SelectWarehouseModal
          close={closeModalNewInventory}
          warehousesList={warehousesList}
          unitOrVolume={unitOrVolume}
          idProduct={idProductModal}
        />
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default InventoryHistory;
