import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export interface RequestBodyEditProductCode {
  idProduct: number;
  code: string;
}

interface PutProductCode {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

export const editProductCode = async (
  data: RequestBodyEditProductCode
): Promise<PutProductCode> => {
  const url = `${baseUrl}/LocalProducts/CodeProduct`;
  try {
    const response = await axios.put(url, data, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al editar el código del producto, intente nuevamente.",
    };
  }
};
