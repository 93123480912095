import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

export const finishInventory_service = async (
  warehouseId: number,
  inventoryId: number
) => {
  const url = `${baseUrl}/Inventory/FinalizeInventory?inventoryId=${inventoryId}&wareHosueId=${warehouseId}`;

  try {
    const response = await axios.post(url, null, headerAuthorization);

    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Hubo un error al finalizar el inventario, por favor intenta de nuevo.",
    };
  }
};
