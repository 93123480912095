import { getBrandsAdapter } from "@Adapters/productsAdapters/brands/getBrands.adapter";
import { getSubBrandsAdapter } from "@Adapters/productsAdapters/subBrands/getSubBrands.adapter";

export const getBrands = async (setStateResponse, setErrorView, id) => {
  const response = await getBrandsAdapter();
  if (response.isSuccess) {
    return response.brands;
  } else {
    setStateResponse({
      message: response.error,
      status: response.status,
    });
    if (!id) {
      setErrorView((prev) => ({ ...prev, errorCategory: true }));
    }
    return [];
  }
};

export const getSubsBrands = async (idBrand: number, setStateResponse) => {
  try {
    if (!idBrand) return [];
    const response = await getSubBrandsAdapter(idBrand, setStateResponse);
    return response.subBrands;
  } catch (error) {}
};
