import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { useEffect, useState } from "react";
import { getWarehousesAdapter } from "@Adapters/warehousesAdapters/getWarehouses.adapter";

const useWarehouses = (setStateResponse, setIsLoading) => {
  const [warehousesList, setWarehousesList] = useState<Warehouse[]>([]);

  useEffect(() => {
    const getWarehouses = async () => {
      const response = await getWarehousesAdapter({
        setStateResponse,
        setIsLoading,
      });
      if (response.isSuccess) {
        setWarehousesList(response.warehouses);
      }
    };
    getWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { warehousesList };
};

export default useWarehouses;
