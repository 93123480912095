// CreateMasiveProductsOverview.js
import React, { useEffect, useState } from "react";
import {
  HeaderNavigation,
  SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";

import styles from "./CreateMasiveProductsOverview.module.css";
import ProductCard from "./components/ProductCard/ProductCard";

const groupByWarehouse = (products) => {
  const warehouses = {};

  products.forEach((product) => {
    const warehouseName = product.wareHouse.name;
    const isNew = product.wareHouse.isNew;
    if (!warehouses[warehouseName]) {
      warehouses[warehouseName] = {
        name: warehouseName,
        isNew: isNew,
        products: [],
      };
    }
    warehouses[warehouseName].products.push(product);
  });

  return Object.values(warehouses);
};

const CreateMasiveProductsOverview = () => {
  const [groupedProducts, setGroupedProducts] = useState([]);
  const [stateResponse, setStateResponse] = React.useState({
    message: "",
    status: 0,
  });

  useEffect(() => {
    const products = localStorage.getItem("masiveProducts");

    if (!products) {
      window.location.replace("/almacen");
    }
    const groupedProducts = groupByWarehouse(JSON.parse(products));
    setGroupedProducts(groupedProducts);
    // localStorage.removeItem("masiveProducts");
  }, []);

  return (
    <div id="viuti-front-mainContent">
      <HeaderNavigation
        title="Resúmen de carga masiva"
        previousAction={() => window.location.replace(`/almacen`)}
        previousActionMovement={"back"}
        buttonProps={{
          textBttn: "Confirmar",
          handleClick: null,
          isHidden: true,
          isPrimary: true,
          isDisabled: false,
          isLoading: false,
        }}
      />
      <div className={styles.masive__products__overview__container}>
        {groupedProducts.map((warehouse) => (
          <div
            key={warehouse.name}
            className={styles.masive__products__overview__warehouse}
          >
            <h2 className={styles.masive__products__overview__warehouse__title}>
              Almacén: {warehouse.name}{" "}
              {warehouse.isNew ? (
                <span
                  className={
                    styles.masive__products__overview__warehouse__new__badge
                  }
                >
                  Nuevo
                </span>
              ) : (
                ""
              )}
            </h2>
            <section className={styles.masive__products__overview__grid}>
              {warehouse.products.map((product) => (
                <ProductCard key={product.productName} product={product} />
              ))}
            </section>
          </div>
        ))}
      </div>
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default CreateMasiveProductsOverview;
