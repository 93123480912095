// ProductCard.js
import React from "react";
import styles from "./ProductCard.module.css";
import { formatToSoles } from "@Utilities/formatToSoles";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";
import { MODIFY_PRODUCTS_UUID } from "@Models/const/securityAccess";

const ProductCard = ({ product }) => {
  return (
    <div className={styles.card}>
      <h3 className={styles.title}>{product.productName}</h3>
      <div className={styles.details}>
        {product?.brand?.name && (
          <p>
            <strong>Marca:</strong> {product.brand.name}{" "}
            {product.brand.isNew && <span className={styles.new}>Nueva</span>}
          </p>
        )}
        {product?.subBrand.name && (
          <p>
            <strong>Sub-Marca:</strong> {product.subBrand.name}{" "}
            {product.subBrand.isNew && (
              <span className={styles.new}>Nueva</span>
            )}
          </p>
        )}
        {!!product?.categories?.length && (
          <p>
            <strong>Categorias:</strong>{" "}
            {product.categories.map((c) => c.name).join(", ")}
          </p>
        )}
        <p>
          <strong>Precio unitario:</strong> {formatToSoles(product.unitPrice)}
        </p>
        <p className={styles.stock}>
          <strong>Stock:</strong> +{product.stock} uds.
        </p>
        {!!product?.alertStock && (
          <p>
            <strong>Alerta de stock:</strong> {product.alertStock}
          </p>
        )}
      </div>
      <button
        className={styles.button__edit}
        type="button"
        onClick={() =>
          validateModuleAccess(MODIFY_PRODUCTS_UUID) &&
          (window.location.href = `/almacen/detalles/${product.idLocalProduct}`)
        }
      >
        Editar
      </button>
    </div>
  );
};

export default ProductCard;
