import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { create } from "zustand";

export type IProductInventory = {
  inventoryId?: number;
  id: number;
  name: string;
  code: string;
  stock: number;
  counter?: string;
  differenceMessage?: string;
  difference?: number;
  brand: {
    id: number;
    name: string;
  };
  line: {
    id: number;
    name: string;
  };
  categories: {
    id: number;
    name: string;
  }[];
};

export interface IWarehouseInventoryStore {
  warehouse: Warehouse;
  setWarehouse: (warehouse: Warehouse) => void;
  products: IProductInventory[];
  setProducts: (products: IProductInventory[]) => void;
  successMsg: string;
  setSuccessMsg: (successMsg: string) => void;
}

export const useWarehouseInventoryStore = create((set) => ({
  warehouse: {
    warehouseId: 0,
    warehouseName: "",
    warehouseAddress: "",
  },
  setWarehouse: (warehouse: Warehouse) => set({ warehouse: warehouse }),
  products: [],
  setProducts: (products: IProductInventory[]) => set({ products }),
  successMsg: "",
  setSuccessMsg: (successMsg: string) => set({ successMsg }),
}));
