import React, { useRef, useState } from "react";
// @ts-ignore
import styled from "../../SearchBar.module.css";
import { filterConfig } from "./filterConfig";
import { FilterProps } from "@Models/interfaces/Filter.interface";
import { TimesIcon, FilteredOutIcon } from "@Models/icons/icons";

const ButtonFilter: React.FC<{
  setViewFilter: React.Dispatch<React.SetStateAction<boolean>>;
  viewFilter: boolean;
}> = ({ setViewFilter, viewFilter }) => {
  return (
    <button
      className={styled.searchBar__button}
      type="button"
      onClick={() => setViewFilter(!viewFilter)}
    >
      <img
        src={FilteredOutIcon}
        alt="Filtro"
        className={styled.searchBarFilter__icon}
      />
    </button>
  );
};

const TitleCategory: React.FC<{ filter: string; multipeSelect: boolean }> = ({
  filter,
  multipeSelect,
}) => {
  return (
    <header className={styled.filter_categories_header}>
      <p className={styled.filter_categories}>{filterConfig[filter]?.title}</p>
      {multipeSelect ? (
        <label className={styled.filter_categories_label}>
          <input
            type="checkbox"
            className={styled.filter_categories_inputCheckbox}
          />
          Todos
        </label>
      ) : null}
    </header>
  );
};

const OptionsCategories: React.FC<{ filter: string }> = ({ filter }) => {
  return (
    <form id={filter} className={styled.filter_categories_form}>
      {filterConfig[filter].options.map((option, index) => {
        return (
          <label
            key={index}
            form={filter}
            className={styled.filter_categories_optionsLabel}
          >
            <input
              name={filter}
              type={filterConfig[filter].type}
              className={
                filterConfig[filter].type === "checkbox"
                  ? styled.filter_categories_inputCheckbox
                  : styled.filter_categories_inputRadio
              }
            />
            {option}
          </label>
        );
      })}
    </form>
  );
};

const Filter: React.FC<FilterProps> = ({ filterUsed, handleFilterChange }) => {
  const [viewFilter, setViewFilter] = useState(false);
  const filterContainer = useRef<HTMLDivElement | null>(null);
  return (
    <section
      ref={filterContainer}
      className={styled.searchBar__containerFilter}
    >
      {<ButtonFilter setViewFilter={setViewFilter} viewFilter={viewFilter} />}
      {viewFilter ? (
        <article className={styled.searchBar__filterContainer}>
          <header className={styled.filter_header}>
            <button
              onClick={() => setViewFilter(!viewFilter)}
              className={styled.filter_closeButton}
              type="button"
            >
              <img src={TimesIcon} alt="Cerrar" width={12} />
            </button>
            <strong className={styled.filter_title}>Filtros</strong>
          </header>
          {filterUsed?.map((filter, index) => {
            return (
              <div key={index} style={{ flexDirection: "column" }}>
                {
                  <TitleCategory
                    filter={filter}
                    multipeSelect={filterConfig[filter].allOptionsAvaliable}
                  />
                }
                {<OptionsCategories filter={filter} />}
              </div>
            );
          })}
        </article>
      ) : null}
    </section>
  );
};

export default Filter;
