import { AdaptedBrandsResponse } from "@Models/interfaces/AdaptedBrandsResponse.interface";
import { Brand } from "@Models/interfaces/productsInterfaces/brandInterface/brand.interface";
import { getBrands } from "@Services/products/brands/getBrands.services";

export const getBrandsAdapter = async (): Promise<AdaptedBrandsResponse> => {
  const brands = await getBrands();
  if (brands.isSuccess) {
    const brandsAdapt: Brand[] = brands.data.map((brand: Brand) => {
      const warehouseAdapt: Brand = {
        brandId: brand.brandId,
        brandName: brand.brandName,
      };
      return warehouseAdapt;
    });
    return {
      isSuccess: true,
      status: brands.status,
      brands: brandsAdapt,
      error: null,
    };
  } else {
    return {
      isSuccess: false,
      status: brands.status,
      brands: [],
      error: brands.error,
    };
  }
};
