import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";

export const deleteProduct = (
  productSelect: Product,
  setModal: any,
  setProductDelete: any
) => {
  setProductDelete(productSelect);
  setModal(4);
};
