import { getStockMovements } from "@Services/products/stockMovements/stockMovements.services";
import { formatDateTime } from "@Utilities/formatDateTime";
export const tempValidation = (text) => {
  if (text !== String && text !== null && text !== "" && text !== undefined) {
    return `${text}`;
  }

  if (text === null || text === "" || text === undefined) {
    return "";
  }
};

export const getStockMovementsAdapter = async (
  productId,
  warehouseId: number | null | string,
  initialDate,
  finalDate,
  currentPage,
  setStateResponse,
  setErrorView
) => {
  if (warehouseId === "Todas") {
    warehouseId = null;
  }
  const stockMovements = await getStockMovements(
    productId,
    warehouseId,
    initialDate,
    finalDate,
    currentPage
  );
  if (stockMovements.isSuccess) {
    return {
      isSuccess: true,
      status: stockMovements.status,
      stockMovements: {
        stockMovements: stockMovements.data.stockMovements,
        maxPage: stockMovements.data.maxPage,
        actualPage: stockMovements.data.actualPage,
      },
      error: null,
    };
  } else {
    setErrorView((prev) => ({
      ...prev,
      errorStockMovements: true,
    }));
    setStateResponse({
      status: stockMovements.status,
      message: stockMovements.error,
    });
    return {
      isSuccess: false,
      status: stockMovements.status,
      stockMovements: [],
      error: stockMovements.error,
    };
  }
};

export const stockMovementsAdapt = (response) => {
  const typeOperationName = (typeOperation) => {
    if (typeOperation === "INGRESO") {
      return "Ingreso";
    } else if (typeOperation === "SALIDA") {
      return "Egreso";
    } else {
      return typeOperation;
    }
  };

  const operationQuantity = (operationQuantity, typeOperationName) => {
    const absQuantity = Math.abs(operationQuantity);
    const unit = absQuantity === 1 ? "producto" : "productos";

    if (typeOperationName === "Ingreso") {
      return `${operationQuantity}`;
    } else if (typeOperationName === "Egreso") {
      return `-${operationQuantity}`;
    } else {
      return `${operationQuantity}`;
    }
  };

  return response.stockMovements.stockMovements.map((stockMovement) => {
    const obj = {
      stock: tempValidation(stockMovement.stock),
      typeOperationName: typeOperationName(
        tempValidation(stockMovement.typeOperationName)
      ),
      operationQuantity: operationQuantity(
        tempValidation(stockMovement.operationQuantity),
        typeOperationName(tempValidation(stockMovement.typeOperationName))
      ),
      observation: tempValidation(stockMovement.observation),
      originWarehouseName: tempValidation(stockMovement.originWarehouseName),
      destinationWarehouseName: tempValidation(
        stockMovement.destinationWarehouseName
      ),
      employeeName: tempValidation(stockMovement.employeeName),
      movementDate: formatDateTime(tempValidation(stockMovement.movementDate)),
    };
    return obj;
  });
};
