import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { incomeProducts } from "@Services/products/products/incomeProducts.services";

export const incomeProductsAdapter = async (
  product: Product,
  incomeTotal: number,
  warehouse: Warehouse,
  cost: number
): Promise<ResponseToRequest> => {
  const obj = {
    incomeProducts: [
      {
        idLocalProduct: product?.productLocalId,
        amountEnter: incomeTotal,
        idWarehouse: warehouse?.warehouseId,
        totalCost: cost,
      },
    ],
  };

  const response = await incomeProducts(obj);

  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
