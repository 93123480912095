import React from "react";
import style from "./FilterProducts.module.css";
import { CheckIcon, TimesIcon, ValidatedIcon } from "@Models/icons/icons";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { ModalAcceptCancel, Icon } from "../../../../resources";

const FilterProducts = ({
  openFilter,
  closeFilter,
  productsList_,
  filtered,
  setFiltered,
}) => {
  const screenWidth = useScreenWidth();
  const handleOrderCheckboxChange = (order) => {
    setFiltered((prevFiltered) => ({
      ...prevFiltered,
      order: {
        defaultOrder: false,
        lowStock: false,
        highStock: false,
        [order]: !prevFiltered.order[order],
      },
    }));
  };

  const handleViewCheckboxChange = (stock) => {
    setFiltered((prevFiltered) => ({
      ...prevFiltered,
      viewForProducts: {
        ...prevFiltered.viewForProducts,
        [stock]: !prevFiltered.viewForProducts[stock],
      },
    }));
  };

  const handleCategoryCheckboxChange = (category) => {
    setFiltered((prevFiltered) => ({
      ...prevFiltered,
      categories: prevFiltered.categories.includes(category)
        ? prevFiltered.categories.filter((cat) => cat !== category)
        : [...prevFiltered.categories, category],
    }));
  };

  if (!openFilter) return;

  return (
    <ModalAcceptCancel
      title="Filtros"
      visibility={openFilter}
      actionButtonClose={closeFilter}
      showButtons={{
        showButtonOne: false,
        showButtonTwo: false,
        showButtonClose: true,
      }}
      buttonOne={{
        textButtonOne: "Aplicar",
        actionButtonOne: closeFilter,
      }}
      buttonTwo={{
        textButtonTwo: "Cancelar",
        actionButtonTwo: closeFilter,
      }}
    >
      <div className={style.containerFilterOptions}>
        <span className={style.titlesFilter}>Ordenar</span>
        <div className={style.orderSection}>
          <div
            className={style.filterflex}
            style={{
              flexDirection: screenWidth < 420 ? "column" : "row",
            }}
          >
            <button
              className={`${style.orderItem} ${
                filtered.order.defaultOrder ? style.cliquedItem : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleOrderCheckboxChange("defaultOrder");
              }}
            >
              {filtered.order.defaultOrder && (
                <Icon path={ValidatedIcon} size={"16px"} color={"#45348E"} />
              )}
              {!filtered.order.defaultOrder && (
                <span className={style.circle}></span>
              )}
              Orden de creación
            </button>

            <button
              className={`${style.orderItem} ${
                filtered.order.lowStock ? style.cliquedItem : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleOrderCheckboxChange("lowStock");
              }}
            >
              {filtered.order.lowStock && (
                <Icon path={ValidatedIcon} size={"18px"} color={"#45348E"} />
              )}
              {!filtered.order.lowStock && (
                <span className={style.circle}></span>
              )}
              Con menor stock
            </button>
          </div>

          <button
            className={`${style.orderItem} ${
              filtered.order.highStock ? style.cliquedItem : ""
            } ${style.orderItemHighStock}`}
            onClick={(e) => {
              e.stopPropagation();
              handleOrderCheckboxChange("highStock");
            }}
          >
            {filtered.order.highStock && (
              <Icon path={ValidatedIcon} size={"18px"} color={"#45348E"} />
            )}
            {!filtered.order.highStock && (
              <span className={style.circle}></span>
            )}
            Con mayor stock
          </button>
        </div>
        <div className={style.flexItem}>
          <span className={style.titlesFilter}>Ver por productos:</span>
          <button
            className={style.flexItem}
            onClick={(e) => {
              e.stopPropagation();
              if (
                filtered.viewForProducts.inStock &&
                filtered.viewForProducts.outOfStock
              ) {
                setFiltered({
                  ...filtered,
                  viewForProducts: {
                    inStock: false,
                    outOfStock: false,
                  },
                });
              } else {
                setFiltered({
                  ...filtered,
                  viewForProducts: {
                    inStock: true,
                    outOfStock: true,
                  },
                });
              }
            }}
          >
            {filtered.viewForProducts.inStock &&
              filtered.viewForProducts.outOfStock && (
                <div className={style.containerChecked}>
                  <Icon path={CheckIcon} size={"12px"} color={"#45348E"} />
                </div>
              )}
            {((!filtered.viewForProducts.inStock &&
              !filtered.viewForProducts.outOfStock) ||
              (filtered.viewForProducts.inStock &&
                !filtered.viewForProducts.outOfStock) ||
              (!filtered.viewForProducts.inStock &&
                filtered.viewForProducts.outOfStock)) && (
              <span className={style.square}></span>
            )}
            Todos
          </button>
        </div>
        <div className={style.productsSection}>
          <button
            className={`${style.checkboxSquare} ${
              filtered.viewForProducts.inStock ? style.cliquedItem : ""
            }`}
            onClick={(event) => {
              event.stopPropagation();
              handleViewCheckboxChange("inStock");
            }}
          >
            {filtered.viewForProducts.inStock && (
              <div className={style.containerChecked}>
                <Icon path={CheckIcon} size={"10px"} color={"#45348E"} />
              </div>
            )}
            {!filtered.viewForProducts.inStock && (
              <span className={style.square}></span>
            )}
            En stock
          </button>

          <button
            className={`${style.checkboxSquare} ${
              filtered.viewForProducts.outOfStock ? style.cliquedItem : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              handleViewCheckboxChange("outOfStock");
            }}
          >
            {filtered.viewForProducts.outOfStock && (
              <div className={style.containerChecked}>
                <Icon path={CheckIcon} size={"10px"} color={"#45348E"} />
              </div>
            )}
            {!filtered.viewForProducts.outOfStock && (
              <span className={style.square}></span>
            )}
            Agotados
          </button>
        </div>
        <div className={style.flexItem}>
          <span className={style.titlesFilter}>Categorías del producto:</span>
          <button
            className={style.flexItem}
            onClick={(e) => {
              e.stopPropagation();
              if (
                filtered.categories.length ===
                productsList_
                  .flatMap((product) => product.category)
                  .filter(
                    (category, index, self) =>
                      index ===
                      self.findIndex(
                        (c) => c.categoryName === category.categoryName
                      )
                  ).length
              ) {
                setFiltered({
                  ...filtered,
                  categories: [],
                });
              } else {
                setFiltered({
                  ...filtered,
                  categories: productsList_
                    .flatMap((product) => product.category)
                    .filter(
                      (category, index, self) =>
                        index ===
                        self.findIndex(
                          (c) => c.categoryName === category.categoryName
                        )
                    )
                    .map((category) => category.categoryName),
                });
              }
            }}
          >
            {filtered.categories.length ===
              productsList_
                .flatMap((product) => product.category)
                .filter(
                  (category, index, self) =>
                    index ===
                    self.findIndex(
                      (c) => c.categoryName === category.categoryName
                    )
                ).length && (
              <div className={style.containerChecked}>
                <Icon path={CheckIcon} size={"12px"} color={"#45348E"} />
              </div>
            )}
            {filtered.categories.length !==
              productsList_
                .flatMap((product) => product.category)
                .filter(
                  (category, index, self) =>
                    index ===
                    self.findIndex(
                      (c) => c.categoryName === category.categoryName
                    )
                ).length && <span className={style.square}></span>}
            Todas
          </button>
        </div>

        <div className={style.categoriesSection}>
          {productsList_.length === 0 && <p>No hay productos</p>}
          {productsList_.length !== 0 &&
            productsList_
              .flatMap((product) => product.category)
              .filter(
                (category, index, self) =>
                  index ===
                  self.findIndex(
                    (c) => c.categoryName === category.categoryName
                  )
              )
              .map((category) => (
                <button
                  key={category.categoryId}
                  className={`${style.checkboxSquare} ${
                    filtered.categories.includes(category.categoryName)
                      ? style.cliquedItem
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCategoryCheckboxChange(category.categoryName);
                  }}
                >
                  {filtered.categories.includes(category.categoryName) && (
                    <div className={style.containerChecked}>
                      <Icon path={CheckIcon} size={"10px"} color={"#45348E"} />
                    </div>
                  )}
                  {!filtered.categories.includes(category.categoryName) && (
                    <span className={style.square}></span>
                  )}
                  {category.categoryName.toUpperCase()}
                </button>
              ))}
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default FilterProducts;
