import { AdaptedProductsResponse } from "@Models/interfaces/AdaptedProductsResponse.interface";
import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { getProducts } from "@Services/products/products/getProducts.services";

export const getProductsAdapter = async (
  idWareHouse: number,
  setErrorView?: any,
  setStateResponse?: any
): Promise<AdaptedProductsResponse> => {
  const products = await getProducts(idWareHouse);
  if (products.isSuccess) {
    const productsAdapt: Product[] = products.data.map((product: Product) => {
      const productAdapt: Product = {
        productLocalId: product.productLocalId,
        stock: product.stock,
        emergencyStock: product.emergencyStock,
        productName: product.productName,
        code: product.code,
        freeProduct: product.freeProduct,
        price: product.price,
        brandImageUrl: product.brandImageUrl,
        productId: product.productId,
        warehouse: {
          warehouseId: product.warehouse.warehouseId,
          warehouseName: product.warehouse.warehouseName,
        },
        brand: {
          brandId: product.brand.brandId,
          brandName: product.brand.brandName,
        },
        line: {
          lineId: product.line.lineId,
          lineName: product.line.lineName,
        },
        category: product.category,
      };
      return productAdapt;
    });
    return {
      isSuccess: true,
      status: products.status,
      products: productsAdapt,
      error: null,
    };
  } else {
    setStateResponse &&
      setStateResponse({
        message: products.error,
        status: products.status,
      });
    setErrorView((prev: any) => {
      return {
        ...prev,
        errorProduct: true,
      };
    });
    return {
      isSuccess: false,
      status: products.status,
      products: [],
      error: products.error,
    };
  }
};
