import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getAllInventories_service = async (warehouseId) => {
  const url = `${baseUrl}/Inventory/GetInventory?wareHouseId=${warehouseId}`;
  const historial_inventario = 30;
  trackUserAction(historial_inventario);
  try {
    const response = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Ha ocurrido un error al obtener el inventario. Por favor, intente nuevamente.",
    };
  }
};
