import { UPSERT_WAREHOUSES_UUID } from "@Models/const/securityAccess";
import style from "./OpenOptionsWarehouse.module.css";
// @ts-ignore
import { TripleDotsIcon } from "@Models/icons/icons";
// @ts-ignore
import { Icon, validateModuleAccess } from "@viuti/recursos";

const OpenOptionsWarehouse = ({
  wareh,
  editOpenModal,
  setIsHovered,
  setButtonDelete,
  showEdit,
  selectedWarehouseId,
  setSelectedWarehouseId,
  setShowEdit,
  closeOptions,
  disabled,
}) => {
  return (
    <button
      onClick={() =>
        validateModuleAccess(UPSERT_WAREHOUSES_UUID) &&
        !disabled &&
        editOpenModal(
          wareh?.warehouseId,
          setIsHovered,
          setButtonDelete,
          showEdit,
          selectedWarehouseId,
          setSelectedWarehouseId,
          setShowEdit,
          closeOptions
        )
      }
      className={style.containerOptions}
    >
      <Icon path={TripleDotsIcon} alt="Editar almacén" size={20} />
    </button>
  );
};

export default OpenOptionsWarehouse;
