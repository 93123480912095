import { useState, useEffect } from "react";
import { reasonsWithdrawalAdapter } from "@Adapters/productsAdapters/reasonWithdrawal/reasonWithdrawal.adapter";

const useReasonsUnitaryWithdrawal = (setStateResponse, setErrorView) => {
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    const listReasons = async () => {
      let res = await reasonsWithdrawalAdapter(setStateResponse, setErrorView);
      // we adapt it to the format that the Select component needs
      let transformedReasons = res.reasonsWithdrawal.map((reason: any) => ({
        value: reason.id,
        name: reason.motive,
      }));
      setReasons(transformedReasons);
    };
    listReasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { reasons };
};

export default useReasonsUnitaryWithdrawal;
