import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export interface CreateCategory {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string;
}

export const createCategory = async (
  category: object
): Promise<CreateCategory> => {
  const url = `${baseUrl}/Categories`;
  try {
    const response = await axios.post(url, category, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message || "Error al crear la categoría.",
    };
  }
};
