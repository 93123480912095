import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { deleteWarehouse } from "@Pages/MainView/functions/deleteWarehouse";
import { productsInWarehouse } from "@Pages/MainView/functions/productsInWarehouse";

export const openCloseSelect = (
  setShowWarehouse: any,
  showWarehouse: boolean,
  closeOptions: any
) => {
  setShowWarehouse(!showWarehouse);
  closeOptions();
};

export const closeOptionsSelect = (
  setShowWarehouse: any,
  closeOptions?: any
) => {
  setShowWarehouse(false);
  closeOptions && closeOptions();
};

export const changeWarehouse = (
  warehouse: Warehouse,
  setSelectWarehouse: any,
  setWarehouse: any,
  setShowWarehouse: any
) => {
  setSelectWarehouse(warehouse);
  setWarehouse(warehouse?.warehouseName);
  setShowWarehouse(false);
};

export const editOpenModal = async (
  warehouseId: number,
  setIsHovered: any,
  setButtonDelete: any,
  showEdit: boolean,
  selectedWarehouse: number,
  setSelectedWarehouse: any,
  setShowEdit: any,
  closeOptions: any
) => {
  setIsHovered(false);
  setButtonDelete(false);
  if (showEdit && warehouseId === selectedWarehouse) {
    closeOptions();
  } else {
    setSelectedWarehouse(warehouseId);
    setShowEdit(true);
  }
  const products = await productsInWarehouse(warehouseId);
  if (products) {
    setButtonDelete(false);
  } else {
    setButtonDelete(true);
  }
};

export const editName = (
  warehouse: Warehouse,
  setWarehouseToEdit: any,
  setOpenEditModal: any,
  closeOptions: any
) => {
  setWarehouseToEdit(warehouse);
  setOpenEditModal(true);
  closeOptions();
};

export const deleteWarehouseSelected = (
  warehouse: Warehouse,
  resetWarehouses: boolean,
  setShowWarehouse: any,
  setSelectedWarehouse: any,
  setWarehouse: any,
  warehouses: Warehouse[],
  setResetWarehouses: any,
  openCloseSelect: any,
  closeOptions: any,
  showWarehouse: boolean,
  setStateResponse: any,
  setWarehousesList,
  resetProducts,
  setResetProducts,
  setSelectWarehouse
) => {
  deleteWarehouse(
    warehouse,
    setShowWarehouse,
    setSelectedWarehouse,
    setWarehouse,
    warehouses,
    resetWarehouses,
    setResetWarehouses,
    setStateResponse,
    setWarehousesList,
    resetProducts,
    setResetProducts,
    setSelectWarehouse
  );
  openCloseSelect(setShowWarehouse, showWarehouse, closeOptions);
};

export const handleSearchInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setSearchQuery: any
) => {
  const inputValue = e.target.value;
  if (inputValue.length <= 35) {
    setSearchQuery(inputValue);
  }
};
