// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vaY_6cwxrHdQWDb9Qjam {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  gap: 1.5rem;
}

.vaY_6cwxrHdQWDb9Qjam h2 {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.0125rem;
}

.xKZrIZbVqGRnwRMZP6iN {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ebbjmq2yrfjsW76cg7qy {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #45348e;
  text-align: center;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.015rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/EditNameModal/EditNameModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;EACxB,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".body {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  background: #fff;\n  gap: 1.5rem;\n}\n\n.body h2 {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 1.5rem;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.0125rem;\n}\n\n.containerForm {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.editNameText {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  color: #45348e;\n  text-align: center;\n  font-family: Mulish;\n  font-size: 1rem;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  letter-spacing: 0.015rem;\n  margin-top: 2rem;\n  margin-bottom: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `vaY_6cwxrHdQWDb9Qjam`,
	"containerForm": `xKZrIZbVqGRnwRMZP6iN`,
	"editNameText": `ebbjmq2yrfjsW76cg7qy`
};
export default ___CSS_LOADER_EXPORT___;
