import style from "./InputSearchWarehouse.module.css";

const InputSearchWarehouse = ({
  searchQuery,
  setSearchQuery,
  setShowWarehouse,
  warehousesList,
  setSelectedWarehouseId,
  setWarehouseName,
  resetWarehouses,
  setResetWarehouses,
  handleSearchInputChange,
  handleKeyPress,
  setStateResponse,
  setWarehousesList,
  setSelectWarehouse,
}) => {
  // Input search warehouse and press enter create a new warehouse
  return (
    <div className={style.containerSearch}>
      <input
        type="text"
        placeholder="Buscar o crear nuevo almacén..."
        value={searchQuery}
        onChange={(e) => handleSearchInputChange(e, setSearchQuery)}
        onKeyDown={(e) =>
          handleKeyPress(
            e,
            searchQuery,
            setShowWarehouse,
            setSearchQuery,
            warehousesList,
            setSelectedWarehouseId,
            setWarehouseName,
            resetWarehouses,
            setResetWarehouses,
            setStateResponse,
            setWarehousesList,
            setSelectWarehouse
          )
        }
      />
    </div>
  );
};

export default InputSearchWarehouse;
