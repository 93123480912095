// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ASZu7obhF7cI0w_Mk0bS {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 16px;
  gap: 8px;
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.dGnjI0iyEWziz2CS2aBr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
}

.CsSy25tBsLMc6Tmy1A9l {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f0f0f0;
  color: #45348e;
}

.EKG64848GqHhFA8Tzf0A {
  width: 100%;
  height: 6px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.YrftbpOBFoEAqV68jiIk {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.pKvMq0M3vuxGF0N0Jipx {
  font-size: 0.8275rem;
  font-weight: 600;
  margin: 4px 0;
}

.tB3rRghFjAA20FNBNpu2 {
  width: 100%;
  font-size: 0.8275rem;
  color: #45348e;
  text-decoration: none;
}

.tB3rRghFjAA20FNBNpu2:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/AvailableWarehouses/AvailableWarehouses.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,2BAA2B;EAC3B,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,oBAAoB;EACpB,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n  padding: 12px 16px;\n  gap: 8px;\n  width: 100%;\n  height: fit-content;\n  background-color: #ffffff;\n  border: 1px solid #e0e0e0;\n  border-radius: 8px;\n}\n\n.usageContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  gap: 8px;\n}\n\n.users_icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  background-color: #f0f0f0;\n  color: #45348e;\n}\n\n.progressContainer {\n  width: 100%;\n  height: 6px;\n  background-color: #e0e0e0;\n  border-radius: 5px;\n  overflow: hidden;\n}\n\n.progressBar {\n  height: 100%;\n  transition: width 0.3s ease-in-out;\n}\n\n.usageText {\n  font-size: 0.8275rem;\n  font-weight: 600;\n  margin: 4px 0;\n}\n\n.upgradeLink {\n  width: 100%;\n  font-size: 0.8275rem;\n  color: #45348e;\n  text-decoration: none;\n}\n\n.upgradeLink:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ASZu7obhF7cI0w_Mk0bS`,
	"usageContainer": `dGnjI0iyEWziz2CS2aBr`,
	"users_icon": `CsSy25tBsLMc6Tmy1A9l`,
	"progressContainer": `EKG64848GqHhFA8Tzf0A`,
	"progressBar": `YrftbpOBFoEAqV68jiIk`,
	"usageText": `pKvMq0M3vuxGF0N0Jipx`,
	"upgradeLink": `tB3rRghFjAA20FNBNpu2`
};
export default ___CSS_LOADER_EXPORT___;
