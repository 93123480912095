import { postData, supplierBaseUrl } from "@Services/constServices";

export const bulkUploadViaInvoice_service = async (
  obj,
  idWarehouse,
  idSupplier = null
) => {
  const url = `${supplierBaseUrl}/CreateBillsAndUpdateStock?WarehouseId=${idWarehouse}&SupplierId=${idSupplier}`;
  const successMessage = "Productos cargados correctamente.";
  const errorMessage = "Error al cargar productos, intente nuevamente.";
  return postData(url, obj, successMessage, errorMessage);
  // return {
  //   isSuccess: true,
  //   status: 200,
  //   data: dataMock,
  //   message: "Carga masiva de productos exitosa.",
  // };
};

// const dataMock = [
//   {
//     idProduct: 111663,
//     idLocalProduct: 256175,
//     productName: "Veamos si sale",
//     wareHouse: {
//       isNew: false,
//       name: "prueba inventario",
//     },
//     brand: {
//       isNew: false,
//       name: "nike",
//     },
//     subBrand: {
//       isNew: false,
//       name: "sub nike",
//     },
//     categories: [
//       {
//         isNew: false,
//         name: "zapas",
//       },
//     ],
//     unitPrice: 3,
//     stock: 5,
//     alertStock: 1,
//   },
// ];
