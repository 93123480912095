import { Route, Routes } from "react-router-dom";
import { ROUTE_STORE } from "../models/routes/routes";
import {
  NotFoundPage,
  ProtectedRoute,
  SlideNotification,
  // @ts-ignore
} from "@viuti/recursos";
import MainView from "../pages/MainView/MainView";
import CreateProduct from "@Pages/CreateProduct/CreateProduct";
import ViewProduct from "@Pages/ViewProduct/ViewProduct";
import UnitIncomeView from "@Pages/UnitIncomeView/UnitIncomeView";
import UnitaryWithdrawalView from "@Pages/UnitaryWithdrawalView/UnitaryWithdrawalView";
import UnitaryTransferView from "@Pages/UnitaryTransferView/UnitaryTransferView";
import Inventory from "@Pages/Inventory/Inventory";
import InventoryOverview from "@Pages/InventoryOverview/InventoryOverview";
import { IToasterStore, useToasterStore } from "@ReduxService/useToasterStore";
import InventoryHistory from "@Pages/InventoryHistory/InventoryHistory";
import CreateMasiveProducts from "@Pages/CreateMasiveProducts/CreateMasiveProducts";
import CreateMasiveProductsOverview from "@Pages/CreateMasiveProductsOverview/CreateMasiveProductsOverview";
import {
  CREATE_PRODUCTS_UUID,
  DEPOSIT_PRODUCTS_UUID,
  MASSIVE_LOAD_PRODUCTS_UUID,
  MODIFY_PRODUCTS_UUID,
  SHOW_INVENTORY_HISTORY_UUID,
  TRANSFER_PRODUCTS_UUID,
  UPSERT_INVENTORIES_UUID,
  WITHDRAW_PRODUCTS_UUID,
} from "@Models/const/securityAccess";

const MainRoutes = () => {
  const { status, message, loading, setEmpty } =
    useToasterStore() as IToasterStore;
  const toasterConfig = {
    status,
    message,
    loading,
  };

  return (
    <>
      <Routes>
        <Route path={ROUTE_STORE.ROUTE_MAIN_VIEW} element={<MainView />} />
        <Route
          path={ROUTE_STORE.ROUTE_CREATE_PRODUCT}
          element={
            <ProtectedRoute
              accessKeyModule={CREATE_PRODUCTS_UUID}
              element={<CreateProduct />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_MASIVE_CREATE_PRODUCT}
          element={
            <ProtectedRoute
              accessKeyModule={MASSIVE_LOAD_PRODUCTS_UUID}
              element={<CreateMasiveProducts />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_MASIVE_CREATE_PRODUCT_RESUME}
          element={
            <ProtectedRoute
              accessKeyModule={MASSIVE_LOAD_PRODUCTS_UUID}
              element={<CreateMasiveProductsOverview />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_PRODUCT_DETAIL}
          element={
            <ProtectedRoute
              accessKeyModule={MODIFY_PRODUCTS_UUID}
              element={<ViewProduct />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_ENTER_UNITARY}
          element={
            <ProtectedRoute
              accessKeyModule={DEPOSIT_PRODUCTS_UUID}
              element={<UnitIncomeView />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_REMOVE_UNITARY}
          element={
            <ProtectedRoute
              accessKeyModule={WITHDRAW_PRODUCTS_UUID}
              element={<UnitaryWithdrawalView />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_TRANSFER_UNITARY}
          element={
            <ProtectedRoute
              accessKeyModule={TRANSFER_PRODUCTS_UUID}
              element={<UnitaryTransferView />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_INVENTORY}
          element={
            <ProtectedRoute
              accessKeyModule={UPSERT_INVENTORIES_UUID}
              element={<Inventory />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_INVENTORY_SUMMARY}
          element={
            <ProtectedRoute
              accessKeyModule={[
                SHOW_INVENTORY_HISTORY_UUID,
                UPSERT_INVENTORIES_UUID,
              ]}
              validate="one"
              element={<InventoryOverview />}
            />
          }
        />
        <Route
          path={ROUTE_STORE.ROUTE_INVENTORY_HISTORY}
          element={
            <ProtectedRoute
              accessKeyModule={SHOW_INVENTORY_HISTORY_UUID}
              element={<InventoryHistory />}
            />
          }
        />
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>
      <SlideNotification state={toasterConfig} clearStatus={() => setEmpty()} />
    </>
  );
};

export default MainRoutes;
