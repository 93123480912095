import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export interface EditWarehouse {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

export const editWarehouseName = async (
  warehouse: object
): Promise<EditWarehouse> => {
  const url = `${baseUrl}/Warehouses/Warehouse`;
  const edita_almacen = 20;
  trackUserAction(edita_almacen);
  try {
    const response = await axios.put(url, warehouse, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message || "Error al editar almacén.",
    };
  }
};

export const editSalesWarehouse = async (
  IdWarehouse: number
): Promise<EditWarehouse> => {
  const url = `${baseUrl}/Warehouses/${IdWarehouse}/Sales?IdWarehouse=${IdWarehouse}`;
  const edita_almacen_ventas = 22;
  trackUserAction(edita_almacen_ventas);
  try {
    const response = await axios.put(url, null, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message || "Error al editar almacén.",
    };
  }
};
