import { AdaptedCategoryResponse } from "@Models/interfaces/AdaptedCategoryResponse.interface";
import { Category } from "@Models/interfaces/productsInterfaces/categoryInterface/category.interface";
import { getCategories } from "@Services/products/categories/getCategories.services";

export const getCategoriesAdapter = async (
  setStateResponse,
  setErrorView,
  id
): Promise<AdaptedCategoryResponse> => {
  const categories = await getCategories();
  if (categories.isSuccess) {
    const categoriesAdapt: Category[] = categories.data.map(
      (category: Category) => {
        const categoryAdapt: Category = {
          categoryId: category.categoryId,
          categoryName: category.categoryName,
        };
        return categoryAdapt;
      }
    );
    return {
      isSuccess: true,
      status: categories.status,
      categories: categoriesAdapt,
      error: null,
    };
  } else {
    if (!id) {
      setErrorView((prev) => ({ ...prev, errorCategory: true }));
    }
    setStateResponse({
      message: categories.error,
      status: categories.status,
    });
    return {
      isSuccess: false,
      status: categories.status,
      categories: [],
      error: categories.error,
    };
  }
};
