import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export interface AddCategoryProduct {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const addCategoryProduct = async (
  idLocalProduct: number,
  idCategory: number,
  idProduct: number
): Promise<AddCategoryProduct> => {
  const url = `${baseUrl}/LocalProducts/${idLocalProduct}/categoryProduct`;
  const obj = {
    idCategory: idCategory,
    ProductId: idProduct,
  };
  try {
    const response = await axios.post(url, obj, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "No se pudo agregar la categoría al producto.",
    };
  }
};
