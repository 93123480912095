// @ts-ignore
import { MagnifyingGlassIcon } from "@Models/icons/icons";
import style from "./SearchInput.module.css";
import { set } from "date-fns";

const SearchInput: any = ({
  onClick,
  setSearchInput,
  searchInput,
  placeholder,
  isSearchAvailable,
  formTypeInput,
  refProp,
  markedBrand,
  setMarkedBrand,
  error,
}) => {
  const changeInput: any = (e: any) =>
    setSearchInput(e.target.value.toUpperCase());
  const handleClick = () => {
    if (setMarkedBrand) setMarkedBrand(""); // Establecer markedBrand a ""
    if (onClick) {
      onClick(); // Llamar a la función onClick si está definida
    }
  };

  const styleStatus = () => {
    if (error) {
      return { border: "1px solid red" };
    }
    if (!isSearchAvailable) {
      return {
        background: "#EFEFEF4D",
        border: "0.0625rem solid #dadada",
      };
    } else return {};
  };
  return (
    <div className={style.searchBar__article}>
      <img
        src={MagnifyingGlassIcon}
        alt="Lupa que hace referencia al buscador"
        className={style.searchBar__icon}
      />
      <input
        style={styleStatus()}
        onFocus={handleClick}
        onChange={changeInput}
        className={
          formTypeInput ? style.searchBar__inputForm : style.searchBar__input
        }
        type="text"
        placeholder={markedBrand ? "" : placeholder}
        value={searchInput}
        disabled={!isSearchAvailable}
        ref={refProp}
      />
      {!searchInput && markedBrand && (
        <div className={style.searchBar__brand}>
          <span>{markedBrand}</span>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
