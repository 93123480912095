import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export interface RequestBodyEditProductData {
  idProduct: number;
  name: string;
  idBrand: number;
  idSubBrand: number;
  price: number;
  freeProduct: number;
}

interface PutProductData {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

export const editProductData = async (
  data: RequestBodyEditProductData
): Promise<PutProductData> => {
  const url = `${baseUrl}/LocalProducts/ProductData`;
  try {
    const response = await axios.put(url, data, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al editar los datos del producto, intente nuevamente.",
    };
  }
};
