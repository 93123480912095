import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

interface TransferProducts {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export interface RequestTransferProducts {
  transferProducts: {
    idLocalProductToTransfer: number;
    idProduct: number;
    idReason: number;
    amountTransfer: number;
    idDestinationWarehouse: number;
    idActualWarehouse: number;
  }[];
}

export const transferProducts = async (
  obj: RequestTransferProducts
): Promise<TransferProducts> => {
  const url = `${baseUrl}/LocalProducts/TransferProducts`;
  const transfiere_unidades = 28;
  trackUserAction(transfiere_unidades);
  try {
    const response = await axios.post(url, obj, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al transferir unidades, intente nuevamente.",
    };
  }
};
