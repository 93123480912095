export const filterConfig = {
  order: {
    title: "Ordenar",
    type: "radio",
    allOptionsAvaliable: false,
    options: ["Orden predeterminado", "Con menor stock", "Con mayor stock"],
  },
  productsType: {
    title: "Ver por productos",
    type: "checkbox",
    allOptionsAvaliable: true,
    options: ["En stock", "Agotados"],
  },
  productsCategories: {
    title: "Categoria del producto",
    type: "checkbox",
    allOptionsAvaliable: true,
    options: ["Cabello", "Higiene", "Pies", "Desodorante", "Manos"],
  },
} as const;
