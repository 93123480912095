import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

interface DeleteProducts {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

export const deleteProducts = async (
  localProductId: number,
  productId: number
): Promise<DeleteProducts> => {
  const url = `${baseUrl}/LocalProducts?LocalProductId=${localProductId}&ProductId=${productId}`;
  const elimina_producto = 25;
  trackUserAction(elimina_producto);
  try {
    const products = await axios.delete(url, headerAuthorization);
    return {
      isSuccess: true,
      status: products.status,
      data: products.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error: error?.response?.data?.message,
    };
  }
};
