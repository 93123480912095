import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { deleteProducts } from "@Services/products/products/deleteProducts.services";

export const deleteProductsAdapter = async (
  localProductId: number,
  productId: number
): Promise<ResponseToRequest> => {
  const response = await deleteProducts(localProductId, productId);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
