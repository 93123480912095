import { postSubBrand } from "@Services/products/subBrands/postSubBrand.services";

export const postSubBrandAdapter = async (props, setStateResponse) => {
  setStateResponse({
    message: "Creando submarca...",
    status: "pending",
  });
  const response = await postSubBrand(props);

  if (response.isSuccess) {
    setStateResponse({
      message: "Submarca creada con éxito.",
      status: response.status,
    });
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  } else {
    setStateResponse({
      message: response.error,
      status: response.status,
    });
    return {
      isSuccess: false,
      status: response.status,
      data: [],
      error: response.error,
    };
  }
};
