// @ts-ignore
import style from "./UnitaryTransferView.module.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom/dist";
import useWarehouses from "@Hooks/UseWarehouses";
import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import useProductInfo from "@Hooks/useProduct";
import { HeadBar } from "../../resources";
import SuccessMessage from "@Components/SuccessMessage/SuccessMessage";
import Table from "@Components/Table/Table";
import { SlideNotification, CriticalErrorPage } from "../../resources/index";
import { transferProductsAdapter } from "@Adapters/productsAdapters/products/transferProducts.adapter";
import Loader from "@Components/Loader/Loader";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";

const UnitaryTransferView = () => {
  const [transferTotal, setTransferTotal] = useState(0);
  const [successMsg, setSuccessMsg] = useState(false);
  const [nameWarehouseMeta, setNameWarehouseMeta] = useState("");
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const [loading, setIsLoading] = useState(false);
  const { warehousesList } = useWarehouses(setStateResponse, setIsLoading);
  const { id } = useParams();
  const { almacenIda } = useParams();
  const { almacenMeta } = useParams();
  const [errorView, setErrorView] = useState({
    errorProduct: false,
  });
  const { product, isLoading } = useProductInfo(
    id,
    setErrorView,
    setStateResponse
  );
  const [warehouseName, setWarehouseName] = useState<string>("");
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();

  const postTransfer = async () => {
    if (loading) return;
    setIsLoading(true);
    let response = await transferProductsAdapter(
      product,
      transferTotal,
      almacenMeta,
      almacenIda
    );
    if (response.isSuccess) {
      setSuccessMsg(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setStateResponse({
        message: response.error,
        status: response.status,
      });
    }
  };

  useEffect(() => {
    const fetchWarehouse = async () => {
      const warehouse = await selectedWarehouseGlobal();
      setWarehouseName(warehouse.warehouseName);
    };
    fetchWarehouse();
  }, []);

  React.useEffect(() => {
    const filtered = warehousesList?.filter(
      (warehouse) => warehouse?.warehouseId === parseInt(almacenMeta)
    );
    setNameWarehouseMeta(filtered[0]?.warehouseName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehousesList]);

  const handleClick = () => {
    if (successMsg) {
      setSuccessMsg(false);
      setTransferTotal(0);
      return;
    }
    return postTransfer();
  };
  const previousAction = () => {
    return navigate(`/`);
  };
  const textBttn = !successMsg ? "Continuar" : "Transferir más unidades";
  const headerProps = {
    previousAction: () => previousAction(),
    warehouseProps: {
      title: `Transferir unidades - Desde ${warehouseName} a ${
        nameWarehouseMeta || "Cargando..."
      }`,
    },
    showButton: screenWidth >= 900,
    buttonProps: {
      textBttn: textBttn,
      handleClick: () => handleClick(),
      isDisabled: loading || isNaN(transferTotal) || transferTotal < 1,
      isPrimary: true,
      isLoading: loading,
      style: { maxHeight: "40px" },
    },
  };

  if (isLoading) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <HeadBar {...headerProps} />
        <Loader />
      </div>
    );
  }
  if (errorView.errorProduct) {
    return (
      <div id="viuti-front-mainContent">
        <HeadBar {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }
  return (
    <div
      id="viuti-front-mainContent"
      style={{
        overflow: "auto",
      }}
    >
      <HeadBar {...headerProps} />

      {successMsg ? (
        <div className={style.success}>
          <SuccessMessage
            actionMsg={`Transferiste ${transferTotal} unidad/es de producto “${product?.productName}” de ${warehouseName} a ${nameWarehouseMeta}.`}
            actionTitle={"Transferencia de productos exitosa"}
          />
        </div>
      ) : (
        <div className={style.containerBot}>
          <Table
            income={false}
            withdrawal={false}
            transfer={true}
            product={product}
            setTransferTotal={setTransferTotal}
            functionButton={postTransfer}
            loading={loading}
            setStateResponse={setStateResponse}
          />
        </div>
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default UnitaryTransferView;
