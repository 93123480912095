export const openEdit = (
  idLocalProduct: number,
  setSelectProduct: any,
  setShowWarehouse: any,
  showWarehouse: any
) => {
  if (showWarehouse) {
    setShowWarehouse(false);
  }
  setSelectProduct(idLocalProduct);
};
