import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

interface CreateProducts {
  isSuccess: boolean;
  status: number;
  data: any | null;
  error: string | null;
}

export interface RequestBodyCreateProducts {
  warehouseId: number | any;
  name: string | any;
  code: string | any;
  brand: number | any;
  subBrand: number | any;
  categoriesIds: [number] | any;
  price: number | any;
  freeProduct: number | any;
  stock: number | any;
  emergencyStock: number | any;
}

export const createProduct = async (
  product: RequestBodyCreateProducts
): Promise<CreateProducts> => {
  const url = `${baseUrl}/LocalProducts`;
  const crea_producto = 23;
  trackUserAction(crea_producto);
  try {
    const response = await axios.post(url, product, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al crear el producto, intente de nuevo.",
    };
  }
};
