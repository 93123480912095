import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export interface RequestBodyEditProductStock {
  idProduct: number;
  emergencyStock: number;
  idWareHouse: number;
  stock: number;
}

interface EditProductStock {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const editProductStock = async (
  data: RequestBodyEditProductStock,
  productLocalId: number
): Promise<EditProductStock> => {
  const url = `${baseUrl}/LocalProducts/${productLocalId}/Stocks`;
  try {
    const response = await axios.put(url, data, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al editar el stock del producto, intente nuevamente.",
    };
  }
};
