// @ts-ignore
import style from "./ModalOptionsWarehouse.module.css";
import { marginLeftModalEdit } from "@Pages/MainView/functions/marginLeftModalEdit";
import arrow_float from "../../../../../../../assets/svgs/arrow_float.svg";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { ModalAcceptCancel, Icon } from "../../../../../../../resources";
import { AltArrowDownIcon, AltArrowRightIcon } from "@Models/icons/icons";
import { useEffect, useRef } from "react";

const ModalOptionsWarehouse = ({
  warehousesList,
  wareh,
  editName,
  setWarehouseToEdit,
  setOpenEditModal,
  setShowWarehouse,
  setSelectedWarehouseId,
  setWarehouseName,
  resetWarehouses,
  setResetWarehouses,
  saleWarehouse,
  deleteWarehouseSelected,
  openCloseSelect,
  closeOptions,
  showWarehouse,
  showEdit,
  selectedWarehouseId,
  buttonDelete,
  isHovered,
  setIsHovered,
  setStateResponse,
  setWarehousesList,
  resetProducts,
  setResetProducts,
  setSelectWarehouse,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Si se hace clic fuera del modal, cerrar el modal
        closeOptions(); // Llama a la función para cerrar el modal
      }
    };

    // Agregar el event listener al documento cuando el modal está visible
    if (showEdit && selectedWarehouseId === wareh?.warehouseId) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remover el event listener cuando el componente se desmonta o el modal ya no está visible
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeOptions, selectedWarehouseId, showEdit, wareh?.warehouseId]);

  const screenWidth = useScreenWidth();
  if (screenWidth < 900) {
    return (
      selectedWarehouseId === wareh?.warehouseId &&
      showEdit && (
        <ModalAcceptCancel
          title={"Opciones de almacén"}
          actionButtonClose={() => closeOptions()}
          showButtons={{
            showButtonClose: true,
            showButtonOne: false,
            showButtonTwo: false,
          }}
        >
          <div className={style.containerOpcionesAlmacenModal}>
            <section className={style.movement_info_container}>
              <div className={style.movement_info}>
                <h3>{wareh?.warehouseName}</h3>
                <span className={style.movement_concept}>
                  {wareh?.sales ? "Almacén de venta" : ""}
                </span>
              </div>
            </section>

            <button
              onClick={() => {
                editName(
                  wareh,
                  setWarehouseToEdit,
                  setOpenEditModal,
                  closeOptions
                );
              }}
            >
              <p>Editar nombre</p>
            </button>
            {/* {wareh?.sales == true && (
              <button
                style={{
                  color: "#8C8C8C",
                  cursor: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #e0e0e0",
                  borderRadius: "5px",
                  padding: "10px",
                  transition: "all 150ms ease-in-out",
                }}
                id={style.hv}
              >
                <p>Establecer como almacén de venta</p>
              </button>
            )} */}
            {/* {!wareh?.sales && (
              <button
                onClick={() =>
                  saleWarehouse(
                    wareh,
                    setShowWarehouse,
                    setSelectedWarehouseId,
                    setWarehouseName,
                    resetWarehouses,
                    setResetWarehouses,
                    setStateResponse
                  )
                }
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #e0e0e0",
                  borderRadius: "5px",
                  padding: "10px",
                  transition: "all 150ms ease-in-out",
                }}
              >
                <p>Establecer como almacén de venta</p>
              </button>
            )} */}

            {(!buttonDelete || warehousesList.length === 1) &&
              wareh?.sales !== 1 && (
                <button
                  style={{
                    color: "#8C8C8C",
                    cursor: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    padding: "10px",
                    transition: "all 150ms ease-in-out",
                  }}
                  id={style.hv}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <p>Eliminar</p>
                  {isHovered && warehousesList.length > 1 && (
                    <div>
                      <p
                        className={style.msgDeleteStore}
                        onMouseLeave={() => setIsHovered(false)}
                        onMouseEnter={() => setIsHovered(true)}
                      >
                        Para eliminar un almacén, este debe tener 0 productos.
                      </p>
                    </div>
                  )}
                </button>
              )}
            {isHovered && warehousesList.length === 1 && wareh?.sales !== 1 && (
              <img
                src={arrow_float}
                alt="Flecha"
                className={style.arrowFloat}
              />
            )}
            {isHovered && warehousesList.length === 1 && wareh?.sales !== 1 && (
              <div>
                <p
                  className={style.msgDeleteStore}
                  onMouseLeave={() => setIsHovered(false)}
                  onMouseEnter={() => setIsHovered(true)}
                >
                  Al menos debe haber un almacén.
                </p>
              </div>
            )}

            {buttonDelete &&
              warehousesList.length > 1 &&
              wareh?.sales !== 1 && (
                <button
                  onClick={() =>
                    deleteWarehouseSelected(
                      wareh,
                      resetWarehouses,
                      setShowWarehouse,
                      setSelectedWarehouseId,
                      setWarehouseName,
                      warehousesList,
                      setResetWarehouses,
                      openCloseSelect,
                      closeOptions,
                      showWarehouse,
                      setStateResponse,
                      setWarehousesList,
                      resetProducts,
                      setResetProducts,
                      setSelectWarehouse
                    )
                  }
                  style={{
                    color: "var(--carpenter-core-color-light-bad, #BA1A1A)",
                  }}
                  onMouseEnter={() => setIsHovered(false)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <p>Eliminar</p>
                </button>
              )}
          </div>
        </ModalAcceptCancel>
      )
    );
  }
  // quiero que el modal se cierre cuando se da click fuera de el
  return (
    selectedWarehouseId === wareh?.warehouseId &&
    showEdit && (
      <div
        ref={modalRef}
        className={style.editModal}
        style={{
          position: "absolute",
          top: 50,
          left: 230,
          zIndex: 100,
        }}
      >
        <button
          onClick={() =>
            editName(wareh, setWarehouseToEdit, setOpenEditModal, closeOptions)
          }
        >
          Editar nombre
        </button>
        {/* {wareh?.sales == true && (
          <button style={{ color: "#8C8C8C", cursor: "auto" }} id={style.hv}>
            Establecer como almacén de venta
          </button>
        )} */}
        {/* {!wareh?.sales && (
          <button
            onClick={() =>
              saleWarehouse(
                wareh,
                setShowWarehouse,
                setSelectedWarehouseId,
                setWarehouseName,
                resetWarehouses,
                setResetWarehouses,
                setStateResponse
              )
            }
          >
            Establecer como almacén de venta
          </button>
        )} */}
        {/*Este boton solo aparece cuando en el almacén no hay productos o cuando hay 1 sola almacen*/}
        {(!buttonDelete || warehousesList.length === 1) &&
          wareh?.sales !== 1 && (
            <button
              style={{ color: "#8C8C8C", cursor: "auto" }}
              id={style.hv}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Eliminar
              {isHovered && warehousesList.length > 1 && wareh?.sales !== 1 && (
                <div>
                  <p
                    className={style.msgDeleteStore}
                    onMouseLeave={() => setIsHovered(false)}
                    onMouseEnter={() => setIsHovered(true)}
                  >
                    Para eliminar un almacén, este debe tener 0 productos.
                  </p>
                </div>
              )}
            </button>
          )}
        {isHovered && warehousesList.length === 1 && wareh?.sales !== 1 && (
          <div>
            <p
              className={style.msgDeleteStore}
              onMouseLeave={() => setIsHovered(false)}
              onMouseEnter={() => setIsHovered(true)}
            >
              Al menos debe haber un almacén.
            </p>
          </div>
        )}

        {buttonDelete && warehousesList.length > 1 && wareh?.sales !== 1 && (
          <button
            onClick={() =>
              deleteWarehouseSelected(
                wareh,
                resetWarehouses,
                setShowWarehouse,
                setSelectedWarehouseId,
                setWarehouseName,
                warehousesList,
                setResetWarehouses,
                openCloseSelect,
                closeOptions,
                showWarehouse,
                setStateResponse,
                setWarehousesList,
                resetProducts,
                setResetProducts,
                setSelectWarehouse
              )
            }
            style={{
              color: "var(--carpenter-core-color-light-bad, #BA1A1A)",
            }}
            onMouseEnter={() => setIsHovered(false)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Eliminar
          </button>
        )}
      </div>
    )
  );
};

export default ModalOptionsWarehouse;
