// @ts-ignore
import style from "./ButtonCreateWarehouse.module.css";

const ButtonCreateWarehouse = ({
  searchQuery,
  setShowWarehouse,
  setSearchQuery,
  warehousesList,
  setSelectedWarehouseId,
  setWarehouseName,
  resetWarehouses,
  setResetWarehouses,
  handleKeyPress,
  setStateResponse,
  setWarehousesList,
}) => {
  return (
    <button
      className={style.createNewWarehouse}
      onClick={(e) =>
        handleKeyPress(
          e,
          searchQuery,
          setShowWarehouse,
          setSearchQuery,
          warehousesList,
          setSelectedWarehouseId,
          setWarehouseName,
          resetWarehouses,
          setResetWarehouses,
          setStateResponse,
          setWarehousesList
        )
      }
      onKeyDown={(e) =>
        handleKeyPress(
          e,
          searchQuery,
          setShowWarehouse,
          setSearchQuery,
          warehousesList,
          setSelectedWarehouseId,
          setWarehouseName,
          resetWarehouses,
          setResetWarehouses,
          setStateResponse,
          setWarehousesList
        )
      }
    >
      <p>Pulsa Intro para crear un nuevo almacén.</p>
    </button>
  );
};

export default ButtonCreateWarehouse;
