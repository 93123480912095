import { getValidateSupplierByDocument_service } from "@Services/supplier/getValidateSupplierByDocument.service";

export const getValidateSupplierByDocument_adapter = async (
  idTypeDocument,
  documentNumber
) => {
  const response = await getValidateSupplierByDocument_service(
    idTypeDocument,
    documentNumber
  );
  if (!response.isSuccess) {
    return {
      ...response,
      data: null,
    };
  }

  const adaptData = {
    supplierExist: response.data.supplierExist,
    supplierData: response.data.supplierData,
  };

  return {
    ...response,
    data: adaptData,
  };
};
