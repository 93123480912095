import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface GetReasonWithdrawal {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const getReasonWithdrawal = async (): Promise<GetReasonWithdrawal> => {
  const url = `${baseUrl}/ReasonsWithdrawal`;
  try {
    const reasonsWithdrawal = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: reasonsWithdrawal.status,
      data: reasonsWithdrawal.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message ||
        "Error al obtener los motivos de retiro, intente nuevamente.",
    };
  }
};
