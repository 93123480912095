import React, { useEffect } from "react";
// @ts-ignore
import styled from "../../SearchBar.module.css";
import { SearchResultsSimpleDataProps } from "@Models/interfaces/SearchResultsSimpleData.interface";
import { TripleDotsIcon } from "@Models/icons/icons";
import { Icon, IconWithOptions } from "../../../../resources";

const filterSearchOnlyStrings = ({
  dataSearchable,
  input,
}: {
  dataSearchable: string[];
  input: string;
}): string[] => {
  if (!Array.isArray(dataSearchable)) {
    throw new Error("dataSearchable must be an array");
  }

  const result = dataSearchable.filter((item) => {
    return item.toLowerCase().includes(input.toLowerCase());
  });

  return result;
};

const SearchResultsSimpleData: React.FC<SearchResultsSimpleDataProps> = ({
  searchInput,
  dataSearchable,
  handleSubmit,
  returnNewResults,
  inputRef,
  handleButtonClickDelete,
  markedBrand,
  markedCategory,
  isCategory,
  messageCreateItem,
}) => {
  const resultsArr = filterSearchOnlyStrings({
    dataSearchable: dataSearchable,
    input: searchInput,
  }).slice(0, 3);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        inputRef.current?.blur();
        handleSubmit(searchInput);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleSubmit, searchInput, inputRef]);

  return (
    <section
      className={`${styled.resultsContainer} ${
        searchInput !== "" ? styled.animation : ""
      }`}
      style={{ border: searchInput !== "" ? "1px solid #dadada" : "none" }}
    >
      {resultsArr.length === 0 || searchInput === "" ? (
        <></>
      ) : (
        resultsArr.map((result, index) => {
          return (
            <div
              key={result + index}
              className={styled.resultSimpleDataContainer}
            >
              {renderResult(
                result,
                handleSubmit,
                handleButtonClickDelete,
                markedBrand,
                markedCategory,
                isCategory
              )}
            </div>
          );
        })
      )}
      {resultsArr.length === 0 && !!searchInput.length && (
        <div className={styled.resultSimpleDataContainer}>
          <p className={styled.noResults}>No se han encontrado resultados.</p>
        </div>
      )}
      {returnNewResults && !!searchInput.length && (
        <button
          className={`${styled.resultSimpleDataButton} ${styled.buttonSimpleData}`}
          onClick={() => handleSubmit(searchInput)}
        >
          <p style={{ fontSize: 12 }}>
            <i>{messageCreateItem} </i>
          </p>
        </button>
      )}
    </section>
  );
};

export default SearchResultsSimpleData;

const renderResult = (
  result: string,
  handleSubmit: (result: string) => void,
  handleButtonClickDelete: any,
  markedBrand: string,
  markedCategory: any,
  isCategory: boolean
) => {
  if (isCategory) {
    if (markedCategory.includes(result)) {
      return (
        <button
          className={styled.resultSimpleData}
          onClick={() => handleSubmit(result)}
        >
          <p>{result}</p>
          <p style={{ color: "#45348e" }}>Seleccionada</p>
        </button>
      );
    } else {
      return (
        <button
          className={styled.resultSimpleData}
          onClick={() => handleSubmit(result)}
        >
          <p>{result}</p>
          <IconWithOptions
            icon={<Icon path={TripleDotsIcon} size={20} alt={"Opciones"} />}
            actions={[
              {
                label: "Eliminar",
                handler: (event) => handleButtonClickDelete(event, result),
              },
            ]}
          />
        </button>
      );
    }
  } else
    return (
      <button
        className={styled.resultSimpleData}
        onClick={() => handleSubmit(result)}
      >
        <p>{result}</p>
        {markedBrand !== result ? (
          <IconWithOptions
            icon={<Icon path={TripleDotsIcon} size={20} alt={"Opciones"} />}
            actions={[
              {
                label: "Eliminar",
                handler: (event) => handleButtonClickDelete(event, result),
              },
            ]}
          />
        ) : (
          <p style={{ color: "#45348e" }}>Seleccionada</p>
        )}
      </button>
    );
};
