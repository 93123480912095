// Vars
const basePath = "https://app.zymple.io/recursos/icons";

/* BRANDS */
export const ViutiIcon = `${basePath}/brands/viuti.svg`;

/* CASH */
export const CashIcon = `${basePath}/cash/cash.svg`;
export const CashRegisterStrokeIcon = `${basePath}/cash/cashRegisterStroke.svg`;
export const CoinsIcon = `${basePath}/cash/coins.svg`;
export const CashCheckIcon = `${basePath}/cash/cashCheck.svg`;

/* CLIENTS */
export const BirthdayCakeIcon = `${basePath}/clients/birthdayCake.svg`;
export const LetterIcon = `${basePath}/clients/letter.svg`;
export const PersonalDocumentIcon = `${basePath}/clients/personalDocument.svg`;
export const PhoneIcon = `${basePath}/clients/phone.svg`;
export const ThreePeopleIcon = `${basePath}/clients/threePeople.svg`;
export const WhatsAppIcon = `${basePath}/clients/whatsApp.svg`;

/* CONFIG MENU */
export const CalendarIcon = `${basePath}/configMenu/calendar.svg`;
export const CodeBarIcon = `${basePath}/configMenu/codeBar.svg`;
export const PromotionIcon = `${basePath}/configMenu/promotion.svg`;
export const ScissorsIcon = `${basePath}/configMenu/scissors.svg`;
export const TaxIcon = `${basePath}/configMenu/tax.svg`;

/*LOADER */
export const LoadingPulseIcon = `${basePath}/loader/loadingPulse.svg`;

/* LOUNGE */
export const SiteIcon = `${basePath}/lounge/store.svg`;

/* ORIENTATION */
export const AltArrowDownIcon = `${basePath}/orientation/altArrowDown.svg`;
export const AltArrowLeftIcon = `${basePath}/orientation/altArrowLeft.svg`;
export const AltArrowRightIcon = `${basePath}/orientation/altArrowRight.svg`;
export const AltDoubleArrowLeftIcon = `${basePath}/orientation/altDoubleArrowLeft.svg`;
export const AltDoubleArrowRightIcon = `${basePath}/orientation/altDoubleArrowRight.svg`;
export const ArrowLeftIcon = `${basePath}/orientation/arrowLeft.svg`;
export const CommonArrowRightIcon = `${basePath}/orientation/commonArrowRight.svg`;
export const RightGreenArrowIcon = `${basePath}/orientation/rightGreenArrow.svg`;

/* PRODUCTS */
export const BoxIcon = `${basePath}/products/box.svg`;
export const DiscountIcon = `${basePath}/products/discount.svg`;
export const FindBoxIcon = `${basePath}/products/findBox.svg`;
export const GiftIcon = `${basePath}/products/gift.svg`;
export const OpenBoxIcon = `${basePath}/products/openBox.svg`;
export const WhiteGiftIcon = `${basePath}/products/whiteGift.svg`;

/* SEARCH */
export const FilterIcon = `${basePath}/search/filter.svg`;
export const MagnifyingGlassIcon = `${basePath}/search/magnifyingGlass.svg`;

/*STATUS */
export const ActiveCalendarIcon = `${basePath}/status/activeCalendar.svg`;
export const CircleAlertIcon = `${basePath}/status/circleAlert.svg`;
export const DangerAlertTriangleIcon = `${basePath}/status/dangerAlertTriangle.svg`;
export const FillPlayIcon = `${basePath}/status/fillPlay.svg`;
export const GreenPointIcon = `${basePath}/status/greenPoint.svg`;
export const InactiveCalendarIcon = `${basePath}/status/inactiveCalendar.svg`;
export const MinusWithFrameIcon = `${basePath}/status/minusWithFrame.svg`;
export const OperationSuccessIcon = `${basePath}/status/operationSuccess.svg`;
export const PersonCheckIcon = `${basePath}/status/personCheck.svg`;
export const PlusIcon = `${basePath}/status/plus.svg`;
export const QueuedCalendarIcon = `${basePath}/status/queuedCalendar.svg`;
export const ValidatedIcon = `${basePath}/status/validated.svg`;

/* SYMBOLS */
export const WarningTriangleIcon = `${basePath}/symbols/warningTriangle.svg`;

/* SYSTEM */
export const CircleUserIcon = `${basePath}/system/circleUser.svg`;
export const CameraIcon = `${basePath}/system/camera.svg`;
export const CheckIcon = `${basePath}/system/check.svg`;
export const ClockIcon = `${basePath}/system/clock.svg`;
export const ComputerIcon = `${basePath}/system/computer.svg`;
export const CredentialCardIcon = `${basePath}/system/credentialCard.svg`;
export const DocumentIcon = `${basePath}/system/document.svg`;
export const ScissorsCommonIcon = `${basePath}/system/scissors.svg`;
export const GoalIcon = `${basePath}/system/goal.svg`;
export const TrophyIcon = `${basePath}/system/trophy.svg`;

/* USER ACTIONS */
export const AddIcon = `${basePath}/userActions/add.svg`;
export const AddCalendarIcon = `${basePath}/userActions/addCalendar.svg`;
export const AddCommandIcon = `${basePath}/userActions/addCommand.svg`;
export const AddLayerIcon = `${basePath}/userActions/addLayer.svg`;
export const ArchiveIcon = `${basePath}/userActions/archive.svg`;
export const CheckedIcon = `${basePath}/userActions/checked.svg`;
export const CurveArrowIcon = `${basePath}/userActions/curveArrow.svg`;
export const DeleteIcon = `${basePath}/userActions/delete.svg`;
export const DeleteIconTwo = `${basePath}/userActions/trashBin.svg`;
export const DownloadIcon = `${basePath}/userActions/download.svg`;
export const EditIcon = `${basePath}/userActions/edit.svg`;
export const FilteredOutIcon = `${basePath}/userActions/filteredOut.svg`;
export const FindIcon = `${basePath}/userActions/find.svg`;
export const UserInfoIcon = `${basePath}/userActions/info.svg`;
export const LeaveIcon = `${basePath}/userActions/leave.svg`;
export const MinusIcon = `${basePath}/userActions/minus.svg`;
export const PencilWithFrameIcon = `${basePath}/userActions/pencilWithFrame.svg`;
export const RedirectIcon = `${basePath}/userActions/redirect.svg`;
export const TrashBinIcon = `${basePath}/userActions/trashBin.svg`;
export const TimesIcon = `${basePath}/userActions/times.svg`;
export const TripleDotsIcon = `${basePath}/userActions/tripleDots.svg`;
export const UncheckedIcon = `${basePath}/userActions/unchecked.svg`;

/* VISIBILITY */
export const EyeIcon = `${basePath}/visibility/eye.svg`;
export const EyeClosedIcon = `${basePath}/visibility/eyeClosed.svg`;

/* ICONS */
export const BellIcon = `${basePath}/bell.svg`;
export const BurgerIcon = `${basePath}/burger.svg`;
export const PeopleIcon = `${basePath}/people.svg`;
export const QuestionMarkIcon = `${basePath}/questionMark.svg`;
export const TripleLeafStackIcon = `${basePath}/TripleLeafStack.svg`;

/* FLAGS */
export const PeruFlagIcon = `${basePath}/flags/peruFlag.svg`;
