import { AxiosResponse } from "axios";
import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { postWarehousesAdapter } from "@Adapters/warehousesAdapters/postWarehouses.adapter";
import { UPSERT_WAREHOUSES_UUID } from "@Models/const/securityAccess";
// @ts-ignore
import { getUserPermissions, validateModuleAccess } from "@viuti/recursos";
import { setSessionWarehouse } from "@Utilities/warehouseUtils";

export const handleKeyPress = async (
  e: any,
  searchQuery: string,
  setShowWarehouse: React.Dispatch<React.SetStateAction<boolean>>,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>,
  warehouses: Warehouse[],
  setSelectedWarehouse: React.Dispatch<React.SetStateAction<number>>,
  setWarehouse: React.Dispatch<React.SetStateAction<string>>,
  resetWarehouses: boolean,
  setResetWarehouses: React.Dispatch<React.SetStateAction<boolean>>,
  setStateResponse: any,
  setWarehousesList,
  setSelectWarehouse
): Promise<AxiosResponse | void> => {
  const availableWarehouses = getUserPermissions().warehousesLimitCount;
  const totalWarehouses = warehouses.length;

  const exceededWarehouses =
    availableWarehouses - totalWarehouses > 0 ? false : true;

  if (e.key === "Enter") {
    if (exceededWarehouses) return;
    if (!validateModuleAccess(UPSERT_WAREHOUSES_UUID))
      return setSearchQuery("");
    if (searchQuery !== "") {
      // validate minimum length of the warehouse name
      if (searchQuery.length < 4) {
        setStateResponse({
          message: `El almacén que deseas crear debe tener al menos 4 caracteres`,
          status: 400,
        });
        return;
      }
      // validate duplicate warehouse name
      const isDuplicateName = warehouses.some(
        (ware) => ware.warehouseName.toLowerCase() === searchQuery.toLowerCase()
      );
      if (isDuplicateName) {
        setStateResponse({
          message: `El almacén que deseas crear ya existe.`,
          status: 400,
        });
        return;
      }
      setStateResponse({
        message: `Creando almacén "${searchQuery}"...`,
        status: "pending",
      });
      let res = await postWarehousesAdapter(searchQuery);
      if (res.isSuccess) {
        setShowWarehouse(false);
        setSearchQuery("");
        setSelectWarehouse({
          // @ts-ignore
          warehouseId: res.data.createdWarehouseId,
          warehouseName: res.data.createdWarehouseName,
          sales: 0,
          warehouseValue: 0,
        });
        setSelectedWarehouse(res.data.createdWarehouseId);
        setWarehouse(res.data.createdWarehouseName);
        setSessionWarehouse({
          // @ts-ignore
          warehouseId: res.data.createdWarehouseId,
          warehouseName: res.data.createdWarehouseName,
          sales: 0,
          warehouseValue: 0,
        });
        setWarehousesList((prev) => [
          ...prev,
          {
            warehouseId: res.data.createdWarehouseId,
            warehouseName: res.data.createdWarehouseName,
            sales: 0,
            warehouseValue: 0,
          },
        ]);
        setStateResponse({
          message: `El almacén "${res.data.createdWarehouseName}" se ha creado correctamente`,
          status: res.status,
        });
        setResetWarehouses(!resetWarehouses);
      } else {
        setStateResponse({
          message: `El almacén "${searchQuery}" no se ha podido crear, intente nuevamente`,
          status: res.status,
        });
      }
    } else {
      setStateResponse({
        message: `El nombre del almacén que deseas crear no puede estar vacío`,
        status: 400,
      });
      return;
    }
  }
};
