// @ts-ignore
import { productTableBuilder } from "@Pages/Inventory/builder/productBuilder";
import { IProductInventory } from "@Hooks/useWarehouseInventoryStore";

export const inventoryProductRowsBuilder = (
  setStateResponse,
  products: IProductInventory[]
) => {
  const tableRows = products.map((product) => {
    const productBuilderParams = {
      name: product.name,
      brand: product.brand.name,
      subBrand: product.line.name,
      code: product.code,
    };

    return {
      Producto: productTableBuilder(setStateResponse, productBuilderParams),
      "Stock anterior": product.stock,
      "Stock contado": product.counter,
    };
  });

  return tableRows;
};
