import { downloadMasiveProductsSpreadsheet_service } from "@Services/products/products/downloadMasiveProductsSpreadsheet.service";

export const downloadMasiveProducts = async () => {
  const response = await downloadMasiveProductsSpreadsheet_service();
  if (response.isSuccess) {
    const byteCharacters = atob(response.data.base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Crear un blob a partir del array de bytes
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Crear un enlace temporal y hacer clic en él para descargar el archivo
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Plantilla_productos_masivos.xlsx";
    link.click();
  }
  return response;
};
