import { postData, baseUrl } from "@Services/constServices";

export const postValidProductsListInWarehouse_service = async (
  products: string[]
) => {
  const url = `${baseUrl}/Product/ValidProductListInWarehouse`;
  const successMessage = "Almacenes encontrados correctamente";
  const errorMessage =
    "Hubo un error al buscar los almacenes. Por favor, intente nuevamente.";
  return postData(url, products, successMessage, errorMessage);
};
