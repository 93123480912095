import { deleteInventoryProduct_service } from "@Services/inventory/deleteInventoryProduct";

export const deleteInventoryProduct = async (
  warehouseId: number,
  inventoryProductId: number
) => {
  const response = await deleteInventoryProduct_service(
    warehouseId,
    inventoryProductId
  );

  return response;
};
