import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

export interface IResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: any;
}

export const downloadMasiveProductsSpreadsheet_service =
  async (): Promise<IResponse> => {
    const url = `${baseUrl}/LocalProducts/GetTemplateMasive`;
    try {
      const response = await axios.get(url, headerAuthorization);
      return {
        isSuccess: true,
        status: response.status,
        data: response.data.data,
        message: null,
      };
    } catch (error) {
      return {
        isSuccess: false,
        status: error?.response?.status || 500,
        message:
          error?.response?.data?.message ||
          "Ocurrió un error al descargar la plantilla de productos. Por favor, intenta de nuevo.",
        data: null,
      };
    }
  };
