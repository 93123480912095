import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";
import { withdrawalsProducts } from "@Services/products/products/withdrawalsProducts.services";

export const withdrawalsProductsAdapter = async (
  product: Product,
  withdrawalTotal: number,
  reasonId: number
): Promise<ResponseToRequest> => {
  const obj = {
    withdrawProducts: [
      {
        idLocalProduct: product?.productLocalId,
        amount: withdrawalTotal,
        idReason: reasonId,
        idProduct: product?.productId,
        cost: 0,
      },
    ],
  };
  const response = await withdrawalsProducts(obj);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
