import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface GetCategories {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const getCategories = async (): Promise<GetCategories> => {
  const url = `${baseUrl}/ProductCategories/CategoryProducts`;
  try {
    const categories = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: categories.status,
      data: categories.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: null,
      error:
        error?.response?.data?.message || "Error al obtener las categorías.",
    };
  }
};
