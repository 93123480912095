import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { addCategoryProduct } from "@Services/products/categories/addCategoryProduct.services";

export const addCategoryProductAdapter = async (
  idLocalProduct: number,
  idCategory: number,
  idProduct: number,
  setStateResponse
): Promise<ResponseToRequest> => {
  setStateResponse({
    message: "Agregando categoría al producto...",
    status: "pending",
  });
  const response = await addCategoryProduct(
    idLocalProduct,
    idCategory,
    idProduct
  );
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  } else {
    setStateResponse({
      message: response.error,
      status: response.status,
    });
    return {
      isSuccess: false,
      status: response.status,
      data: null,
      error: response.error,
    };
  }
};
