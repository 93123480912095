import { SubBrand } from "@Models/interfaces/productsInterfaces/subBrandInterface/subBrand.interface";
import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface GetSubBrands {
  isSuccess: boolean;
  status: number;
  data: SubBrand[];
  error: string | any;
}

export const getSubBrands = async (
  subBrandId: number
): Promise<GetSubBrands> => {
  if (subBrandId) {
    const url = `${baseUrl}/ProductSubBrands?subBrandId=${subBrandId}`;
    try {
      const brands = await axios.get(url, headerAuthorization);
      return {
        isSuccess: true,
        status: brands.status,
        data: brands.data.data,
        error: null,
      };
    } catch (error) {
      return {
        isSuccess: false,
        status: error?.response?.status || 400,
        data: [],
        error: error?.response?.data?.message || "Error al obtener submarcas.",
      };
    }
  }
};
