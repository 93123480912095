import React, { useState } from "react";

// @ts-ignore
import style from "./JustificationModal.module.css";
import tableStyles from "../../builder/styles/inventoryCountRowsBuilder.module.css";
import {
  ButtonWithoutIcon,
  Icon,
  ModalLayoutWrapper,
  TextAreaInput,
} from "../../../../resources";
import { TimesIcon } from "@Models/icons/icons";
import { productTableBuilder } from "@Pages/Inventory/builder/productBuilder";
import { IProductInventory } from "@Hooks/useWarehouseInventoryStore";

interface IJustificationModalProps {
  visibility: {
    isVisible: boolean;
    setIsVisible: Function;
  };
  onDifferenceChange: Function;
  activeProductDifference: IProductInventory;
  setStateResponse: any;
}

const JustificationModal = ({
  visibility,
  onDifferenceChange,
  activeProductDifference,
  setStateResponse,
}: IJustificationModalProps) => {
  const { isVisible, setIsVisible } = visibility;

  const [justificationMessage, setJustificationMessage] = useState(
    activeProductDifference?.differenceMessage || ""
  );

  const productBuilderParams = {
    name: activeProductDifference?.name,
    brand: activeProductDifference?.brand.name,
    subBrand: activeProductDifference?.line.name,
    code: activeProductDifference?.code,
  };

  const productDifference =
    Number(activeProductDifference?.counter) - activeProductDifference?.stock;

  const isDifferencePositive = productDifference > 0;
  let differenceMessage = "";

  const isSingular = Math.abs(productDifference) === 1;
  let unitsText = "";
  if (isDifferencePositive) {
    unitsText = isSingular ? "sobra" : "sobran";
  } else {
    unitsText = isSingular ? "falta" : "faltan";
  }
  const quantity = isSingular ? "una unidad" : "unidades";
  differenceMessage = `Te ${unitsText} ${quantity} de este producto`;

  return (
    <ModalLayoutWrapper isVisible={isVisible}>
      <div className={style.container}>
        <div className={style.header}>
          <h1 className={`${style.title}`}>Solucionar diferencia</h1>

          <button
            className={style.close__button__container}
            onClick={() => {
              setIsVisible(false);
            }}
            data-testid="close__button"
          >
            <Icon path={TimesIcon} size={14} color={"#B4B4B4"} />
          </button>
        </div>
        <div className={style.body}>
          <section className={style.product__container}>
            {productTableBuilder(setStateResponse, productBuilderParams)}

            <div className={style.counter__container}>
              <span
                className={`${style.difference__container} ${
                  tableStyles.difference__container
                } ${
                  productDifference !== 0 ? tableStyles.difference__show : ""
                }`}
              >
                {isDifferencePositive
                  ? `+${productDifference}`
                  : productDifference}
              </span>
              <span className={style.counter__message}>
                {differenceMessage}
              </span>
            </div>
          </section>
          <TextAreaInput
            label={`¿Por qué ${unitsText} ${quantity} de este producto?`}
            value={justificationMessage}
            // textareaProps
            placeholder=""
            handleChange={(e) => setJustificationMessage(e)}
          />
        </div>
        <ButtonWithoutIcon
          textBttn={"Aceptar"}
          handleClick={() => {
            onDifferenceChange(justificationMessage);
            setJustificationMessage("");
          }}
          isPrimary
          isDisabled={!justificationMessage}
        />
      </div>
    </ModalLayoutWrapper>
  );
};

export default JustificationModal;
