import { deleteSubBrand } from "@Services/products/subBrands/deleteSubBrand.services";

export const deleteSubBrandAdapter = async (
  idSubBrand: number,
  setStateResponse
) => {
  setStateResponse({
    message: `Eliminando submarca...`,
    status: "pending",
  });
  const subBrand = await deleteSubBrand(idSubBrand);
  if (subBrand.isSuccess) {
    setStateResponse({
      message: "Submarca eliminada con éxito.",
      status: 200,
    });
    return {
      isSuccess: true,
      status: subBrand.status,
      data: subBrand.data,
      error: null,
    };
  } else {
    setStateResponse({
      message: subBrand.error,
      status: 400,
    });
    return {
      isSuccess: false,
      status: subBrand.status,
      data: [],
      error: subBrand.error,
    };
  }
};
