import { headerAuthorization, baseUrl } from "@Services/constServices";

import axios from "axios";

interface PostBrand {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const postBrand = async (name: string): Promise<PostBrand> => {
  const url = `${baseUrl}/ProductBrands/CreateBrand`;
  const obj = {
    newBrandName: name,
  };
  try {
    const brand = await axios.post(url, obj, headerAuthorization);
    return {
      isSuccess: true,
      status: brand.status,
      data: brand.data.data.newBrandId,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Error al crear la marca, intente nuevamente.",
    };
  }
};
