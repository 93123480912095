import React from "react";
// @ts-ignore
import { SelectInput } from "@viuti/recursos";
import { useGetWarehouse } from "@Hooks/useGetWarehouse";

const WarehouseSelect = ({
  onSelectWarehouse,
  selectedWarehouse,
  error,
  isSummaryVisible,
}) => {
  const { warehouseList } = useGetWarehouse();
  return (
    <SelectInput
      label={
        !isSummaryVisible
          ? "Selecciona un almacén"
          : "Almacén seleccionado para la carga"
      }
      value={selectedWarehouse.warehouseId}
      items={warehouseList}
      handleChange={(e) => {
        const { value } = e.target;
        const name = warehouseList.find((item) => item.value === value)?.name;
        onSelectWarehouse({
          warehouseId: value,
          warehouseName: name,
        });
      }}
      error={error.warehouseError}
      touched={error.warehouseError}
      required
      disabled={isSummaryVisible}
    />
  );
};

export default WarehouseSelect;
