export const changeSection = (
  section: number,
  basicsForm: any,
  pricesForm: any,
  stockForm: any,
  setCurrentSection: any
) => {
  switch (section) {
    case 0:
      basicsForm.current?.scrollIntoView({ behavior: "smooth" });
      break;
    case 1:
      pricesForm.current?.scrollIntoView({ behavior: "smooth" });
      break;
    case 2:
      stockForm.current?.scrollIntoView({ behavior: "smooth" });
      break;
    default:
      break;
  }
  setCurrentSection(section);
};
