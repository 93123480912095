import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

export const deleteInventoryProduct_service = async (
  inventoryId: number,
  inventoryProductId: number
) => {
  const url = `${baseUrl}/Inventory/DeleteinventoryDetail?inventoryDetailId=${inventoryProductId}&inventoryId=${inventoryId}`;

  try {
    const response = await axios.delete(url, headerAuthorization);

    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      message: response.data.data.message,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      message:
        error?.response?.data?.message ||
        "Hubo un error al eliminar el producto del inventario, por favor intenta de nuevo.",
    };
  }
};
