export const SHOW_PRODUCTS_AND_WAREHOUSES_UUID =
  "28fc22bf-3df8-4f7c-bfe8-f9082faf12b5";
export const UPSERT_WAREHOUSES_UUID = "6e60a3aa-e143-464e-aec1-e938806d16e1";
export const MASSIVE_LOAD_PRODUCTS_UUID =
  "f7987e13-0c53-4b10-ab96-08e660279e78";
export const CREATE_PRODUCTS_UUID = "6d1aa3a8-9625-400b-8dbc-b48d49bcb604";
export const MODIFY_PRODUCTS_UUID = "80c30d9c-6201-45dd-9bdb-8fab2013f1d7";
export const SHOW_INVENTORY_HISTORY_UUID =
  "22486bbc-9617-408d-86ae-46303d128df3";
export const UPSERT_INVENTORIES_UUID = "b70d6814-9bb7-4184-b466-4e1c82f29d5f";
export const DEPOSIT_PRODUCTS_UUID = "47e5e115-a461-4e3e-be85-dcdb3ad63bfe";
export const TRANSFER_PRODUCTS_UUID = "bb3a84dd-bb92-47dd-84f3-182d6b122925";
export const WITHDRAW_PRODUCTS_UUID = "afddc05d-f4b2-452a-aedf-75f5905789a8";
