import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./InventoryOverview.module.css";
import justificationStyles from "../Inventory/components/JustificationModal/JustificationModal.module.css";
import tableStyles from "../Inventory/builder/styles/inventoryCountRowsBuilder.module.css";
import {
  HeadBar,
  TextAreaInput,
  ButtonWithoutIcon,
  SlideNotification,
  CriticalErrorPage,
  validateModuleAccess,
} from "../../resources";
import SuccessMessage from "@Components/SuccessMessage/SuccessMessage";
import InventoryTable from "@Components/InventoryTable/InventoryTable";
import { inventoryProductRowsBuilder } from "./builder/inventoryProductRowsBuilder";
import { IProductInventory } from "@Hooks/useWarehouseInventoryStore";
import { productTableBuilder } from "@Pages/Inventory/builder/productBuilder";
import { useNavigate, useParams } from "react-router";
import { getInventory } from "@Adapters/inventoryAdapters/getInventory.adapter";
import { getAllInventories } from "@Adapters/inventoryAdapters/getAllInventories.adapter";
import { getProductsAdapter } from "@Adapters/productsAdapters/products/getProducts.adapter";
import { LoadingPulseIcon } from "@Models/icons/icons";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { SHOW_INVENTORY_HISTORY_UUID } from "@Models/const/securityAccess";

const InventoryOverview = () => {
  const navigate = useNavigate();
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const [errorView, setErrorView] = useState({
    errorProduct: false,
    errorWarehouse: false,
    errorInventory: false,
  });
  const { warehouseId, inventoryId } = useParams();
  const screenWidth = useScreenWidth();
  const headerProps = {
    previousAction: () => window.location.replace(`/almacen`),
    warehouseProps: {
      title: "Almacen - Inventario",
    },
    showButton: false,
    showSecondaryButton: screenWidth > 900 ? true : false,
    buttonProps: {
      textBttn: "Revisar",
      handleClick: () => {},
      isDisabled: false,
      isPrimary: true,
      style: { maxHeight: "40px" },
    },
    secondaryButtonProps: {
      textBttn: "Ver inventarios",
      handleClick: () =>
        validateModuleAccess(SHOW_INVENTORY_HISTORY_UUID) &&
        window.location.replace(`/almacen/historial-inventario`),
      isDisabled: false,
      isPrimary: false,
      style: { maxHeight: "40px" },
    },
  };

  const [currentInventory, setCurrentInventory] = useState<{
    inventory: {
      inventoryId: number;
      dateStart: string;
      dateEnd: string;
      warehouseId: number;
      warehouseName: string;
      conflict: boolean;
    };
    products: IProductInventory[];
    response: {
      status: number;
      isSuccess: boolean;
      error: string;
    };
  }>({
    inventory: {
      inventoryId: 0,
      dateStart: "",
      dateEnd: "",
      warehouseId: 0,
      warehouseName: "",
      conflict: false,
    },
    products: [],
    response: {
      status: 0,
      isSuccess: false,
      error: "",
    },
  });

  const [successMessageProps, setSuccessMessageProps] = useState({
    actionTitle: `Alamacén - Inventario exitoso`,
    actionMsg: "Revisa el informe en la parte inferior para ver el detalle.",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    (async () => {
      const warehouseProducts = await getProductsAdapter(
        Number(warehouseId),
        setErrorView,
        setStateResponse
      );

      const inventoryResponse = await getInventory(
        Number(inventoryId),
        setStateResponse,
        setErrorView
      );
      const allInventoriesResponse = await getAllInventories(
        Number(warehouseId),
        setStateResponse,
        setErrorView
      );

      const selectedInventory = allInventoriesResponse.inventories.find(
        (inventory) => {
          return inventory.inventoryId === Number(inventoryId);
        }
      );

      if (
        !warehouseProducts.isSuccess ||
        !inventoryResponse.isSuccess ||
        !allInventoriesResponse.isSuccess ||
        !selectedInventory
      ) {
        setCurrentInventory({
          inventory: {
            inventoryId: 0,
            dateStart: "",
            dateEnd: "",
            warehouseId: 0,
            warehouseName: "",
            conflict: false,
          },
          products: [],
          response: {
            status: 500,
            isSuccess: false,
            error:
              "Ha ocurrido un error al cargar los datos del inventario. Por favor, intenta de nuevo.",
          },
        });
        return true;
      }

      if (
        selectedInventory.inventoryId &&
        typeof selectedInventory.dateStart === "string" &&
        typeof selectedInventory.dateEnd === "object"
      ) {
        navigate(`/inventario/${warehouseId}/${inventoryId}`);
      }

      const products = inventoryResponse.inventory.map((product: any) => {
        return {
          id: product.productId,
          inventoryId: product.id,
          counter: product.counter,
          stock: product.stock,
          difference: product.counter - product.stock,
          differenceMessage: product.differenceMessage || "",
          name: product.name,
          code: product.productCode,
          brand: {
            id: product.productBrandName,
            name: product.productBrandName,
          },
          line: {
            id: product.productSubBrandName,
            name: product.productSubBrandName,
          },
          categories: product?.categories?.map((category: any) => {
            return {
              id: category.id,
              name: category.name,
            };
          }),
        };
      });

      const tempCurrentInventory = {
        inventory: selectedInventory,
        products: products,
        response: {
          status: 200,
          isSuccess: true,
          error: "",
        },
      };

      setCurrentInventory(tempCurrentInventory);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productsWithDifferences = currentInventory.products.filter(
    (product) => {
      return product.differenceMessage !== "";
    }
  );

  useEffect(() => {
    setSuccessMessageProps({
      actionTitle: `Inventario de ${currentInventory.inventory.warehouseName}`,
      actionMsg: "Revisa el informe en la parte inferior para ver el detalle.",
      startDate: currentInventory.inventory.dateStart,
      endDate: currentInventory.inventory.dateEnd,
    });
  }, [currentInventory.inventory]);

  if (
    errorView.errorProduct ||
    errorView.errorWarehouse ||
    errorView.errorInventory
  ) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <HeadBar {...headerProps} />
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  } else if (currentInventory.response.status === 0) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <figure className={style.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );
  } else if (!currentInventory.response.isSuccess) {
    return (
      <div
        id="viuti-front-mainContent"
        style={{
          overflow: "auto",
        }}
      >
        <HeadBar {...headerProps} />
        <SuccessMessage
          actionTitle="Error al cargar el inventario"
          actionMsg="Ha ocurrido un error al cargar los datos del inventario. Por favor, intenta de nuevo."
          startDate=""
          endDate=""
          showStatus={false}
        />
      </div>
    );
  }

  return (
    <div
      id="viuti-front-mainContent"
      style={{
        overflow: "auto",
      }}
    >
      <HeadBar {...headerProps} />
      {screenWidth < 900 && (
        <ButtonWithoutIcon
          textBttn="Ver inventarios"
          handleClick={() =>
            validateModuleAccess(SHOW_INVENTORY_HISTORY_UUID) &&
            window.location.replace(`/almacen/historial-inventario`)
          }
          isPrimary={false}
          isDisabled={false}
          style={{ maxHeight: "40px" }}
        />
      )}
      <div className={style.body}>
        <SuccessMessage {...successMessageProps} />
        <div className={style.inventoryTable__header}>
          <p className={style.inventoryTable__header__title}>
            Resultados del inventario
          </p>
        </div>
        <section className={style.inventoryTable__container}>
          <InventoryTable
            columns={["Producto", "Stock anterior", "Stock contado"]}
            rows={inventoryProductRowsBuilder(
              setStateResponse,
              currentInventory.products
            )}
            screenWidth={screenWidth}
            colgroup={
              screenWidth > 900 ? "auto 140px 140px" : "auto 120px 120px"
            }
          />
        </section>
        <div className={style.inventoryTable__header}>
          <p
            className={`${style.inventoryTable__header__title} ${style.inventoryTable__header__title__incidences}`}
          >
            Incidencias resueltas
          </p>
        </div>
        {productsWithDifferences.length > 0 ? (
          <section className={style.incidences__container}>
            {productsWithDifferences.map((product) => {
              const productBuilderParams = {
                name: product.name,
                brand: product.brand.name,
                subBrand: product.line.name,
                code: product.code,
              };

              // @ts-ignore
              const difference = Number(product.difference);
              const isDifferencePositive = difference > 0;
              const isSingular = Math.abs(difference) === 1;
              let differenceMessage = "";
              let unitsText = "";
              if (isDifferencePositive) {
                unitsText = isSingular ? "sobra" : "sobran";
              } else {
                unitsText = isSingular ? "falta" : "faltan";
              }
              const quantity = isSingular ? "una unidad" : "unidades";
              differenceMessage = `Te ${unitsText} ${quantity} de este producto`;

              return (
                <div
                  className={style.incidences__item__container}
                  key={product.id}
                >
                  <div className={style.incidences__item__content}>
                    <div className={justificationStyles.body}>
                      <section
                        className={justificationStyles.product__container}
                      >
                        {productTableBuilder(
                          setStateResponse,
                          productBuilderParams
                        )}

                        <div className={justificationStyles.counter__container}>
                          <span
                            className={`${
                              justificationStyles.difference__container
                            } ${tableStyles.difference__container} ${
                              difference !== 0
                                ? tableStyles.difference__show
                                : ""
                            }`}
                          >
                            {isDifferencePositive
                              ? `+${difference}`
                              : difference}
                          </span>
                          <span
                            className={justificationStyles.counter__message}
                          >
                            {differenceMessage}
                          </span>
                        </div>
                      </section>
                      <TextAreaInput
                        label={`¿Por qué ${unitsText} ${quantity} de este producto?`}
                        value={product.differenceMessage}
                        placeholder=""
                        handleChange={null}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
        ) : (
          <p className={style.noIncidences__message}>
            Todos los productos en el inventario están en perfecto estado.
            <br />
            <b>No se registran incidencias.</b>
          </p>
        )}
      </div>
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default InventoryOverview;
