import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";

export const itemsValueAndName = (
  warehousesList: Warehouse[],
  warehouseName: string,
  setItems: any
) => {
  warehousesList?.map((obj) => {
    if (obj.warehouseName !== warehouseName) {
      setItems((items: any) => [
        ...items,
        { value: obj.warehouseId, name: obj.warehouseName },
      ]);
    }
  });
};
