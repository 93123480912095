import { baseUrl, headerAuthorization } from "@Services/constServices";
import axios from "axios";

export const getStockMovements = async (
  productId,
  warehouseId,
  initialDate,
  finalDate,
  currentPage
) => {
  let url = "";
  if (productId && warehouseId !== null) {
    url = `${baseUrl}/StockMovement/GetMovementStock?productId=${productId}&wareHouseId=${warehouseId}&page=${currentPage}&fromtDate=${initialDate}&toDate=${finalDate}`;
  } else {
    url = `${baseUrl}/StockMovement/GetMovementStock?productId=${productId}&page=${currentPage}&fromtDate=${initialDate}&toDate=${finalDate}`;
  }

  try {
    const StockMovements = await axios.get(url, headerAuthorization);
    return {
      isSuccess: true,
      status: StockMovements.status,
      data: StockMovements.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Hubo un error al obtener los movimientos de stock.",
    };
  }
};
