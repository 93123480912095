// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FlaAGr37Y1EzdnFCWKaA {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.adaJ3M_rz3uBQ8OEHLQT {
  color: #4d4d4d;
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  margin-left: 0.3125rem;
  cursor: pointer;
}

.nrARXvosrQGlRC10T02w {
  color: #4d4d4d;
}

.nrARXvosrQGlRC10T02w::before {
  background-color: green;
}
`, "",{"version":3,"sources":["webpack://./src/components/FormInputs/CheckboxInput/CheckboxInput.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;EACT,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".inputContainer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.inputLabel {\n  color: #4d4d4d;\n  font-family: \"Mulish\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  margin: 0;\n  margin-left: 0.3125rem;\n  cursor: pointer;\n}\n\n.inputCheckbox {\n  color: #4d4d4d;\n}\n\n.inputCheckbox::before {\n  background-color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `FlaAGr37Y1EzdnFCWKaA`,
	"inputLabel": `adaJ3M_rz3uBQ8OEHLQT`,
	"inputCheckbox": `nrARXvosrQGlRC10T02w`
};
export default ___CSS_LOADER_EXPORT___;
