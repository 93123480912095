export const copyCodeToClipboard = (text) => {
  if (text === "-") {
    return;
  }
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};
