import React from "react";
// @ts-ignore
import styled from "../../SearchBar.module.css";
import SearchResult from "../Result/Result";
import {
  DataItem,
  FilterSearchProps,
  SearchResultsProps,
} from "@Models/interfaces/SearchResult.interface";

const filterSearch = ({
  dataSearchable,
  input,
}: FilterSearchProps): DataItem[] => {
  if (!Array.isArray(dataSearchable)) {
    throw new Error("dataSearchable must be an array");
  }
  const result = dataSearchable.filter((item) => {
    const { name, barCode } = item;
    return (
      name.toLowerCase().includes(input.toLowerCase()) ||
      barCode.includes(input)
    );
  });

  return result;
};

const SearchResults: React.FC<SearchResultsProps> = ({
  searchInput,
  dataSearchable,
  handleSubmit = () => {},
}) => {
  if (searchInput === "") return null;
  const resultsArr = filterSearch({
    dataSearchable: dataSearchable,
    input: searchInput,
  });
  if (resultsArr.length === 0) return null;

  return (
    <section className={styled.resultsContainer}>
      {resultsArr.map((el, i) => (
        <SearchResult
          key={i}
          name={el.name}
          brand={el.brand}
          family={el.familia}
          cod={el.barCode}
          category={el.categories}
          isLastElement={i === resultsArr.length - 1}
          handleSubmit={handleSubmit}
        />
      ))}
    </section>
  );
};

export default SearchResults;
