// useInventory.ts
import { useEffect } from "react";
import { getWarehousesAdapter } from "@Adapters/warehousesAdapters/getWarehouses.adapter";
import { getProductsAdapter } from "@Adapters/productsAdapters/products/getProducts.adapter";
import { useParams } from "react-router";
import { IProductInventory } from "@Hooks/useWarehouseInventoryStore";
import { getInventory } from "@Adapters/inventoryAdapters/getInventory.adapter";

export const useWarehouseData = (
  warehouseData: any,
  setWarehousesResponse: Function,
  setProductsResponse: Function,
  setInventoryResponse: Function,
  navigate: Function,
  setWarehouseProps: Function,
  setStateResponse: any,
  setErrorView: any
) => {
  const { warehouseId, inventoryId } = useParams();

  const fetchWarehouses = async () => {
    const response = await getWarehousesAdapter({
      setStateResponse,
      setErrorView,
    });
    if (response.isSuccess) {
      const selectedWarehouse = response.warehouses.find(
        (warehouse) => warehouse.warehouseId === Number(warehouseId)
      );
      setWarehousesResponse({
        warehouses: response.warehouses,
        isSuccess: true,
        status: response.status,
        error: response.error,
      });

      selectedWarehouse
        ? setWarehouseProps({
            title: `${selectedWarehouse.warehouseName} - Inventario`,
          })
        : navigate("/");
    } else {
      setWarehousesResponse({
        warehouses: [],
        isSuccess: false,
        status: response.status,
        error: response.error,
      });
    }
  };

  const fetchProducts = async () => {
    const response = await getProductsAdapter(
      Number(warehouseId),
      setErrorView,
      setStateResponse
    );
    if (response.isSuccess) {
      const orderedProducts = response.products.reverse();
      const formattedProducts: IProductInventory[] = orderedProducts.map(
        (product: any) => {
          return {
            id: product.productLocalId,
            name: product.productName,
            code: product.code,
            stock: product.stock,
            counter: "",
            difference: 0, // Add the missing 'difference' property with a default value
            differenceMessage: "",
            brand: {
              id: product.brand.brandId,
              name: product.brand.brandName,
            },
            line: {
              id: product.line.lineId,
              name: product.line.lineName,
            },
            categories: product.category.map((category: any) => {
              return {
                id: category.categoryId,
                name: category.categoryName,
              };
            }),
          };
        }
      );

      setProductsResponse({
        products: formattedProducts,
        isSuccess: true,
        status: response.status,
        error: response.error,
      });
    } else {
      setProductsResponse({
        products: [],
        isSuccess: false,
        status: response.status,
        error: response.error,
      });
    }
  };

  const fetchInventory = async () => {
    const response = await getInventory(
      Number(inventoryId),
      setStateResponse,
      setErrorView
    );
    if (response.isSuccess) {
      setInventoryResponse({
        inventory: response.inventory,
        isSuccess: true,
        status: response.status,
        error: response.error,
      });
    } else {
      setInventoryResponse({
        inventory: [],
        isSuccess: false,
        status: response.status,
        error: response.error,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (!warehouseData.warehouseId) {
        await fetchWarehouses();
      } else {
        setWarehousesResponse({
          warehouses: [warehouseData],
          isSuccess: true,
          status: 200,
          error: "",
        });
      }
    })();
    (async () => {
      await fetchProducts();
    })();
    (async () => {
      await fetchInventory();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
