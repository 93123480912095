// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w5dD46No4Z_f5AnJLulN {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
  gap: 1.25rem;
  margin-top: 20px;
}

.Ps4jQvcYTRlopBzIx5Ba {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 1.25rem;
  flex-wrap: wrap;
}

.iuEMhsw4thM1E04Iybcw {
  display: flex;
  gap: 1.25rem;
  min-width: 11.25rem;
  align-items: end;
}
.DjUtmYXXh26LS9HyLjGf {
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.hCXiweuRAPT_a5bLnaJO {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-width: 70px;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductForm/views/StockMovements/StockMovements.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;EACf,uBAAuB;AACzB","sourcesContent":[".containerRecord {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  overflow-y: auto;\n  gap: 1.25rem;\n  margin-top: 20px;\n}\n\n.headerFilter {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: end;\n  gap: 1.25rem;\n  flex-wrap: wrap;\n}\n\n.containerDate {\n  display: flex;\n  gap: 1.25rem;\n  min-width: 11.25rem;\n  align-items: end;\n}\n.icon_options {\n  transform: rotate(90deg);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.stockMovementsMobile__headerTitle {\n  padding: 6px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n  min-width: 70px;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerRecord": `w5dD46No4Z_f5AnJLulN`,
	"headerFilter": `Ps4jQvcYTRlopBzIx5Ba`,
	"containerDate": `iuEMhsw4thM1E04Iybcw`,
	"icon_options": `DjUtmYXXh26LS9HyLjGf`,
	"stockMovementsMobile__headerTitle": `hCXiweuRAPT_a5bLnaJO`
};
export default ___CSS_LOADER_EXPORT___;
