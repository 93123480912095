import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

export interface ISaveInventoryProduct {
  inventoryDetailId: number;
  localProductId: number;
  count: number;
  comments: string;
}

interface ISaveInventoryPayload {
  inventoryId: number;
  inventorySaveChangeDetails: ISaveInventoryProduct[];
}

export const saveInventory_service = async (payload: ISaveInventoryPayload) => {
  const url = `${baseUrl}/Inventory/SaveChangeInventory`;

  try {
    const response = await axios.post(url, payload, headerAuthorization);

    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error:
        error?.response?.data?.message ||
        "Hubo un error al guardar el inventario, por favor intenta de nuevo.",
    };
  }
};
