// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YHFamBUsAsiLvJrqFCox {
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 0.75rem 1.56rem;
  border-top: 1px solid rgb(204, 204, 204);
}

.YHFamBUsAsiLvJrqFCox p {
  color: #484848;
  font-family: Mulish;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.015rem;
  text-align: start;
  display: flex;
  text-align: initial;
}

.YHFamBUsAsiLvJrqFCox:hover {
  background-color: #f0edfe;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/componentsMainView/WarehouseSelect/ComponentsWarehouseSelect/ButtonCreateWarehouse/ButtonCreateWarehouse.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,WAAW;EACX,eAAe;EACf,wBAAwB;EACxB,wCAAwC;AAC1C;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;EACxB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".createNewWarehouse {\n  background-color: transparent;\n  border: none;\n  width: 100%;\n  cursor: pointer;\n  padding: 0.75rem 1.56rem;\n  border-top: 1px solid rgb(204, 204, 204);\n}\n\n.createNewWarehouse p {\n  color: #484848;\n  font-family: Mulish;\n  font-size: 0.75rem;\n  font-style: italic;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.015rem;\n  text-align: start;\n  display: flex;\n  text-align: initial;\n}\n\n.createNewWarehouse:hover {\n  background-color: #f0edfe;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createNewWarehouse": `YHFamBUsAsiLvJrqFCox`
};
export default ___CSS_LOADER_EXPORT___;
