interface SelectedWarehouse {
  warehouseId: number;
  warehouseName: string;
  sales: number | boolean;
  warehouseValue: number;
}

export const setSessionWarehouse = (warehouse: SelectedWarehouse): void => {
  localStorage.setItem(
    "selectedWarehouse",
    JSON.stringify({
      warehouseId: warehouse.warehouseId,
      warehouseName: warehouse.warehouseName,
      sales: warehouse.sales,
      warehouseValue: warehouse.warehouseValue,
    })
  );
};
