import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

interface PostSubBrand {
  isSuccess: boolean;
  status?: number;
  data: any | null;
  error: string | null;
}

export const postSubBrand = async (props): Promise<PostSubBrand> => {
  const url = `${baseUrl}/ProductSubBrands/CreateSubBrand`;
  const obj = {
    brandId: props.brandId,
    subBrandName: props.subBrandName,
  };
  try {
    const subBrand = await axios.post(url, obj, headerAuthorization);
    return {
      isSuccess: true,
      status: subBrand.status || 200,
      data: subBrand.data.data.newSubBrandId,
      error: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || 400,
      data: [],
      error: error?.response?.data?.message || "Error al crear submarca.",
    };
  }
};
