import { Product } from "@Models/interfaces/productsInterfaces/productInterface/product.interface";

export const filteredProducts = (products: Product[], searchInput: string) => {
  return products.filter((product) => {
    const { productName, code } = product;
    const searchTerm = searchInput.toLowerCase().trim();

    // Combina el productName y code en una sola cadena
    const combinedFields = `${productName ?? ""} ${code ?? ""}`.toLowerCase();

    return combinedFields.includes(searchTerm);
  });
};
