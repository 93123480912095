import { ResponseToRequest } from "@Models/interfaces/ResponseToRequest.interface";
import { deleteWarehouse } from "@Services/warehouses/deleteWarehouse.services";

export const deleteWarehouseAdapter = async (
  IdWarehouse: number
): Promise<ResponseToRequest> => {
  const response = await deleteWarehouse(IdWarehouse);
  if (response.isSuccess) {
    return {
      isSuccess: true,
      status: response.status,
      data: response.data,
      error: null,
    };
  }
  return {
    isSuccess: false,
    status: response.status,
    data: null,
    error: response.error,
  };
};
