import { headerAuthorization, baseUrl } from "@Services/constServices";
import axios from "axios";

export interface IResponse {
  isSuccess: boolean;
  status: number;
  message: string;
  data: any;
}

export const uploadSpreadsheet_service = async (
  file: File
): Promise<IResponse> => {
  const formData = new FormData();
  formData.append("File", file);
  const url = `${baseUrl}/LocalProducts/MasiveProduct`;
  try {
    const response = await axios.post(url, formData, headerAuthorization);
    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
      message: null,
    };
  } catch (error) {
    return {
      isSuccess: false,
      status: error?.response?.status || error,
      message:
        error?.response?.data?.message ||
        "Error al crear los productos, intente de nuevo.",
      data: null,
    };
  }
};
