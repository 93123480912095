import { Warehouse } from "@Models/interfaces/warehousesInterfaces/Warehouse.interface";
import { putWarehousesNameAdapter } from "@Adapters/warehousesAdapters/putWarehouses.adapter";
import { setSessionWarehouse } from "@Utilities/warehouseUtils";

export const edit = async (
  warehouses: Warehouse[],
  warehouseName: string,
  setError: any,
  warehouseToEdit: Warehouse,
  setShowWarehouse: any,
  setResetWarehouses: any,
  resetWarehouses: boolean,
  setSelectedWarehouse: any,
  setSelectWarehouse: any,
  setWarehouse: any,
  close: any,
  setStateResponse: any,
  setIsLoading: any,
  setWarehousesList: any
) => {
  const storeNameExists = warehouses.find(
    (store: any) =>
      store.warehouseName.toLowerCase() === warehouseName?.toLowerCase()
  );
  if (!warehouseName)
    return setError("El nombre del almacén no puede estar vacío.");
  if (warehouseName.length < 4)
    return setError(
      "Ingresa un nombre de almacén válido con al menos 4 caracteres."
    );
  if (warehouseName === warehouseToEdit?.warehouseName)
    return setError("Ingresa un nombre de almacén diferente al actual.");
  if (storeNameExists) return setError("Ya existe un almacén con ese nombre.");
  if (warehouseName.length > 35)
    return setError(
      "El nombre del almacén no puede tener más de 35 caracteres."
    );
  setIsLoading(true);
  let response = await putWarehousesNameAdapter(
    warehouseName,
    warehouseToEdit?.warehouseId
  );
  if (response.isSuccess) {
    setShowWarehouse(false);
    setResetWarehouses(!resetWarehouses);
    setSelectedWarehouse(warehouseToEdit?.warehouseId);
    setSelectWarehouse({
      warehouseId: warehouseToEdit?.warehouseId,
      warehouseName: warehouseName || "",
      sales: warehouseToEdit?.sales,
    });
    setWarehouse(warehouseName || "");
    setSessionWarehouse({
      warehouseId: warehouseToEdit?.warehouseId,
      warehouseName: warehouseName || "",
      sales: warehouseToEdit?.sales || false,
      warehouseValue: warehouseToEdit?.warehouseValue || 0,
    });
    setWarehousesList((prev) =>
      prev.map((warehouse) =>
        warehouse.warehouseId === warehouseToEdit?.warehouseId
          ? { ...warehouse, warehouseName }
          : warehouse
      )
    );
    setIsLoading(false);
    setStateResponse({
      message: `El almacén "${warehouseName}" se ha editado correctamente`,
      status: response.status,
    });
    close();
  } else {
    setIsLoading(false);
    setStateResponse({
      message: `El almacén "${warehouseName}" no se ha podido editar, intente nuevamente`,
      status: response.status,
    });
  }
};
