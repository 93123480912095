import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./ModalTransfer.module.css";
import { useNavigate } from "react-router";
import selectedWarehouseGlobal from "@Hooks/UseSelectedWarehouse";
import { ModalTransferProps } from "@Models/interfaces/ModalTransfer.interface";
import { ModalAcceptCancel, SelectInput } from "../../../../resources/index";
import { itemsValueAndName } from "./functions/itemsValueAndName";

const ModalTransfer: React.FC<ModalTransferProps> = ({
  close,
  warehousesList,
  unitOrVolume,
  idProduct,
  selectWarehouse,
}) => {
  const [optionMeta, setOptionMeta] = useState({
    value: 0,
    name: "Almacén Objetivo",
  });

  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    itemsValueAndName(warehousesList, selectWarehouse.warehouseName, setItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehousesList]);

  const handleInputClickMeta = (e) => {
    const { value, name } = e.target;
    setOptionMeta({
      value: value,
      name: name,
    });
  };

  const actionButtonTwo = () => {
    if (optionMeta.value === 0) {
      setError("Debes seleccionar un almacén de destino");
      return;
    }
    if (unitOrVolume.unit === true) {
      navigate(
        `transferir/${selectWarehouse?.warehouseId}/${optionMeta.value}/${idProduct}`
      );
    } else {
      navigate("transferir");
    }
  };

  useEffect(() => {
    setError("");
  }, [optionMeta]);

  return (
    <ModalAcceptCancel
      showButtons={{
        showButtonClose: true,
        showButtonOne: false,
        showButtonTwo: true,
      }}
      actionButtonClose={() => close()}
      buttonTwo={{
        textButtonTwo: "Aceptar",
        isDisabled: optionMeta.value === 0,
        actionButtonTwo: () => actionButtonTwo(),
      }}
    >
      <div className={style.container}>
        <div className={style.containerTitle}>
          <h1>Transferir unidades</h1>
        </div>
        <div className={style.containerContent}>
          <SelectInput
            label={"Elige el almacén de origen"}
            value={selectWarehouse.warehouseId}
            items={[
              {
                value: selectWarehouse.warehouseId,
                name: selectWarehouse.warehouseName,
              },
            ]}
            name={"optionIda"}
            disabled={true}
            type={"text"}
            handleChange={null}
          />
          <SelectInput
            label={"Elige el almacén de destino"}
            value={optionMeta.value || ""}
            placeholder={"Seleccionar una opción"}
            type={"text"}
            handleChange={(e) => handleInputClickMeta(e)}
            items={items}
            name={"optionMeta"}
            error={error}
          />
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default ModalTransfer;
