export const formatToSoles = (
  number: number | string,
  includePrefix: boolean = true
) => {
  number = Number(number);

  if (typeof number !== "number" || isNaN(number)) {
    return "S/ 0.00";
  }

  const formatter = new Intl.NumberFormat("es-PE", {
    style: "currency",
    currency: "PEN",
    currencyDisplay: "symbol",
  });

  const formatterWithoutSymbol = number.toLocaleString("es-PE", {
    minimumFractionDigits: 2,
  });

  return includePrefix ? formatter.format(number) : formatterWithoutSymbol;
};
