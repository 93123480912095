import React, { useState } from "react";
import style from "./ModalOptionsProduct.module.css";
import { LeaveIcon, MinusWithFrameIcon, PlusIcon } from "@Models/icons/icons";
import { ModalAcceptCancel, validateModuleAccess } from "../../resources";
import {
  DEPOSIT_PRODUCTS_UUID,
  TRANSFER_PRODUCTS_UUID,
  WITHDRAW_PRODUCTS_UUID,
} from "@Models/const/securityAccess";

const ModalOptionsProduct = ({
  navigate,
  setSelectProduct,
  setUnitOrVolume,
  setIdProductModal,
  selectProduct,
  setModal,
  close,
  screenWidth,
}) => {
  const [tooltipMessage, setTooltipMessage] = useState(null);

  const handleClick = (message) => {
    setTooltipMessage(message);
    setTimeout(() => setTooltipMessage(null), 2000); // Ocultar el mensaje después de 2 segundos
  };

  return (
    screenWidth < 1150 && (
      <ModalAcceptCancel
        title={"Opciones de producto"}
        showButtons={{
          showButtonClose: true,
          showButtonOne: false,
          showButtonTwo: false,
        }}
        actionButtonClose={() => close()}
      >
        <div className={style.containerOptionsProduct}>
          <section className={style.movement_info_container}>
            <div className={style.movement_info}>
              <h3>{selectProduct.productName}</h3>
              <span className={style.movement_concept}>
                {selectProduct.code}
              </span>
            </div>
            <div className={style.amount_info}>
              <p className={style.movement_date}>
                {selectProduct.brand.brandName}
              </p>
              <p>{selectProduct.stock} unidades</p>
            </div>
          </section>
          <button
            onClick={() =>
              validateModuleAccess(DEPOSIT_PRODUCTS_UUID) &&
              navigate(`ingresar/${selectProduct.productLocalId}`)
            }
          >
            <p>Ingresar</p>
          </button>
          <button
            onClick={() => {
              if (validateModuleAccess(TRANSFER_PRODUCTS_UUID)) {
                if (selectProduct.stock > 0) {
                  setModal(1);
                  setSelectProduct(null);
                  setUnitOrVolume({ unit: true });
                  setIdProductModal(selectProduct.productLocalId);
                } else {
                  handleClick("Stock insuficiente para realizar esta acción.");
                }
              }
            }}
          >
            <p>Transferir</p>
          </button>
          <button
            onClick={() => {
              if (validateModuleAccess(WITHDRAW_PRODUCTS_UUID)) {
                if (selectProduct.stock > 0) {
                  navigate(`retirar/${selectProduct.productLocalId}`);
                } else {
                  handleClick("Stock insuficiente para realizar esta acción.");
                }
              }
            }}
          >
            <p>Retirar</p>
          </button>
        </div>
        {tooltipMessage && (
          <div className={style.tooltip}>{tooltipMessage}</div>
        )}
      </ModalAcceptCancel>
    )
  );
};

export default ModalOptionsProduct;

// {
//   "productLocalId": 255836,
//   "stock": -2,
//   "emergencyStock": 2,
//   "productName": "Producto nuevo",
//   "code": "99999",
//   "freeProduct": 0,
//   "price": "27.140",
//   "brandImageUrl": null,
//   "productId": 111402,
//   "warehouse": {
//       "warehouseId": 411,
//       "warehouseName": "Almacen 1a"
//   },
//   "brand": {
//       "brandId": 2254,
//       "brandName": "DAADADADASD"
//   },
//   "line": {
//       "lineId": 0,
//       "lineName": null
//   },
//   "category": [
//       {
//           "categoryId": 1686,
//           "categoryName": "NUEVA CATEGORIA"
//       },
//       {
//           "categoryId": 1687,
//           "categoryName": "DAD12K3123"
//       }
//   ]
// }
